import { Organization } from "../../types/organization"
import { PropertyPermissionGroup } from "../../types/propertyPermissionGroup"
import { UserDTO, UserOrgDTO } from "../../types/user.dto"
import { apiClient } from "./createApiClient"

export type CreatePropertyPermissionGroupReqBody = {
  name: string
  permissions: string[]
}

export type CreateUserRequestBody = {
  firstName: string;
  lastName: string;
  email: string;
  nickName: string;
  locale: string;
  propertyGroups: {
    property: string;
    groups: number[];
  }[];
}

export function createOrgSettingsClient(organizationId: string) {
  const baseUrl = `/org/${organizationId}`

  const getOrg = () =>
    apiClient.get<{ org: Organization}>(`${baseUrl}`, {showProperties: true})

  const createPropertyPermissionGroup = (propertyPermissionGroup: CreatePropertyPermissionGroupReqBody) =>
    apiClient.post<CreatePropertyPermissionGroupReqBody>(`${baseUrl}/property-permission-groups`, propertyPermissionGroup)

  const editPropertyPermissionGroup = (propertyPermissionGroupId: string, propertyPermissionGroup: CreatePropertyPermissionGroupReqBody) =>
    apiClient.put<CreatePropertyPermissionGroupReqBody>(`${baseUrl}/property-permission-groups/${propertyPermissionGroupId}`, propertyPermissionGroup)

  const deletePropertyPermissionGroup = (propertyPermissionGroupId: string) =>
    apiClient.delete(`${baseUrl}/property-permission-groups/${propertyPermissionGroupId}`);

  const getPropertyPermissionGroups = () =>
    apiClient.get<{ groups: PropertyPermissionGroup[] }>(`${baseUrl}/property-permission-groups`)

  const createUser = (form: CreateUserRequestBody) =>
    apiClient.post<CreateUserRequestBody>(`${baseUrl}/users`, form);

  const editUser = (userId: string, form: CreateUserRequestBody) =>
    apiClient.put<CreateUserRequestBody>(`${baseUrl}/users/${userId}`, form);

  const deleteUser = (userId: string) =>
    apiClient.delete(`${baseUrl}/users/${userId}`);

  const getUsers = () =>
    apiClient.get<{ users: UserOrgDTO[] }>(`${baseUrl}/users`);

  return {
    getOrg,
    createPropertyPermissionGroup,
    getPropertyPermissionGroups,
    editPropertyPermissionGroup,
    deletePropertyPermissionGroup,
    createUser,
    editUser,
    deleteUser,
    getUsers,
  }
}
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { t, Trans } from "@lingui/macro";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { useGlobalStore } from "../../store/globalStore";
import { classNames } from "../../utils";
import { Button } from "./Button";

type Props = {
  message: string;
  title: string;
  buttonName?: string;
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  type?: "info" | "warning" | "success" | "error";
};

export function ConfirmationModal({
  message,
  title,
  buttonName = t`Confirm`,
  onClose,
  onConfirm,
  isOpen,
  type = "warning",
}: Props) {
  const loading = useGlobalStore((state) => state.loading);

  const renderIcon = () => {
    switch (type) {
      case "warning":
      case "error":
        return (
          <div className="flex flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-0 h-10 w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
        );
      case "info":
        return (
          <div className="flex flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-0 h-10 w-10">
            <CheckIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
          </div>
        );
      case "success":
        return (
          <div className="flex flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-0 h-10 w-10">
            <CheckIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
          </div>
        );
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
                <div className="flex items-start">
                  {renderIcon()}
                  <div className="ml-4 mt-0 text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 space-x-3 space-x-reverse flex flex-row-reverse">
                  <Button
                    type="danger"
                    size="lg"
                    loading={loading}
                    onClick={() => onConfirm()}
                  >
                    {buttonName}
                  </Button>
                  <Button size="lg" onClick={() => onClose()}>
                    <Trans>Cancel</Trans>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { useCallback } from "react";
import { CreateOptionDTO, UpdateOptionDTO } from "../../types/option.dto";
import { createOptionClient, GetOptionsQueryParam } from "../api/createOptionClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { OptionState, useOptionStore } from "../store/optionStore";

export function useOptionService(propertyId: string) {
  const client = createOptionClient(propertyId);

  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const options = useOptionStore((state: OptionState) => state.options);
  const pagination = useOptionStore((state: OptionState) => state.pagination);
  const setOption = useOptionStore((state: OptionState) => state.setOption);
  const setOptions = useOptionStore((state: OptionState) => state.setOptions);
  const setOptionCategories = useOptionStore((state: OptionState) => state.setOptionCategories);
  const setPagination = useOptionStore(state => state.setPagination);

  const paginationQueryParams: GetOptionsQueryParam = {
    limit: pagination.pageSize,
    page: pagination.currentPage,
  };

  const getOption = useCallback(
    async (
      optionId: number,
    ) => {
      setLoading(true);

      try {
        const option = options.find(o => o.id === optionId);

        if (option) {
          setOption(option);
        } else {
          const res = await client.getOption(optionId);
          setOption(res.option);
        }

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));

        setLoading(false);
      }
    },
    [options],
  );

  const getOptions = useCallback(
    async (
      data?: GetOptionsQueryParam,
    ) => {
      try {
        setLoading(true);

        const res = await client.getOptions(data);
        setOptions(res.options);
        setPagination({
          ...res.pagination,
          pageSize: data?.limit ?? 30,
        });

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const getOptionCategories = useCallback(
    async () => {
      try {
        setLoading(true);

        const res = await client.getOptionCategories();
        setOptionCategories(res.categories);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const createOption = useCallback(
    async (
      data: CreateOptionDTO,
    ) => {
      try {
        setLoading(true);
        await client.createOption(data);
        await getOptions(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(true);
    },
    [],
  );

  const updateOption = useCallback(
    async (
      data: UpdateOptionDTO,
    ) => {
      try {
        setLoading(true);
        await client.updateOption(data);
        await getOptions(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [setError, setLoading, client],
  )

  const deleteOption = useCallback(
    async (
      id: number | string | any,
    ) => {
      try {
        setLoading(true);
        await client.deleteOption(id);
        await getOptions(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  )

  return {
    getOptions,
    getOption,
    getOptionCategories,
    createOption,
    updateOption,
    deleteOption,
  }
}


import { create } from "zustand";
import { Reservation } from "../../types/reservation";

export type MockTemairazuReservationState = {
  readonly setXmlResponse: (response: string) => void;
  readonly setReservations: (reservations: Reservation[]) => void;
  readonly setReservationInFocus: (reservation: Reservation | null) => void;
  readonly setInitialCallMade: (state: boolean) => void;
  readonly xmlResponse: string;
  readonly reservations: Reservation[];
  readonly reservationInFocus: Reservation | null;
  readonly initialCallMade: boolean;
}

export const useMockTemairazuReservationStore = create<MockTemairazuReservationState>((set, get) => ({
  xmlResponse: "",
  reservations: [],
  reservationInFocus: null,
  initialCallMade: false,
  setXmlResponse: (response: string) => set({ xmlResponse: response }),
  setReservations: (reservations: Reservation[]) => set({ reservations }),
  setReservationInFocus: (reservation: Reservation | null) => set({ reservationInFocus: reservation }),
  setInitialCallMade: (state: boolean) => set({ initialCallMade: state }),
}));

import { useState } from 'react'
import { Switch as ISwitch } from '@headlessui/react'
import { classNames } from '../../utils'

type SwitchProps = {
  label: string;
  description?: string;
  onChange: (value: boolean) => void;
}

export function Switch({ label, onChange, description }: SwitchProps) {
  const [enabled, setEnabled] = useState(false)

  const toggleChangeHandler = (value: boolean) => {
    setEnabled(value);
    onChange(value);
  }

  return (
    <ISwitch.Group as="div" className="flex items-center pl-5 pt-5">
      <ISwitch
        checked={enabled}
        onChange={toggleChangeHandler}
        className={classNames(
          enabled ? 'bg-gray-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </ISwitch>
      <ISwitch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{label}</span>{' '}
        {description ??
          <span className="text-gray-500">{description}</span>
        }
      </ISwitch.Label>
    </ISwitch.Group>
  )
}

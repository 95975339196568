import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { detect, fromCookie, fromNavigator } from "@lingui/detect-locale";
import { I18nContext } from "./I18nContext";
import { LocaleEnum } from "./types";
import dayjs from "dayjs";
import { Spin } from "antd";

const FALLBACK_LOCALE = () => LocaleEnum.English;

const isLocalePresent = (locale: string) =>
  Object.values(LocaleEnum as Record<string, string>).includes(locale);

export const I18nProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [locale, setLocale] = useState(LocaleEnum.English);
  const [loading, setLoading] = useState(true);

  const setLocaleIfPresent = useCallback((locale: string) => {
    if (isLocalePresent(locale)) {
      setLocale(locale as LocaleEnum);
    }
  }, []);

  useEffect(() => {
    const detectedLocale = detect(
      fromCookie("lang"),
      fromNavigator(),
      FALLBACK_LOCALE
    );

    if (detectedLocale) setLocaleIfPresent(detectedLocale);
  }, [setLocaleIfPresent]);

  const handleChangeLocale = (newLocale: LocaleEnum) => {
    if (locale === newLocale) return;
    document.cookie = `lang=${newLocale}`;
    localStorage.setItem("lang", newLocale);
    setLocale(newLocale);
  };

  useEffect(() => {
    // Dynamically load the catalogs
    setLoading(true);
    import(`../../locales/${locale}/messages`)
      .then((module) => {
        const messages = module.messages;

        i18n.load(locale, messages);
        i18n.activate(locale);
      })
      .catch((err) => {
        console.error(`Failed to load language file for locale ${locale}`, err);
      })
      .finally(() => {
        setLoading(false);
      });
    import(`dayjs/locale/${locale}.js`).then((module) => {
      dayjs.locale(locale);
    });
  }, [locale]);

  if (loading) {
    return <Spin />;
  }

  return (
    <I18nContext.Provider
      value={{
        locale,
        handleChangeLocale,
      }}
    >
      <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>
    </I18nContext.Provider>
  );
};

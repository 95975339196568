import { Button } from "../../../components/elements";
import React, { useEffect, useState } from "react";
import { Table } from "../../../components/datadisplay/Table";
import { Loading } from "../../../components/elements/Loading";
import { useUserStore, UserState } from "../../../store/userStore";
import { useSlideOverStore } from "../../../store/slideOverStore";
import { useNotificationStore } from "../../../store/notificationStore";
import { t, Trans } from "@lingui/macro";
import { ConfirmationModal } from "../../../components/elements/ConfirmationModal";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { useFacilityStore } from "../../../store/facilityStore";
import { useFacilityService } from "../../../services/useFacilityService";
import { FacilityForm } from "./FacilitySlideOver";
import { useGlobalStore } from "../../../store/globalStore";

export const FacilityListView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState<number| null>(null);
  const [itemToDeleteName, setItemToDeleteName] = useState<string | undefined>("")
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  const addNotification = useNotificationStore((state) => state.addNotification);

  const facilities = useFacilityStore(state => state.facilities);
  const propertyId = useGlobalStore(state => state.currentProperty);
  const user = useUserStore((state: UserState) => state.user);
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);

  const { getFacilities, deleteFacility } = useFacilityService(propertyId);

  const handleDeleteModal = (itemId: number) => {
    const facilityName = facilities.find(d => d.id === itemId)?.name;
    setItemToDeleteName(facilityName);
    setItemToDeleteId(itemId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDeleteId) {
      try {
        await deleteFacility(itemToDeleteId);
        setTimeout(() => {
          addNotification({
            title: t`Successfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }
    }

    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handleSort = (column: string, direction: 'asc' | 'desc') => {
    setSortColumn(column);
    setSortDirection(direction);
    setSearchParams(queryString.stringify({
      ...searchParams,
      sort: column,
      direction,
    }));
  };

  useEffect(() => {
    if (propertyId) {
      getFacilities();
    }
  }, []);

  return user ? (
    <div className="px-8">
      <div className="flex items-center">
        <div className="ml-8 mt-0 flex-none">
          <Button
            type="primary"
            className="px-4"
            onClick={() => setSlideOver(<FacilityForm />)}>
            <Trans>Add Facility</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <Table
          data={facilities.map(d => {
            return {
              id: d.id,
              name: d.name,
              description: d.description,
              department: d.department,
            }
          })}
          columns={[
            {
              key: "name",
              title: t`Name`,
              sortable: true,
            },
            {
              key: "description",
              title: t`Description`,
            },
            {
              key: "department",
              title: t`Department`,
            },
          ]}
          onSort={handleSort}
          currentSortColumn={sortColumn}
          currentSortDirection={sortDirection}

          onEdit={(item) => setSlideOver(
            <FacilityForm facility={facilities.find(d => d.id === item.id)} />
          )}
          onDelete={(id) => handleDeleteModal(id)}
        />
        <ConfirmationModal
          title={t`Delete OTA`}
          message={t`Are you sure you want to permanently remove this facility ${itemToDeleteName} from this property?`}
          buttonName={t`Delete`}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          isOpen={isModalOpen}
        />
      </div>
    </div>
  ) : <Loading />;
}

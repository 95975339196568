import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { createAuthClient } from "../../../api/createAuthClient";
import { Button } from "../../../components/elements";
import { LogoIcon } from "../../../components/icons";
import { useNotificationStore } from "../../../store/notificationStore";

type Inputs = {
  email: string;
};

export const RequestResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const { requestResetPassword } = createAuthClient();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<Inputs>();
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await requestResetPassword({
        email: data.email,
      });

      addNotification({
        title: "Success!",
        message: response.message,
        type: "success",
      });

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (e: any) {
      addNotification({
        title: "Unable to request for password reset",
        message: "Please contact IT support",
        type: "error",
      });
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 px-8">
      <div className="mx-auto w-full max-w-sm">
        <Link to="/">
          <LogoIcon className="mx-auto h-10 w-auto text-gray-900" />
        </Link>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Reset your password
        </h2>
      </div>
      <div className="mt-10 mx-auto w-full max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Enter your account's email address and we will send you a password
              reset link.
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                rules={{ required: "Email is required" }}
                name="email"
                render={({ field }) => (
                  <input
                    {...field}
                    placeholder="Enter your email here"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6"
                  />
                )}
              />
            </div>
            {errors.email && (
              <div className="text-red-500 text-sm">{errors.email.message}</div>
            )}
          </div>

          <div>
            <Button
              type="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Send password reset email
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

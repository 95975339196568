import React, { useEffect, useState } from "react";
import { useSlideOverStore } from "../../../store/slideOverStore";
import { Link, useLocation } from "react-router-dom";

export interface ActionTriggerButtonProps {
  label: React.ReactNode;
  urlSuffix: string;
  SlideOverComponent?: React.ComponentType<any>;
}

export const ActionTriggerButton: React.FC<ActionTriggerButtonProps> = ({
  label,
  urlSuffix,
  SlideOverComponent,
}) => {
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);
  const location = useLocation();
  const defaultLinkClassName =
    "text-sm rounded-md px-3 py-1.5 flex items-center";
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname.endsWith(urlSuffix)) {
      if (SlideOverComponent)
        setSlideOver(<SlideOverComponent urlSuffix={urlSuffix} />);
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);

  return (
    <Link
      to={urlSuffix}
      className={
        active ? `${defaultLinkClassName} bg-gray-200 text-gray-900` : `${defaultLinkClassName} hover:bg-gray-50 text-gray-600 hover:text-gray-900`
      }
    >
      {label}
    </Link>
  );
};

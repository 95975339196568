import { useState } from 'react'
import { Controller, SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { t } from '@lingui/macro';

import { SlideOver } from "../../../components/slideover/SlideOver";
import { useNotificationStore } from '../../../store/notificationStore';
import { InputGroup } from '../../../components/forms/Input';
import { useGlobalStore } from '../../../store/globalStore';
import { TextArea } from '../../../components/forms/TextArea';
import { FacilityDTO } from '../../../../types/facility.dto';
import { useFacilityService } from '../../../services/useFacilityService';
import { DepartmentDropDown } from './DepartmentDropDown';
import { useDepartmentStore } from '../../../store/departmentStore';

type Inputs = {
  name: string;
  description?: string;
  departmentId?: number;
}

type Param = {
  facility?: FacilityDTO;
}

export function FacilityForm({ facility}: Param) {
  const [open, setOpen] = useState(true);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const loading = useGlobalStore(state => state.loading);
  const propertyId = useGlobalStore(state => state.currentProperty);

  const setDepartment = useDepartmentStore(state => state.setDepartment);

  const { createFacility, updateFacility } = useFacilityService(propertyId);

  const methods = useForm<Inputs>({
    defaultValues: {
      name: facility?.name ?? "",
      description: facility?.description ?? "",
      departmentId: facility?.departmentId,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = methods;

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      if (facility) {
        await updateFacility({ ...data, id: facility.id });
      } else {
        await createFacility(data);
      }

      setOpen(false);

      const titleToUse = facility ? t`Facility successfully updated` : t`Facility successfully created`;

      setTimeout(() => {
        addNotification({
          title: titleToUse,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);
    } catch (e: unknown) {
      setTimeout(() => {
        addNotification({
          title: t`Unable to create / update facility`,
          type: "error",
          message: String(e),
          timeoutMs: 1500,
        });
      }, 500);

    }
  };

  const renderForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <InputGroup
                label={t`Name`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />

        </div>

        <div>
          <DepartmentDropDown />
        </div>

          <div className="px-6 py-4">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextArea
                  label={t`Description`}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>

      </div>
    )
  }
  
  const clearForm = () => {
    setDepartment(undefined);
    reset();
  }

  const disableSubmitButton = () =>
    isSubmitSuccessful ||
    isSubmitting ||
    loading ||
    !isValid;

  const title = facility ? t`Edit Facility` : t`Create new Facility`;

  return <SlideOver
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit(submitHandler)}
    afterLeave={() => clearForm()}
    title={title}
    disableSubmitButton={disableSubmitButton()}
    content={
      <FormProvider {...methods}>
        {renderForm()}
      </FormProvider>
    }
  />
}

import React, { useEffect } from "react";
import { SlideOver } from "../../../components/slideover/SlideOver";
import { t } from "@lingui/macro";
import { withUrlSlideOver } from "../../../components/hocs/withUrlSlideOver";
import { useGuestStore } from "../../../store/guestStore";
import {
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  AddReservationGuestDTO,
  UpdateReservationGuestDTO,
} from "../../../../types/guest.dto";
import { useReservationService } from "../../../services/useReservationService";
import { useParams } from "react-router-dom";
import { ReservationRouteParam } from "../routes/ReservationDetailView";
import { useGlobalStore } from "../../../store/globalStore";
import { useNotificationStore } from "../../../store/notificationStore";
import DynamicFormGroups from "../../../components/forms/DynamicFormGroups";
import { EngagementChannels } from "../../../../types/surveyResponse";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { GuestInformationGuestForm } from "./GuestInformationGuestForm";
import { GuestInformationSurveyForm } from "./GuestInformationSurveyForm";
import { useReservationStore } from "../../../store/reservationStore";
import { SurveyResponseDTO } from "../../../../types/surveyResponse.dto";

export type GuestInformationSlideOverProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const blankForm = {
  firstName: "",
  firstNameFurigana: "",
  lastName: "",
  lastNameFurigana: "",
  alternateName: "",
  kanjiName: null,
  email: "",
  phone: "",
  gender: "",
  age: null,
  country: "",
  nationality: "",
  province: "",
  dob: null,
  address1: "",
  address2: "",
  zipcode: "",
  optOutEmail: false,
  isRepresentative: false,
  ageGroup: "",
  city: "",
};

const blankSurveyForm = {
  engagementChannels: {
    onlineTravelAgency: "",
    officialWebsite: false,
    instagram: false,
    twitter: false,
    facebook: false,
    otherSocialMedia: "",
    television: "",
    travelAgency: false,
    newspaper: "",
    magazine: "",
    bookOrGuide: "",
    recommendation: false,
    other: "",
  },
  reservationGroupType: "",
} as SurveyResponseDTO;

function isUpdateReservationGuestDTO(
  obj: UpdateReservationGuestDTO | AddReservationGuestDTO
): obj is UpdateReservationGuestDTO {
  return (obj as UpdateReservationGuestDTO).id !== undefined;
}

type GuestInformationInputs = {
  guests: AddReservationGuestDTO[];
  engagementChannels: EngagementChannels;
  reservationGroupType: string;
};

const GuestInformationSlideOver: React.FC<GuestInformationSlideOverProps> = ({
  open,
  onOpen,
  onClose,
}) => {
  const params = useParams<ReservationRouteParam>();
  const propertyId = params.propertyId as string;
  const reservationId = params.reservationId as string;
  const {
    getReservationGuests,
    updateReservationGuest,
    addReservationGuest,
    deleteReservationGuest,
    addReservationSurveyResponse,
    getReservationSurveyResponse,
  } = useReservationService(propertyId);
  const guests = useGuestStore((state) => state.guests);
  const surveyResponse = useReservationStore((state) => state.surveyResponse);
  const loading = useGlobalStore((state) => state.loading);
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );

  useEffect(() => {
    if (Boolean(reservationId)) {
      getReservationGuests(Number(reservationId));
      getReservationSurveyResponse(Number(reservationId));
    }
  }, []);

  const methods = useForm<GuestInformationInputs>();
  const { reset } = methods;

  const defaultSurveyForm = surveyResponse ? surveyResponse : blankSurveyForm;

  useEffect(() => {
    reset({ guests, engagementChannels: defaultSurveyForm.engagementChannels, reservationGroupType: surveyResponse?.reservationGroupType });
  }, [guests, surveyResponse]);

  if (loading || !guests.length) {
    return null;
  }
  const onSubmit: SubmitHandler<GuestInformationInputs> = async (data: any) => {
    // store the data before sending it to the server. on error, we can reset the form to this data
    const originalData = data;
    try {
      const promises = data.guests.map(
        async (guest: UpdateReservationGuestDTO | AddReservationGuestDTO) => {
          if (isUpdateReservationGuestDTO(guest)) {
            return updateReservationGuest(Number(reservationId), guest);
          } else {
            return addReservationGuest(Number(reservationId), guest);
          }
        }
      );
      // check if there's any guest in guests whose id is not present in data.guests. if so, delete those guests
      const guestIds = data.guests.map(
        (guest: UpdateReservationGuestDTO | AddReservationGuestDTO) =>
          isUpdateReservationGuestDTO(guest) && guest.id
      );
      const deletePromises = guests
        .filter((guest) => !guestIds.includes(guest.id))
        .map((guest) =>
          deleteReservationGuest(Number(reservationId), guest.id)
        );

      promises.push(
        addReservationSurveyResponse(Number(reservationId), {
          engagementChannels: data.engagementChannels,
          reservationGroupType: data.reservationGroupType,
        })
      )
      await Promise.all([...promises, ...deletePromises]);
      onClose();

      setTimeout(() => {
        addNotification({
          title: t`Guest information updated`,
          type: "success",
          timeoutMs: 1500,
        });
      });
    } catch (e: unknown) {
      addNotification({
        title: t`Unable to update guest information`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
      reset(originalData);
    }
  };

  return (
    <FormProvider {...methods}>
      <SlideOver
        onClose={onClose}
        onOpen={onOpen}
        afterLeave={undefined}
        open={open}
        title={t`Edit Guest Information`}
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => methods.reset({ guests, ...defaultSurveyForm })}
      >
        <>
          <DynamicFormGroups
            name="guests"
            FormComponent={GuestInformationGuestForm}
            formMethods={methods}
            blankForm={blankForm}
            addLabel={<UserPlusIcon className="h-5 w-5 text-gray-600 " />}
          />
          <div className="ml-20 mt-3 mb-2">
            <GuestInformationSurveyForm methods={methods} />
          </div>
        </>
      </SlideOver>
    </FormProvider>
  );
};

export default withUrlSlideOver(GuestInformationSlideOver);

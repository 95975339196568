import { create } from "zustand";
import { UserDTO } from "../../types/user.dto";

export type UserState = {
  readonly user?: UserDTO;
  readonly authError?: string;
  readonly setUser: (user?: UserDTO) => void;
  readonly setAuthError: (err?: string) => void;
}

export const useUserStore = create<UserState>((set) => ({
  user: undefined,
  authError: "",
  setUser: (user?: UserDTO) => set({ user }),
  setAuthError: (err?: string) => set({ authError: err }),
}));

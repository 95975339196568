import React from "react";
import { useEffect } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { useNavigate } from "react-router";
import { Loading } from "../components/elements/Loading";

export const Interstitial: React.FC = () => {
  // we need to wait for the sidebar to resolve the current property based on local store + user info
  const currentProperty = useGlobalStore(
    (state: GlobalState) => state.currentProperty
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (currentProperty) {
      navigate(`/properties/${currentProperty}/dashboard`);
    }
  }, [currentProperty]);

  return <Loading />;
};

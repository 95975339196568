// @NOTE:
// In order to use this component properly, the form must be wrapped with <FormProvider>
// otherwise, the `useFormContext` will return null;
import { useEffect } from 'react'
import { t } from '@lingui/macro';

import { useOptionStore } from '../../../store/optionStore';
import { useOptionService } from '../../../services/useOptionService';
import { SelectInputGroup, Option } from '../../../components/forms/Select';
import { usePropertyStore } from '../../../store/propertyStore';
import { useFormContext } from 'react-hook-form';

type Props = {
  onChange: (selectedValue: any) => void;
}

export function OptionCategoryDropDown({ onChange }: Props) {
  const optionCategories = useOptionStore((state) => state.optionCategories);
  const setOptionCategory = useOptionStore((state) => state.setOptionCategory);
  const optionCategory = useOptionStore((state) => state.optionCategory);
  const setOption = useOptionStore((state) => state.setOption);
  const property = usePropertyStore(state => state.propertyInFocus);

  const propertyId = property?.id ?? "";

  const { getOptionCategories } = useOptionService(propertyId);
  const { getValues, setValue } = useFormContext<{optionCategoryId?: number}>();
  const optionCategoryId = getValues("optionCategoryId");

  useEffect(() => {
    if (propertyId) {
      getOptionCategories();
    }
  }, [propertyId]);

  useEffect(() => {
    if (optionCategoryId && optionCategories.length) {
      const category = optionCategories.find(o => o.id === optionCategoryId);
      setOptionCategory(category);
      setValue("optionCategoryId", optionCategoryId);
    }
  }, [optionCategoryId, optionCategories])

  const onSelectHandler = async (data: Option) => {
    const category = optionCategories.find(category => category.id === data.value);
    if (category) {
      setOptionCategory(category);
      setOption(undefined);
      onChange(category.id);
    }
  }

  return (
    <SelectInputGroup
      selectProps={{
        options: optionCategories.map(category => {
          return {
            value: category.id,
            label: category.name,
          }
        }),
        selected: optionCategory && optionCategory.name,
        inlineLabel: t`Select a category`,
        onChange: onSelectHandler,
      }}
      label={t`Option Category`}
    />
  )
}



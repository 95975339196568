import { ReservationDetailDTO } from "../../../../types/reservation.dto";
import { ActionTriggerButton } from "../../../components/elements";
import { Sidebar } from "../../../components/elements/Sidebar";

import { t } from "@lingui/macro";
import GuestInformationSlideOver from "./GuestInformationSlideOver";

import { NavItemProps } from "../../../components/elements/NavItem";
import { ChevronDownIcon, LinkIcon } from "@heroicons/react/16/solid";
import PlaceholderSlideOver from "./PlaceholderSlideOver";

type SidebarProps = {
  reservation: ReservationDetailDTO;
  propertyId: string;
};

export function ReservationDetailSidebar({
  reservation,
  propertyId,
}: SidebarProps) {
  const baseUrl = `/properties/${propertyId}/reservations/${reservation.id}`;

  const navItems: NavItemProps[] = [
    { label: t`Res. Info`, to: `${baseUrl}/info` },
    { label: t`Preference`, to: `${baseUrl}/preference` },
    { label: t`Cashier Info`, to: `${baseUrl}/cashier` },
    { label: t`F&B`, to: `${baseUrl}/fnb` },
    { label: t`SPA`, to: `${baseUrl}/spa` },
    { label: t`Activity`, to: `${baseUrl}/activity` },
    { label: t`Notes`, to: `${baseUrl}/notes` },
    { label: t`General Info`, to: `${baseUrl}/general` },
    { label: t`History`, to: `${baseUrl}/history` },
  ];

  return (
    <Sidebar
      navItems={navItems}
      topContent={
        <div>
          <ul className="space-y-1">
            <li key="linked-reservations">
              <ActionTriggerButton
                label={
                  <>
                    <LinkIcon className="h-4 w-4 mr-1" />
                    {reservation.linkedReservations.length}
                  </>
                }
                SlideOverComponent={PlaceholderSlideOver}
                urlSuffix="linked-reservations"
              />
            </li>
            <li key="invoice">
              <ActionTriggerButton
                label={t`Invoice (${reservation.invoiceItems.length})`}
                SlideOverComponent={PlaceholderSlideOver}
                urlSuffix="invoice"
              />
            </li>
            <li key="manage-guests">
              <ActionTriggerButton
                label={t`Manage Guests`}
                SlideOverComponent={GuestInformationSlideOver}
                urlSuffix="manage-guests"
              />
            </li>
            <li key="allocate-room">
              <ActionTriggerButton
                label={t`Room Allocation`}
                urlSuffix="allocate-room"
              />
            </li>
            <li key="documents">
              <ActionTriggerButton
                label={t`Documents`}
                SlideOverComponent={PlaceholderSlideOver}
                urlSuffix="documents"
              />
            </li>
            <li key="print">
              <ActionTriggerButton
                label={t`Print`}
                SlideOverComponent={PlaceholderSlideOver}
                urlSuffix="print"
              />
            </li>
            <li key="language">
              <ActionTriggerButton
                label={
                  <>
                    {reservation.invoicePrintoutLang || t`Language`}
                    <ChevronDownIcon className="h-5 w-5 ml-1" />
                  </>
                }
                SlideOverComponent={PlaceholderSlideOver}
                urlSuffix="language"
              />
            </li>
          </ul>
        </div>
      }
    />
  );
}

import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { t } from '@lingui/macro';
import { useSlideOverStore } from "../../store/slideOverStore";
import { Trans } from "@lingui/macro";
import { Button } from "../elements";

export type SlideOverProps = {
  onClose: (state?: any) => void;
  onOpen?: () => void;
  afterLeave?: () => void;
  onReset?: () => void;
  open: boolean;
  disableSubmitButton?: boolean;
  onSubmit?: () => void;
  content?: ReactJSXElement | ReactJSXElement[];
  title: string;
  description?: string;
  saveButtonName?: string;
  resetButtonName?: string;
  hideCancelButton?: boolean;
  children?: ReactJSXElement;
};

export function SlideOver({
  afterLeave,
  onClose,
  onOpen,
  content,
  open = false,
  title,
  description,
  disableSubmitButton = false,
  saveButtonName,
  resetButtonName,
  hideCancelButton = false,
  onSubmit,
  onReset,
  children,
}: SlideOverProps) {
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);
  useEffect(() => {
    if (onOpen) {
      onOpen();
    }
  }, []);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      appear={true}
      afterLeave={() => {
        setSlideOver(undefined);
        if (afterLeave) {
          afterLeave();
        }
      }}
    >
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <form
                    onSubmit={onSubmit}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 py-6 px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              {description}
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={onClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {content ? content : children}
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 py-5 px-6">
                      <div className="flex justify-end space-x-3">
                        {!hideCancelButton && (
                          <Button
                            onClick={onClose}
                            size="lg"
                          >
                            <Trans>Cancel</Trans>
                          </Button>
                        )}
                        {onReset && (
                          <Button
                            onClick={onReset}
                            size="lg"
                          >
                            <Trans>{resetButtonName || t`Reset`}</Trans>
                          </Button>
                        )}
                        <Button
                          type="primary"
                          size="lg"
                          disabled={disableSubmitButton}
                        >
                          {saveButtonName || t`Save`}
                        </Button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { t } from '@lingui/macro';

import { SlideOver } from "../../../components/slideover/SlideOver";
import { useNotificationStore } from '../../../store/notificationStore';
import { InputGroup } from '../../../components/forms/Input';
import { useGlobalStore } from '../../../store/globalStore';
import { usePropertyStore } from '../../../store/propertyStore';
import { Department } from '../../../../types/department';
import { useDepartmentService } from '../../../services/useDepartmentService';
import { TextArea } from '../../../components/forms/TextArea';

type Inputs = {
  name: string;
  description?: string;
  freeeId?: string;
  freeeCompanyId?: string;
  shortcut1?: string;
  shortcut2?: string;
}

type Param = {
  department?: Department;
}

export function DepartmentForm({ department }: Param) {
  const [open, setOpen] = useState(true);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const loading = useGlobalStore(state => state.loading);
  const property = usePropertyStore((state) => state.propertyInFocus);

  const { createDepartment, updateDepartment} = useDepartmentService(property?.id ?? "");

  const initialValues: Inputs = {
    name: "",
  }

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });


  useEffect(() => {
    if (department) {
      setValue("name", department.name);
      setValue("description", department.description);
      setValue("freeeId", department.freeeId ?? "");
      setValue("freeeCompanyId", department.freeeCompanyId ?? "");
      setValue("shortcut1", department.shortcut1 ?? "");
      setValue("shortcut2", department.shortcut2 ?? "");
    }
  }, [department])

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      if (department) {
        await updateDepartment({ ...data, id: department.id });
      } else {
        await createDepartment(data);
      }

      setOpen(false);

      const titleToUse = department ? t`Department successfully updated` : t`Department successfully created`;

      setTimeout(() => {
        addNotification({
          title: titleToUse,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);
    } catch (e: unknown) {
      setTimeout(() => {
        addNotification({
          title: t`Unable to create / update department`,
          type: "error",
          message: String(e),
          timeoutMs: 1500,
        });
      }, 500);

    }
  };

  const renderForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <InputGroup
                label={t`Name`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />

        </div>

        <div>
          <Controller
            control={control}
            name="freeeId"
            render={({ field }) => (
              <InputGroup
                label="Freee ID (if applicable)"
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="freeeCompanyId"
            render={({ field }) => (
              <InputGroup
                label={t`Email`}
                inputProps={{
                  name: field.name, 
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="shortcut1"
          render={({ field }) => (
            <InputGroup
              label={t`Shortcut 1`}
              inputProps={{
                name: field.name,
                value: field.value,
                onChange: (value) => {
                  field.onChange(value);
                }
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="shortcut2"
          render={({ field }) => (
            <InputGroup
              label={t`Shortcut 2`}
              inputProps={{
                name: field.name,
                value: field.value,
                onChange: (value) => {
                  field.onChange(value);
                }
              }}
            />
          )}
        />

          <div className="px-6 py-4">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextArea
                  label={t`Description`}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>

      </div>
    )
  }
  

  const disableSubmitButton = () =>
    isSubmitSuccessful ||
    isSubmitting ||
    loading ||
    !isValid;

  const title = department ? t`Edit Department` : t`Create new Department`;

  return <SlideOver
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit(submitHandler)}
    afterLeave={() => reset()}
    title={title}
    disableSubmitButton={disableSubmitButton()}
    content={renderForm()}
  />
}

import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { classNames, None } from '../../../utils'
import { UserCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReservationDetailDTO } from '../../../../types/reservation.dto'
import { useSlideOverStore } from '../../../store/slideOverStore';
import { CreateReservationMemoForm } from './ReservationCreateMemoSlideOver';
import { ThreeDotDropdown } from '../../../components/elements/ThreeDotDropdown';
import { CreateReservationMemoTopicForm } from './ReservationCreateMemoTopicSlideOver';
import { useReservationService } from '../../../services/useReservationService';
import { ReservationRouteParam } from '../routes/ReservationDetailView';
import { useNotificationStore } from '../../../store/notificationStore';
import { ConfirmationModal } from '../../../components/elements/ConfirmationModal';
import { Button } from '../../../components/elements';

type Props = {
  reservation: ReservationDetailDTO;
}

export function ReservationMemoSection({ reservation }: Props) {
  const setSlideOver = useSlideOverStore(state => state.setSlideOver);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const [isMemoModalOpen, setIsMemoModalOpen] = useState(false);
  const [memoToDeleteId, setMemoToDeleteId] = useState<number | null>(null);

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [topicToDeleteId, setTopicToDeleteId] = useState<number | null>(null);

  const params = useParams<ReservationRouteParam>();
  const propertyId = params.propertyId || "";

  const { deleteReservationMemoTopic, deleteReservationMemo } = useReservationService(propertyId);

  const handleDeleteMemoModal = (topicId: number, memoId: number) => {
    setTopicToDeleteId(topicId);
    setMemoToDeleteId(memoId);
    setIsMemoModalOpen(true);
  }

  const handleCloseDeleteMemoModal = () => {
    setIsMemoModalOpen(false);
    setMemoToDeleteId(null);
    setTopicToDeleteId(null);
  }

  const handleDeleteMemo = async () => {
    if (memoToDeleteId && topicToDeleteId) {
      try {
        await deleteReservationMemo(reservation.id, topicToDeleteId, memoToDeleteId);

        setTimeout(() => {
          addNotification({
            title: t`Succesfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            message: String(e),
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }
    }

    setIsMemoModalOpen(false);
    setMemoToDeleteId(null);
    setTopicToDeleteId(null);
  };

  const handleDeleteTopicModal = (itemId: number) => {
    setTopicToDeleteId(itemId);
    setIsTopicModalOpen(true);
  }

  const handleCloseDeleteTopicModal = () => {
    setIsTopicModalOpen(false);
    setTopicToDeleteId(null);
  }

  const handleDeleteTopic = async () => {
    if (topicToDeleteId) {
      try {
        await deleteReservationMemoTopic(reservation.id, topicToDeleteId);

        setTimeout(() => {
          addNotification({
            title: t`Succesfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            message: String(e),
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }

      setIsTopicModalOpen(false);
      setTopicToDeleteId(null);
    }
  }

  const renderMemos = () => {
    if (!None(reservation.memoTopics)) {
      const { memoTopics } = reservation;
      return (
        <>
          {Object.keys(memoTopics).map((category, idx) => 
            <ul
              key={`${category}-${idx}`}
              role="list"
              className="space-y-6 mb-16">
              {memoTopics[category].map((topic) => [
                <li key={topic.id} className="relative flex gap-x-4">
                  <div
                    className={classNames(
                      '-bottom-6',
                      'absolute left-0 top-0 flex w-6 justify-center'
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {topic.postedBy}
                    </span> <Trans>started a topic</Trans> <strong>{topic.title}</strong> <br/> {topic.createdAt}
                  </p>
                  <div className="absolute right-7">
                    <ThreeDotDropdown
                      items={[
                        { label: t`Edit`, onClick: () => setSlideOver(<CreateReservationMemoTopicForm existingTopic={topic} />) },
                        { label: t`Delete`, onClick: () => handleDeleteTopicModal(topic.id) },
                      ]}
                    />
                  </div>
                </li>,
                topic.memos.map((memo, idx) =>
                  <li key={memo.id} className="relative flex gap-x-4">
                    <div
                      className={classNames(
                        idx === topic.memos.length - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                      )}
                    >
                      <div className="w-px bg-gray-200" />
                    </div>
                    <UserCircleIcon
                      className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-400 text-white"
                    />
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-gray-50">
                      <div className="flex justify-between gap-x-4 pb-2">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">{memo.postedBy}</span> {memo.createdAt}
                        </div>
                        <div className="absolute right-7 top-3">
                          <button
                            className="text-gray-400 hover:text-gray-600 p-1"
                            onClick={() => setSlideOver(<CreateReservationMemoForm topic={topic} existingMemo={memo} />)}
                          >
                            <PencilIcon className="w-4 h-4" />
                          </button>

                          <button
                            className="text-gray-400 hover:text-gray-600 p-1"
                            onClick={() => handleDeleteMemoModal(topic.id, memo.id)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                      <p className="text-base leading-6 text-gray-500">{memo.content}</p>
                    </div>
                  </li>
                ),
                <div className="flex relative flex-auto" key={`${category}-comment`}>
                  <Button
                    type="primary"
                    onClick={() => setSlideOver(
                      <CreateReservationMemoForm topic={topic} />
                    )}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 absolute right-0 -top-3"
                  >
                    <Trans>Add Memo</Trans>
                  </Button>
                </div>
              ])}
            </ul>
          )}
          <ConfirmationModal
            title={t`Delete Memo Topic`}
            message={t`Are you sure you want to delete this memo topic? Deleting a topic will also delete all memos associated with it.`}
            buttonName={t`delete`}
            onClose={handleCloseDeleteTopicModal}
            onConfirm={handleDeleteTopic}
            isOpen={isTopicModalOpen}
          />
          <ConfirmationModal
            title={t`Delete Memo`}
            message={t`Are you sure you want to delete this memo?`}
            buttonName={t`delete`}
            onClose={handleCloseDeleteMemoModal}
            onConfirm={handleDeleteMemo}
            isOpen={isMemoModalOpen}
          />
        </>
      );
    }
  }

  return (
    <div className="mt-6 max-w-3xl">
      <h3 className="text-xl font-semibold leading-6 text-gray-900 pb-5">
        <Trans>
          Memos
        </Trans>
      </h3>
      {renderMemos()}
    </div>
  )
}

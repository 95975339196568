import { MockTemairazuInsertDTO } from "../../types/mockTemairazuInsertDTO"
import { OTA } from "../../types/ota";
import { Property } from "../../types/property";
import { Reservation } from "../../types/reservation";
import { DeepPartial } from "../utils";
import { apiClient } from "./createApiClient";

type PropertiesResponse = {
  message: string;
  properties: Property[];
}

type PropertyResponse = {
  message: string;
  property: Property;
}

type OTAsResponse = {
  message: string;
  otas: OTA[];
}

type ReservationsResponse = {
  message: string;
  reservations: Reservation[];
  pagination: {
    currentPage: number;
    pageCount: number;
    nextPageAvailable: number;
  }
}

type ReservationResponse = {
  message: string;
  reservation: Reservation;
}

export type ReservationsQueryParam = {
  page: string;
  limit: string;
  startDate?: string;
  endDate?: string;
}

export function createMockingbirdClient() {
  const createReservation = () => {};
  const createTemairazuReservation = () => {};

  const createMockTemairazuReservation = (propertyId: string, reservation: DeepPartial<MockTemairazuInsertDTO>) =>
    apiClient.post<MockTemairazuInsertDTO, string>(`/mockingbird/properties/${propertyId}/temairazu/reservations`, reservation);

  const getMockTemairazuReservations = (
    propertyId: string,
    data: ReservationsQueryParam,
  ) =>
    apiClient.get<ReservationsResponse>(
      `/mockingbird/properties/${propertyId}/reservations`, data,
    );

  const getMockTemairazuReservation = (propertyId: string, reservationId: number) =>
    apiClient.get<ReservationResponse>(`/mockingbird/properties/${propertyId}/reservations/${reservationId}`);

  const processTemairazuReservations = () =>
    apiClient.get("/mockingbird/mock/temairazu/process");

  const getOtas = () =>
    apiClient.get<OTAsResponse>(`/mockingbird/ota`);

  const getProperties = () =>
    apiClient.get<PropertiesResponse>("/mockingbird/properties");

  const getProperty = (id: string) =>
    apiClient.get<PropertyResponse>(`/mockingbird/properties/${id}`);

  return {
    createReservation,
    createTemairazuReservation,
    createMockTemairazuReservation,
    getMockTemairazuReservations,
    getMockTemairazuReservation,
    processTemairazuReservations,
    getOtas,
    getProperties,
    getProperty,
  }
}

import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { ReservationDetailDTO } from "../../../../types/reservation.dto";
import { DescriptionListEntry } from "../../../components/datadisplay/DescriptionListEntry";
import { Card } from "../../../components/elements/Card";
import { Title } from "../../../components/elements/typography/Title";
import { useSlideOverStore } from "../../../store/slideOverStore";
import GuestInformationSlideOver from "./GuestInformationSlideOver";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans, t } from "@lingui/macro";

type Props = {
  reservation: ReservationDetailDTO;
};

const guestInfoSuffix = "edit-guest-info";

export function GuestInformationSection({ reservation }: Props) {
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith(guestInfoSuffix)) {
      setSlideOver(<GuestInformationSlideOver urlSuffix={guestInfoSuffix} />);
    }
  }, [location.pathname]);

  return (
    <div>
      <div className="flex space-x-2">
        <Title level={4}>
          <Trans>Guest Information</Trans>
        </Title>
        <Link to={guestInfoSuffix} className="mt-1">
          <PencilSquareIcon className="h-5 w-5 text-gray-600 " />
        </Link>
      </div>
      <Card className="mb-5">
        <div>
          <dl className="grid grid-cols-3">
            <DescriptionListEntry title={t`Guest name`}>
              {reservation.guestName}
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Room name`}>
              {reservation.roomType}
            </DescriptionListEntry>
            {/* @NOTE: Booking price might change in the future */}
            <DescriptionListEntry title={t`Total`}>
              {reservation.bookingPrice}
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Reservation Unique ID`}>
              {reservation.temairazuOTABookingId}
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Arrival`}>
              {reservation.checkInDate}
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Departure`}>
              {reservation.checkOutDate}
            </DescriptionListEntry>
            {/* @NOTES: Length of stay should be calculated by DTO  */}
            <DescriptionListEntry title={t`Length of Stay`}>
              3
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Rooms`}>
              {reservation.numberOfRooms}
            </DescriptionListEntry>
            <DescriptionListEntry title={t`Number of Guests`}>
              {reservation.numberOfGuests}
            </DescriptionListEntry>
          </dl>
        </div>
      </Card>
    </div>
  );
}

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  Listbox,
  Transition,
  ListboxOptions,
  ListboxOption,
  ListboxButton,
} from "@headlessui/react";
import { Fragment } from "react";
import { classNames, getNestedValue } from "../../utils";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";
import {
  ChevronDownIcon,
  CheckIcon as MicroCheckIcon,
} from "@heroicons/react/16/solid";

export type Option = {
  value: string | number | any;
  label: string;
};

export type SelectOption = Option; // @NOTE: to avoid confusion with the 'Option' entity.

type SelectProps = {
  onChange: (selectedValue: any) => void;
  options: Option[];
  inlineLabel: string;
  selected?: string | number | any;
  size?: "xs" | "sm" | "md" | "lg";
  disabled?: boolean;
};

export function Select({
  onChange,
  options,
  inlineLabel,
  selected,
}: SelectProps) {
  return (
    <Listbox onChange={onChange}>
      <div className="relative mt-2">
        <Listbox.Button className="relative w-60 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 text-sm leading-6">
          <span className="block truncate">{selected || inlineLabel}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-[15rem] overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            {options.map((option, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  classNames(
                    active ? "bg-gray-600 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                  )
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      )}
                    >
                      {option.label}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-gray-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

type SelectInputGroupProps = {
  label: string;
  selectProps: SelectProps;
};

export function SelectInputGroup({
  label,
  selectProps,
}: SelectInputGroupProps) {
  return (
    <div className="grid grid-cols-3 gap-4 space-y-0 px-6 py-3">
      <div>
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-6 text-gray-900 mt-3"
        >
          {label}
        </label>
      </div>
      <div className="col-span-2">
        <Select {...selectProps} />
      </div>
    </div>
  );
}

export type StackedSelectInputGroupProps = {
  label: string;
  selectProps: {
    name: string;
    defaultValue?: string | number | any;
    options: Option[];
  };
  helpText?: string;
  className?: string;
  options?: RegisterOptions;
};
export function StackedSelectInputGroup({
  label,
  helpText,
  selectProps,
  className,
  options,
}: StackedSelectInputGroupProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const name = selectProps.name;
  const error = getNestedValue(errors, name)?.message;
  const showHelpText = helpText && !error;

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
        id={name}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-600 text-sm leading-6"
        defaultValue={selectProps?.defaultValue || ""}
        {...register(name, options)}
      >
        {!selectProps.defaultValue && (
          <option value="" disabled hidden>
            <Trans>Select an option</Trans>
          </option>
        )}
        {selectProps.options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}

export default function SelectBadge({
  onChange,
  options,
  inlineLabel,
  selected,
  size = "md",
  disabled = false,
}: SelectProps) {
  const selectedLabel = options.find(
    (option) => option.value === selected
  )?.label;
  let sizeClasses = "";

  if (size === "xs") {
    sizeClasses = "px-1.5 py-0.5 text-xs";
  } else if (size === "sm") {
    sizeClasses = "px-2 py-1 text-sm";
  } else if (size === "lg") {
    sizeClasses = "px-3 py-2 text-sm";
  } else {
    sizeClasses = "px-2.5 py-1.5 text-sm";
  }

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative">
            <div
              className={`inline-flex divide-x divide-gray-700 rounded-md shadow-sm ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <div
                className={`inline-flex items-center gap-x-1.5 rounded-l-md bg-gray-600 text-white shadow-sm ${sizeClasses}`}
              >
                <p className="font-semibold">{selectedLabel || inlineLabel}</p>
              </div>
              <ListboxButton
                className={`inline-flex items-center rounded-l-none rounded-r-md bg-gray-600 ${
                  !disabled && "hover:bg-gray-700"
                } ${sizeClasses}`}
                disabled={disabled}
              >
                <ChevronDownIcon
                  className={`${
                    size === "xs" ? "h-3 w-3" : "h-5 w-5"
                  } text-white`}
                  aria-hidden="true"
                />
              </ListboxButton>
            </div>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute left-0 z-20 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-gray-600 text-white" : "",
                        !focus ? "text-gray-900" : "",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, focus }) => (
                      <div className="flex flex-col">
                        <p
                          className={selected ? "font-semibold" : "font-normal"}
                        >
                          {option.label}
                        </p>
                        {selected ? (
                          <span
                            className={focus ? "text-white" : "text-gray-600"}
                          >
                            <MicroCheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

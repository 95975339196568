type DescriptionListEntryProps = {
  title: string;
  children: React.ReactNode;
};

export const DescriptionListEntry: React.FC<DescriptionListEntryProps> = ({
  title,
  children,
}) => {
  return (
    <div className="p-2">
      <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
      <dd className="text-sm leading-3 text-gray-700">
        {children}
      </dd>
    </div>
  );
};

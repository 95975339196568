import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ThemeConfig } from "antd/es/config-provider/context";

import { UserState, useUserStore } from "./store/userStore";
import { useAuthService } from "./services/useAuthService";
import { LocaleEnum, useI18nContext } from "./providers/I18nProvider";

export const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: "#ff8a56",
    blue6: "#455a64", // Header
  },
};

export const App: React.FC = () => {
  const navigate = useNavigate();
  const user = useUserStore((state: UserState) => state.user);
  const authError = useUserStore((state: UserState) => state.authError);
  const { handleChangeLocale } = useI18nContext();

  const { getCurrentUser } = useAuthService();

  useEffect(() => {
    if (!user) {
      getCurrentUser();
    } else {
      handleChangeLocale(user.locale as LocaleEnum);
    }

    if (authError) {
      navigate("/login");
    }
  }, [authError]);

  return (
    <ConfigProvider theme={defaultTheme}>
      <Outlet />
    </ConfigProvider>
  );
};

import { create } from "zustand";
import { RoomTypeListDTO } from "../../types/roomType.dto";
import { persist } from "zustand/middleware";

export type RoomState = {
  readonly roomTypes: RoomTypeListDTO;
  setRoomTypes: (roomTypes: RoomTypeListDTO) => void;
};

export const useRoomStore = create<RoomState>((set) => ({
  roomTypes: [],
  setRoomTypes: (roomTypes: RoomTypeListDTO) => set({ roomTypes }),
}));

import { create } from "zustand";
import {
  ReservationMemoCategoryListDTO,
  ReservationMemoCategoryDTO,
} from "../../types/reservationMemoCategory.dto";
import {
  ReservationMemoTopicDTO,
  ReservationMemoTopicListDTO,
} from "../../types/reservationMemo.dto";

export type ReservationMemoState = {
  readonly topic?: ReservationMemoTopicDTO;
  readonly topics: ReservationMemoTopicListDTO;
  readonly memoCategory?: ReservationMemoCategoryDTO;
  readonly memoCategories: ReservationMemoCategoryListDTO; 
  readonly setTopic: (topic: ReservationMemoTopicDTO) => void;
  readonly setTopics: (topics: ReservationMemoTopicListDTO) => void;
  readonly setMemoCategory: (category?: ReservationMemoCategoryDTO) => void;
  readonly setMemoCategories: (categories: ReservationMemoCategoryListDTO) => void;
}

export const useReservationMemoStore = create<ReservationMemoState>((set) => ({
  topic: undefined,
  topics: [],
  memoCategory: undefined,
  memoCategories: [],
  setTopic: (topic: ReservationMemoTopicDTO) => set({ topic }),
  setTopics: (topics: ReservationMemoTopicListDTO) => set({ topics }),
  setMemoCategory: (memoCategory?: ReservationMemoCategoryDTO) => set({ memoCategory }),
  setMemoCategories: (memoCategories: ReservationMemoCategoryListDTO) => set({ memoCategories }),
}));


import React from "react";

interface ButtonProps {
  loading?: boolean;
  type?: "primary" | "danger" | "default" | "text" | "link";
  size?: "xs" | "sm" | "md" | "lg";
  disabled?: boolean;
  onClick?: (state: any) => void;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  tabIndex?: number;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  type = "default",
  size = "md",
  disabled,
  children,
  onClick,
  icon,
  className,
  tabIndex,
}) => {
  const buttonType = !onClick ? "submit" : "button";
  let buttonClass = "font-semibold focus-visible:outline-2 focus-visible:outline-offset-2";

  if (type === "primary") {
    buttonClass += " bg-gray-600 shadow-sm hover:bg-gray-500 text-white focus-visible:outline-gray-600";
  } else if (type === "danger") {
    buttonClass += " bg-red-700 shadow-sm hover:bg-red-600 text-white focus-visible:outline-red-700";
  } else if (type === "text") {
    buttonClass += " text-gray-600 hover:text-gray-900 focus-visible:outline-gray-600";
  } else if (type === "link") {
    buttonClass += " text-blue-500 focus-visible:outline-blue-500";
  } else {
    buttonClass += " hover:bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 text-gray-800 focus-visible:outline-gray-300";
  }

  if (size === "xs") {
    buttonClass += " rounded px-2 py-1 text-xs";
  } else if (size === "sm") {
    buttonClass += " rounded px-2 py-1 text-sm";
  } else if (size === "lg") {
    buttonClass += " rounded-md px-3 py-2 text-sm";
  } else {
    buttonClass += " rounded-md px-1.5 py-2.5 text-sm";
  }

  if (disabled || loading) {
    buttonClass += " opacity-50 cursor-not-allowed";
  }


  return (
    <button
      className={`${buttonClass} ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      type={buttonType}
      tabIndex={tabIndex}
    >
      {icon && !loading && <span className="mr-2">{icon}</span>}
      {loading ? (
        <span className="flex">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {children}
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;

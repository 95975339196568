import { apiClient } from "./createApiClient";
import { UserDTO } from "../../types/user.dto";
import { ResetPasswordEmailType } from "../constants/common/mailer";

type LoginReqBody = {
  email: string;
  password: string;
};

type ResetPasswordReqBody = LoginReqBody & {
  resetPasswordToken: string;
};

type RequestResetPasswordReqBody = {
  email: string;
  emailType?: ResetPasswordEmailType;
};

export type EditUserReqBody = {
  locale: string;
};

export type LoginResponse = {
  message: string;
  error?: string;
};

export function createAuthClient() {
  const login = (data: LoginReqBody) =>
    apiClient.post<LoginReqBody, LoginResponse>("/login", data);

  const logout = () => apiClient.get<{ message: string }>("/logout");

  const resetPassword = (data: ResetPasswordReqBody) =>
    apiClient.post<ResetPasswordReqBody, LoginResponse>(
      "/reset-password",
      data
    );

  const requestResetPassword = (data: RequestResetPasswordReqBody) =>
    apiClient.post<{ email: string }, LoginResponse>(
      "/request-reset-password-url",
      data
    );

  const getUserInfo = () => apiClient.get<{ user: UserDTO }>("/me");

  const editUserInfo = (data: EditUserReqBody) =>
    apiClient.patch<EditUserReqBody, { user: UserDTO }>("/me", data);

  return {
    getUserInfo,
    login,
    logout,
    resetPassword,
    requestResetPassword,
    editUserInfo,
  };
}

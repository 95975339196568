import { Notification } from "./Notification";
import {
  Notification as NotificationType,
  useNotificationStore,
} from "../../store/notificationStore";
import { useGlobalStore } from "../../store/globalStore";
import { useEffect } from "react";
import { t } from "@lingui/macro";

export const Notifications = () => {
  const { notifications, dismissNotification, addNotification } =
    useNotificationStore();
  const error = useGlobalStore((state) => state.err);

  useEffect(() => {
    if (error) {
      addNotification({
        title: t`Error`,
        message: error,
        type: "error",
      });
    }
  }, [error]);

  return (
    <div
      aria-live="assertive"
      className="z-50 flex flex-col fixed inset-0 space-y-4 px-4 py-6 pointer-events-none p-6 items-start"
    >
      {notifications.map((notification: NotificationType) => (
        <Notification
          key={notification.id}
          notification={notification}
          onDismiss={dismissNotification}
        />
      ))}
    </div>
  );
};

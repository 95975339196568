import { create } from "zustand";
import { OtaDTO, OtaListDTO } from "../../types/ota.dto";
import { PaginationParam } from "../app.d";
import { defaultPaginationValues } from "./utils";

export type OTAState = {
  readonly otas: OtaListDTO;
  readonly ota?: OtaDTO;
  readonly pagination: PaginationParam;
  readonly setOtas: (otas: OtaListDTO) => void;
  readonly setOta: (ota: OtaDTO) => void;
  readonly setPagination: (pagination: PaginationParam) => void;
}

export const useOtaStore = create<OTAState>((set) => ({
  ota: undefined,
  otas: [],
  pagination: defaultPaginationValues,
  setOtas: (otas: OtaListDTO) => set({ otas }),
  setOta: (ota: OtaDTO) => set({ ota }),
  setPagination: (pagination: PaginationParam) => set({ pagination }),
}));


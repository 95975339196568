import { Trans, t } from "@lingui/macro";
import { ReservationDetailDTO } from "../../../../types/reservation.dto";
import SelectBadge from "../../../components/forms/Select";
import { DateWithTooltip } from "../../../components/elements/DateWithTooltip";
import { useReservationService } from "../../../services/useReservationService";
import { useParams } from "react-router-dom";
import { useGlobalStore } from "../../../store/globalStore";
import { useCallback, useEffect, useMemo } from "react";
import { useCommonService } from "../../../services/useCommonService";
import { RoomStatus } from "../../../constants/common/rooms";
import { useRoomService } from "../../../services/useRoomService";

type HeaderProps = {
  reservation: ReservationDetailDTO;
};

export function ReservationDetailHeader({ reservation }: HeaderProps) {
  const params = useParams<{ propertyId: string; reservationId: string }>();
  const { getReservationStatuses } = useCommonService();
  const { getReservation } = useReservationService(params.propertyId as string);
  const reservationStatuses = useGlobalStore(
    (state) => state.reservationStatuses
  );

  useEffect(() => {
    if (reservationStatuses.length === 0) getReservationStatuses();
  }, []);

  const { updateReservation } = useReservationService(
    params.propertyId as string
  );
  const { updateRoom } = useRoomService(params.propertyId as string);

  const reservationStatusOptions = useMemo(
    () =>
      reservationStatuses.map((status) => ({
        id: status.id,
        value: status.name,
        label: status.label,
      })),
    [reservationStatuses]
  );

  const roomStatusOptions = useMemo(
    () =>
      Object.entries(RoomStatus).map(([key, value]) => ({
        value: value,
        label: key,
      })),
    []
  );

  const handleReservationStatusChange = useCallback(
    async (data: any) => {
      await updateReservation({
        id: reservation.id,
        reservationStatus: data.id,
      });
    },
    [reservation]
  );

  const handleRoomStatusChange = useCallback(
    async (data: any) => {
      if (reservation.roomDetails) {
        await updateRoom(
          reservation.roomTypeDetails.id,
          reservation.roomDetails.id,
          {
            status: data.value,
          }
        );
        // reload reservation
        await getReservation(params.reservationId);
      }
    },
    [reservation]
  );

  return (
    <div className="bg-gray-200 p-8">
      <div className="flex flex-wrap space-x-6 items-center">
        <h1 className="text-lg font-semibold text-gray-700">
          {reservation.guestName}
        </h1>
        <div className="">
          <SelectBadge
            options={reservationStatusOptions}
            selected={reservation.reservationStatus}
            onChange={handleReservationStatusChange}
            inlineLabel={t`Status`}
          />
        </div>
      </div>
      <div className="flex flex-wrap space-x-6 text-sm">
        <div className="flex items-center space-x-3 mt-2">
          <div className="text-gray-500">
            {reservation.roomType} - {reservation.room || t`No Room Assigned`}
          </div>
          <SelectBadge
            disabled={reservation.roomDetails === null}
            options={roomStatusOptions}
            selected={reservation.roomDetails?.status}
            onChange={handleRoomStatusChange}
            inlineLabel={t`Room Status`}
            size="xs"
          />
        </div>
        <div className="text-gray-500 mt-2">
          <Trans>
            <span className="font-semibold">Adults</span>:{" "}
            {reservation.numberOfAdults || 0} /{" "}
            <span className="font-semibold">Children</span>:{" "}
            {reservation.numberOfChildren || 0}
          </Trans>
        </div>
        <div className="text-gray-500 mt-2">
          <Trans>
            <span className="font-semibold">In</span>:{" "}
            <DateWithTooltip date={reservation.checkInDate} /> /{" "}
            <span className="font-semibold">Out</span>:{" "}
            <DateWithTooltip date={reservation.checkOutDate} />
          </Trans>
        </div>
      </div>
    </div>
  );
}

import dayjs from "dayjs";
import { Inputs } from "../routes/mockingbird/CreateMockReservationForm";
import { RoomAndGuestList, RoomRateInformation, TemairazuXMLParseResult } from "./rawTypes";
import { faker } from "@faker-js/faker";
import { PropertyDTO } from "../../types/property.dto";

const { datatype } = faker;
export function transformMockInput (inputs: Inputs, property: PropertyDTO) {
  const roomAndGuestList: RoomAndGuestList[] = [];
  const roomRateInformationList: RoomRateInformation[] = []; // rate per day and room date
  const paymentType = inputs.prepaid ?  "事前決済" : "不明";
  const temairazuBookingDataId = datatype.uuid();
  const temairazuDataId = datatype.uuid();
  const otaBookingId = datatype.uuid();

  const packagePlan = property.plans?.find(plan => plan.id === inputs.packagePlanId)
  const packagePlanCode = packagePlan?.codes && packagePlan.codes.length ? packagePlan.codes[0] : "";

  for (const ratePerDay of inputs.ratesPerDay) {
    roomRateInformationList.push({
      RoomDate: [ratePerDay.date],
      TotalPerRoomRate: [ratePerDay.rate],
      PerPaxRate: [ratePerDay.rate / inputs.adults],
      PerChildA70Rate: [0],
      PerChildB50Rate: [0],
      PerChildC30Rate: [0],
      PerChildDRate: [0],
      PerChildOtherRate: [0],
    });
  }
  
  for (let i = 0; i < inputs.roomQuantity; i++) {
    roomAndGuestList.push({
      RoomInformation: [
        {
          RoomTypeCode: [inputs.roomTypeId],
          RoomTypeName: [inputs.roomTypeId],
          PerRoomPaxCount: [inputs.adults],
          RoomPaxMaleCount: [0],
          RoomPaxFemaleCount: [0],
          RoomChildA70Count: [0],
          RoomChildB50Count: [0],
          RoomChildC30Count: [0],
          RoomChildDNoneCount: [0],
        }
      ],
      RoomRateInformation: roomRateInformationList,
      GuestInformation: [
        {
          GuestInformationList: [
            {
              GuestEmail: [inputs.contactEmail],
              GuestNameSingleByte: [inputs.guestFirstName],
              GuestSurName: [inputs.guestSurName],
              GuestKanjiName: [""],
              GuestPhoneNumber: [""],
              GuestPostalCode: [""],
              GuestStateProvidence: [""],
            }
          ]
        }
      ],
    })
  }

  const result: TemairazuXMLParseResult = {
    AllotmentBookingReports: {
      TransactionInformation: [
        {
          DataID: [ temairazuDataId ],
          EndFlag: [ "True" ],
        }
      ],
      AllotmentBookingReport: [
        {
          TransactionType: [
            {
              DataFrom: ["FromTravelAgency"],
              DataClassification: ["NewBookReport"],
              BookingDataID: [ temairazuBookingDataId ],
              SystemDate: [dayjs().format("YYYY-MM-DD")],
              SystemTime: [dayjs().format("HH:MM:SS")],
            },
          ],
          AccommodationInformation: [
            {
              AccommodationName: [inputs.propertyCode],
              AccommodationCode: [inputs.propertyCode],
            },
          ],
          SalesOfficeInformation: [
            {
              SalesOfficeCompanyCode: [inputs.otaCode],
              SalesOfficeCompanyName: [inputs.otaCode],
              SalesOfficeName: [""],
              SalesOfficeCode: [""],
              SalesOfficePersonInCharge: [""],
              SalesOfficePhoneNumber: [""],
            }
          ],
          BasicInformation: [
            {
              TravelAgencyBookingNumber: [otaBookingId],
              TravelAgencyBookingDate: [dayjs().format("YYYY-MM-DD")],
              TravelAgencyBookingTime: [dayjs().format("HH:MM:SS")],
              GuestOrGroupNameSingleByte: [inputs.guestFirstName],
              GuestOrGroupNameDoubleByte: [""],
              GuestOrGroupNameKanjiName: [""],
              CheckInDate: [inputs.checkInDate],
              CheckOutDate: [inputs.checkOutDate],
              CheckInTime: ["15:00"],
              Nights: [inputs.nights],
              PackagePlanName: [packagePlan?.name || ""],
              PackagePlanCode: [packagePlanCode],
              MealCondition: [inputs.mealCondition],
              SpecificMealCondition: [inputs.specificMealCondition],
              SpecialServiceRequest: [inputs.specialServiceRequest],
              OtherServiceInformation: [inputs.otherServiceInformation],
            },
          ],
          BasicRateInformation: [
            {
              RoomRateOrPersonalRate: ["PersonalRate"],
              TaxServiceFee: ["IncludingServiceAndTax"],
              Payment: [paymentType],
              TotalAccommodationCharge: [inputs.price],
            }
          ],
          RoomAndGuestInformation: [
            {
              RoomAndGuestList: roomAndGuestList,
            }
          ],
          Member: [
            {
              UserName: [inputs.guestFirstName],
              UserKana: [inputs.guestFirstName],
              UserTel: [inputs.phoneNumber],
              UserMailAddr: [inputs.contactEmail],
              UserZip: [faker.address.zipCode()],
              UserAddr: [inputs.address],
              UserGivinPoints: [0],
              UserUsePoints: [inputs.usePoints],
              TotalAccommodationDecleasePoints: [inputs.price],
              AmountClaimed: [inputs.price],
            }
          ],
        }
      ],
    }
  }

  return result;
}

import { TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { Button } from "../elements";

type DynamicFormGroupsProps = {
  name: string;
  FormComponent: React.ComponentType<any>;
  formMethods: UseFormReturn<any>;
  blankForm: object;
  addLabel: string | React.ReactNode;
  extraForms?: number;
};

const DynamicFormGroups = ({
  name,
  FormComponent,
  formMethods,
  blankForm,
  addLabel,
  extraForms = 0,
}: DynamicFormGroupsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name,
  });

  // on load, append extraForms
  useEffect(() => {
    for (let i = 0; i < extraForms; i++) {
      append(blankForm);
    }
  }, [])

  const handleAddTab = () => {
    append(blankForm);
    setSelectedTab(fields.length); // Select the new tab
  };

  const handleRemoveTab = (index: number) => {
    remove(index);
    setSelectedTab(index - 1);
  };

  return (
    <div className="flex">
      <ul className="mt-3 ml-3 flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 me-3 mb-0">
        {fields.map((field, index) => (
          <li key={field.id}>
            <Button
              onClick={(e) => {
                setSelectedTab(index);
                e.preventDefault();
              }}
              size="lg"
              className={`inline-flex items-center justify-end w-12 ${
                index === selectedTab
                  && "!bg-gray-700 !text-white"
              }`}
            >
              #&nbsp;{index + 1}
            </Button>
          </li>
        ))}
        <li>
          <Button
            size="lg"
            onClick={(e) => {
              handleAddTab();
              e.preventDefault();
            }}
            className="inline-flex items-center justify-end w-12"
          >
            {addLabel}
          </Button>
        </li>
      </ul>
      <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full mt-3">
        {fields.map((field, index) => (
          <div
            key={field.id}
            style={{ display: index === selectedTab ? "block" : "none" }}
          >
            {index !== 0 && (
              <div className="flex flex-row-reverse">
              <button
                type="button"
                className="relative text-gray-400 hover:text-gray-500"
                onClick={() => handleRemoveTab(index)}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Remove Guest</span>
                <TrashIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              </div>
            )}
            <FormComponent control={formMethods.control} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicFormGroups;

import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { createAuthClient } from "../../../api/createAuthClient";
import { getEmailFromSearchParams } from "../../../utils";
import { LogoIcon } from "../../../components/icons";
import { useNotificationStore } from "../../../store/notificationStore";
import { Button } from "../../../components/elements";

type Inputs = {
  email: string;
  password: string;
  confirm: string;
};

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { resetPassword } = createAuthClient();

  const [searchParams] = useSearchParams();
  const email = getEmailFromSearchParams(searchParams);
  const token = searchParams.get("token");
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    watch,
    setError,
    clearErrors,
  } = useForm<Inputs>();
  const watchPassword = watch("password");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (email && token) {
      try {
        const response = await resetPassword({
          email,
          password: data.password,
          resetPasswordToken: token,
        });
        addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
        });
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      } catch (e: any) {
        addNotification({
          title: "Unable to reset password",
          message: e.response.data.message,
          type: "error",
        });
      }
    } else {
      addNotification({
        title: "Unable to reset password",
        message: "Please contact IT support",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-8 py-12">
      <div className="mx-auto w-full max-w-sm">
        <Link to="/">
          <LogoIcon className="mx-auto h-10 w-auto text-gray-900" />
        </Link>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Set a new password
        </h2>
      </div>
      <div className="mt-10 mx-auto w-full max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                rules={{ required: "Password is required" }}
                name="password"
                render={({ field }) => (
                  <input
                    {...field}
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6"
                  />
                )}
              />
            </div>
            {errors.password && (
              <div className="text-red-500 text-sm">
                {errors.password.message}
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="confirm"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                rules={{ required: "Confirm password is required" }}
                name="confirm"
                render={({ field }) => (
                  <input
                    {...field}
                    type="password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6"
                    onBlur={(e: any) => {
                      if (e.target.value !== watchPassword) {
                        setError("confirm", {
                          message: "Passwords do not match!",
                        });
                      } else {
                        clearErrors("confirm");
                      }
                    }}
                  />
                )}
              />
            </div>
            {errors.confirm && (
              <div className="text-red-500 text-sm">
                {errors.confirm.message}
              </div>
            )}
          </div>

          <div>
            <Button
              type="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

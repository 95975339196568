import { useCallback } from "react";
import { createMockingbirdClient } from "../api/createMockingbirdClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { PropertyState, usePropertyStore } from "../store/propertyStore";
import { None } from "../utils";

export function useMockingbirdPropertyService() {
  const client = createMockingbirdClient();

  const setProperties = usePropertyStore((state: PropertyState) => state.setProperties);
  const setPropertyInFocus = usePropertyStore((state: PropertyState) => state.setPropertyInFocus);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const setError = useGlobalStore((state: GlobalState) => state.setError);

  const getProperties = useCallback(
    async () => {
      try {
        setLoading(true);

        const res = await client.getProperties();
        setProperties(res.properties);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    }, 
    [setError, setProperties, client, setLoading],
  )

  const getProperty = useCallback(
    async (propertyId: string) => {
      try {
        setLoading(true);

        const res = await client.getProperty(propertyId);
        if (None(res.property)) throw new Error("Property not found");
        setPropertyInFocus(res.property);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [setPropertyInFocus, setLoading, client, setError],
  )

  return {
    getProperties,
    getProperty,
  }
}

import React from "react";
import { Link, useLocation } from "react-router-dom";

export interface NavItemProps {
  icon?: React.ReactNode;
  label: string;
  to: string;
  isSelected?: boolean;
  pathRegex?: RegExp;
}

export const NavItem: React.FC<NavItemProps> = ({
  icon = null,
  label,
  to,
  isSelected = false,
  pathRegex = undefined,
}) => {
  const pathname = useLocation().pathname
  let isActive = isSelected;
  if (pathRegex) {
    isActive = isActive || pathRegex.test(pathname);
  } else {
    isActive = isActive || pathname === to;
  }
  return (
  <Link
    to={to}
    className={`${
      isActive
        ? "bg-gray-200 text-gray-900 font-semibold"
        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
    } group flex items-center rounded-md px-2 py-2 text-sm font-medium`}
  >
    {icon}
    <span className="ml-3">{label}</span>
  </Link>
  )
};

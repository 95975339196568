import React from "react";
import { Route, Routes } from "react-router-dom";
import { TeamOutlined } from "@ant-design/icons";
import UserSettingsContent from "../components/UserSettingsContent";
import { t } from "@lingui/macro";
import { ContentLayout } from "../../../components/layouts";
import { NavItem } from "../../../components/elements/NavItem";
import { UsersIcon } from "@heroicons/react/24/outline";

export const UserSettingsView: React.FC = () => {
  return (
    <ContentLayout
      sideColumn={
        <nav className="px-3">
          <div className="space-y-1">
            <NavItem
              icon={<UsersIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />}
              label={t`Basic Information`}
              to="/settings/basic"
            />
          </div>
        </nav>
      }
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Routes>
          <Route path="basic" element={<UserSettingsContent />} />
        </Routes>
      </div>
    </ContentLayout>
  );
};

import { useNavigate } from "react-router";
import { Button } from "../components/elements";
import { Trans } from "@lingui/macro";

// create 404 page
export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="grid place-items-center h-screen">
      <div className="flex items-center">
        <Trans>
          <h1>We can't find your page. Please check your URL.</h1>
        </Trans>
        <Button type="link" onClick={() => navigate("/")}>
          <Trans>Go Home?</Trans>
        </Button>
      </div>
    </div>
  );
};

import { apiClient, GenericHTTPResponse } from "./createApiClient";
import { OptionListDTO, OptionCategoryListDTO, OptionDTO, CreateOptionDTO, UpdateOptionDTO } from "../../types/option.dto";
import { PaginationParam } from "../app.d";

export type GetOptionsQueryParam = {
  page?: number;
  limit?: number;
  categoryId?: number;
}

type GetOptionsResponse = {
  message: string;
  options: OptionListDTO;
  pagination: PaginationParam;
}

type GetOptionResponse = {
  message: string;
  option: OptionDTO;
}

type GetOptionCategoriesResponse = {
  message: string;
  categories: OptionCategoryListDTO;
}

export function createOptionClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/options`;

  const getOptions = (
    data?: GetOptionsQueryParam,
  ) => apiClient.get<GetOptionsResponse>(baseUrl, data);

  const getOption = (optionId: number) => apiClient.get<GetOptionResponse>(`${baseUrl}/${optionId}`);

  const getOptionCategories = () => apiClient.get<GetOptionCategoriesResponse>(`${baseUrl}/categories`);

  const createOption = (data: CreateOptionDTO) => apiClient.post<
    CreateOptionDTO,
    GenericHTTPResponse
  >(baseUrl, data);

  const updateOption = (data: UpdateOptionDTO) => apiClient.patch<
    UpdateOptionDTO,
    GenericHTTPResponse
  >(`${baseUrl}/${data.id}`, data);

  const deleteOption = (optionId: number) => apiClient.delete<
    GenericHTTPResponse
  >(`${baseUrl}/${optionId}`)

  return {
    getOptions,
    getOption,
    getOptionCategories,
    createOption,
    updateOption,
    deleteOption,
  }
}


import React, { useEffect } from 'react';
import { Space, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { MockTemairazuReservationState, useMockTemairazuReservationStore } from '../../store/mockTemairazuReservationStore';
import { PropertyRouteParam } from './CreateMockReservationForm';
import { GlobalState, useGlobalStore } from '../../store/globalStore';
import { useMockTemairazuReservationService } from '../../services/useMockTemairazuReservationService';
import Table from 'antd/es/table';
import Column from 'antd/es/table/Column';
import { Guest } from '../../../types/guest';
import { Plan } from '../../../types/plan';
import { RoomType } from '../../../types/roomType';
import { Button } from '../../components/elements';
import { Trans } from '@lingui/macro';

export const MockReservationListView: React.FC = () => {
  const params = useParams<PropertyRouteParam>();

  const reservations = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.reservations);
  const loading = useGlobalStore((state: GlobalState) => state.loading);

  const { getReservations } = useMockTemairazuReservationService();

  useEffect(() => {
    if (params.propertyId) {
      getReservations(params.propertyId, { limit: "100", page: "1" });
    }
  }, [params.propertyId]); // eslint-disable-line

  return (
    <>
    <Link to={`/mockingbird/mock/properties/${params.propertyId}/create-mock-reservation`}>
      <Button type="primary">Create Reservation</Button>
    </Link>
    <Table
      dataSource={reservations}
      loading={loading}
      rowKey={(record) => record.id}>
      <Column
        title="Check In"
        key="checkInDate"
        dataIndex="checkInDate"
      />

      <Column
        title="Check Out"
        key="checkOutDate"
        dataIndex="checkOutDate"
      />

      <Column
        title="Room"
        key="roomType"
        dataIndex="roomType"
        render={(roomType: RoomType) => (<>{roomType.name}</>)}
      />

      <Column
        title="Guest"
        key="guestName"
        dataIndex="guests"
        render={(guests: Guest[]) => (<>{`${guests[0].firstName} ${guests[0].lastName}`}</>)}
      />

      <Column
        title="# of Guests"
        key="adults"
        dataIndex="adults"
      />

      <Column
        title="Temairazu Booking ID"
        key="temairazuBookingId"
        dataIndex="temairazuOTABookingId"
      />

      <Column
        title="Package"
        key="packagePlan"
        dataIndex="otaPlan"
        render={(plan: Plan) => plan ? (<>{plan.name}</>) : null}
      />

      <Column
        title="Multiple Room Reservation"
        key="linkedReservations"
        dataIndex="linkedReservations"
        render={(linkedReservations) => {
          if (linkedReservations && linkedReservations.length) {
            return <Tag color="default"><Trans>YES</Trans></Tag>;
          } else return null;
        }}
      />

      <Column
        title=""
        key="view"
        dataIndex="id"
        render={(reservationId: string) => (
          <Space size="small">
            <Link to={`/mockingbird/mock/properties/${params.propertyId}/reservations/${reservationId}`} state={{ fromListView: true }}>View details</Link>
          </Space>
        )}
      />
    </Table>
    </>
  )
}

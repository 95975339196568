import { ChangeEvent } from "react";

export type TextAreaProps = {
  value?: string;
  label: string;
  required?: boolean;
  rows?: number;
  fullWidth?: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function TextArea({
  value,
  label,
  onChange,
  required = false,
  rows = 4,
  fullWidth = true,
}: TextAreaProps) {
  return (
    <div>
      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
        {`${label}${required ? "*" : ""}`}
      </label>
      <div className="mt-2">
        <textarea
          required={required}
          rows={rows}
          className={`block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm ${fullWidth ? "w-full" : "w-3/6"}`}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

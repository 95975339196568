import React, { useCallback, useEffect, useState } from "react";
import { Table, Modal, Form, Input, Tag, Select, message } from "antd";
import { PropertyPermissionGroup } from "../../../../types/propertyPermissionGroup";
import { useOrgSettingsService } from "../services/useOrgSettingsService";
import { OrgState, useOrgStore } from "../../../store/orgStore";
import { enumToOptionsList } from "../../../utils";
import { PropertyPermission } from "../../../constants/common/permissions";
import { Button } from "../../../components/elements";
import { Controller, useForm } from "react-hook-form";
import { Trans, t } from "@lingui/macro";

const GroupsPage: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState<number | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const groups = useOrgStore(
    (state: OrgState) => state.propertyPermissionGroups
  );

  const {
    getPropertyPermissionGroups,
    createPropertyPermissionGroup,
    editPropertyPermissionGroup,
    deletePropertyPermissionGroup,
  } = useOrgSettingsService(organizationId);
  const permissionOptions = enumToOptionsList(PropertyPermission);

  // on load, fetch groups
  useEffect(() => {
    getPropertyPermissionGroups();
  }, [organizationId]);

  const handleEdit = (groupId: number) => {
    const groupToEdit = groups.find((group) => group.id === groupId);
    if (groupToEdit) {
      setValue("name", groupToEdit.name);
      setValue("permissions", groupToEdit.permissions);
      setEditingGroupId(groupId);
      setIsModalOpen(true);
    }
  };

  const handleDelete = (groupId: number) => {
    deletePropertyPermissionGroup(String(groupId));
  };

  const handleSave = useCallback(
    handleSubmit(async (values: any) => {
      if (editingGroupId) {
        await editPropertyPermissionGroup(String(editingGroupId), values);
      } else {
        await createPropertyPermissionGroup({
          organization: organizationId,
          ...values,
        });
      }

      reset();
      setEditingGroupId(null);
      setIsModalOpen(false);
    }),
    [handleSubmit, editingGroupId, organizationId]
  );

  const columns = [
    {
      title: t`Name`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: t`Permissions`,
      dataIndex: "permissions",
      key: "permissions",
      render: (permissions: string[]) => (
        <>
          {permissions.map((permission) => (
            <Tag key={permission}>
              {
                permissionOptions.find((option) => option.value === permission)
                  ?.label
              }
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t`Actions`,
      key: "actions",
      render: (_: any, group: PropertyPermissionGroup) => (
        <>
          <Button
            type="link"
            onClick={() => handleEdit(group.id)}
            disabled={group.organization == null}
          >
            <Trans>Edit</Trans>
          </Button>
          <Button
            type="link"
            onClick={() => handleDelete(group.id)}
            disabled={group.organization == null}
          >
            <Trans>Delete</Trans>
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={groups} rowKey="id" />
      <Button
        type="primary"
        onClick={() => {
          reset({ name: "", permissions: [] });
          setIsModalOpen(true);
        }}
      >
        <Trans>Add Group</Trans>
      </Button>

      <Modal
        title={editingGroupId ? t`Edit Group` : t`Add Group`}
        open={isModalOpen}
        onCancel={() => {
          reset();
          setEditingGroupId(null);
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              reset();
              setEditingGroupId(null);
              setIsModalOpen(false);
            }}
          >
            <Trans>Cancel</Trans>
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            <Trans>OK</Trans>
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Controller
            name="name"
            control={control}
            rules={{
              required: t`Please enter a name`,
            }}
            render={({ field, fieldState }) => (
              <Form.Item label={t`Name`}>
                <Input {...field} />
                {fieldState.error && (
                  <p style={{ color: "red" }}>{fieldState.error.message}</p>
                )}
              </Form.Item>
            )}
          />

          <Controller
            name="permissions"
            control={control}
            rules={{
              required: t`Please select permissions`,
            }}
            render={({ field, fieldState }) => (
              <Form.Item label={t`Permissions`}>
                <Select
                  {...field}
                  mode="multiple"
                  options={permissionOptions}
                />
                {fieldState.error && (
                  <p style={{ color: "red" }}>{fieldState.error.message}</p>
                )}
              </Form.Item>
            )}
          />
        </Form>
      </Modal>
    </div>
  );
};

export default GroupsPage;

import { ChangeEvent, useEffect, useState } from "react";
import { Transition } from '@headlessui/react'
import { t } from '@lingui/macro';
import { useParams } from "react-router-dom";

import { SlideOver } from "../../../components/slideover/SlideOver";
import { Checkbox } from "../../../components/forms/Checkbox";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useReservationStore } from "../../../store/reservationStore";
import { Some } from "../../../utils";
import { TextArea } from "../../../components/forms/TextArea";
import { useUserStore } from "../../../store/userStore";
import { useReservationService } from "../../../services/useReservationService";
import { ReservationRouteParam } from "../routes/ReservationDetailView";
import { useNotificationStore } from "../../../store/notificationStore";
import { ReservationMemoDTO, ReservationMemoTopicDTO } from "../../../../types/reservationMemo.dto";

type Inputs = {
  content: string;
  showOnDailyPlannerFB: boolean;
  showOnDailyPlannerFull: boolean;
}

type Props = {
  topic: ReservationMemoTopicDTO;
  existingMemo?: ReservationMemoDTO;
}

export function CreateReservationMemoForm({ topic, existingMemo = undefined }: Props) {
  const [open, setOpen] = useState(true);

  const reservation = useReservationStore(state => state.reservation);
  const user = useUserStore(state => state.user);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const params = useParams<ReservationRouteParam>();

  const { addReservationMemo, updateReservationMemo } = useReservationService(params.propertyId || "");

  const initialValues: Inputs = {
    content: "",
    showOnDailyPlannerFull: true,
    showOnDailyPlannerFB: true,
  }

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid, isDirty }
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (existingMemo) {
      setValue("content", existingMemo.content);
      setValue("showOnDailyPlannerFB", existingMemo.showOnDailyPlannerFB);
      setValue("showOnDailyPlannerFull", existingMemo.showOnDailyPlannerFull);
    }
  }, [existingMemo]);

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    if (Some(reservation) && Some(user)) {
      try {
        const { content, showOnDailyPlannerFB, showOnDailyPlannerFull } = data;
        if (existingMemo) {
          await updateReservationMemo(
            reservation.id,
            topic.id,
            {
              id: existingMemo.id,
              content,
              showOnDailyPlannerFull,
              showOnDailyPlannerFB,
              userId: user.id,
            }
          );

        } else {
          await addReservationMemo(
            reservation.id,
            {
              content,
              showOnDailyPlannerFull,
              showOnDailyPlannerFB,
              userId: user.id,
              topicId: topic.id,
            }
          );
        }

        setOpen(false);

        setTimeout(() => {
          addNotification({
            title: existingMemo ? t`Memo successfully updated` : t`Memo successfully added`,
            type: "success",
            timeoutMs: 1500,
          });
        }, 500);
      } catch (e: unknown) {
        addNotification({
          title: t`Unable to create reservation memo`,
          type: "error",
          message: String(e),
          timeoutMs: 1500,
        });
      }
    }
  }

  const renderMemoForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div className="px-6 pt-4">
          <Controller
            control={control}
            name="showOnDailyPlannerFB"
            render={({ field }) => (
              <Checkbox
                field={field}
                label={t`Show on Daily Planner F&B`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.checked)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="showOnDailyPlannerFull"
            render={({ field }) => (
              <Checkbox
                field={field}
                label={t`Show on Daily Planner Full`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.checked)
                }}
              />
            )}
          />
        </div>
        <div className="px-6 py-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="content"
            render={({ field }) => (
              <TextArea
                label={t`Memo`}
                rows={6}
                value={field.value}
                required={true}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
        </div>
      </div>
    )
  }

  const clearForm = () => {
    reset();
  }

  const disableSubmitButton = () =>
    isSubmitting ||
      isSubmitSuccessful ||
      !isDirty ||
      !isValid;

  const generateForm = () => (
    <div className="space-y-0 divide-y divide-gray-200 py-0">
      <Transition
        enter="ease-in-out duration-250"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {renderMemoForm}
      </Transition>
    </div>
  );

  return <SlideOver
    title={t`Add memo to topic` + " " + `"${topic.title}"`}
    open={open}
    onClose={() => setOpen(false)}
    afterLeave={() => {
      clearForm();
    }}
    disableSubmitButton={disableSubmitButton()}
    onSubmit={handleSubmit(submitHandler)}
    content={generateForm()}
  />
}


/* eslint-disable eqeqeq */
/* eslint-disable no-eq-null */
import { MenuProps } from "antd";
import { Dayjs } from "dayjs";
import { PermissionArray, PropertyPermissionArray } from "./constants/common/permissions";
import { useUserStore } from "./store/userStore";
import { User } from "../types/user";
import { Property } from "../types/property";
import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import { faker } from "@faker-js/faker";
import { Translation } from "../types/translation";

export const Some = <T>(value: T | null | undefined): value is T =>
  value != null;

export const None = <T>(
  value: T | null | undefined
): value is null | undefined => value == null;

export const isEmpty = (obj: {}) => !Object.keys(obj).length;

export declare type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U> ? Array<DeepPartial<U>> : T[P] extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : DeepPartial<T[P]> | T[P];
};

export type MenuItem = Required<MenuProps>["items"][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  onClick?: () => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
  };
}

export function enumToOptionsList(
  enumObject: any
): { label: string; value: string }[] {
  const enumKeys = Object.keys(enumObject).filter((key) => isNaN(Number(key)));
  const enumList = enumKeys.map((key) => ({
    label: key,
    value: enumObject[key],
  }));
  return enumList;
}

const hasCommonElement = (arr1: any[], arr2: any[]) =>
  arr1.some((item) => arr2.includes(item));

export function hasOrgPermission(
  permissions: PermissionArray,
  orgId: string | null = null
) {
  const user = useUserStore.getState().user;

  if (!user || !user.orgPermissions) {
    return false;
  }

  if (orgId) {
    return user.orgPermissions.some(
      (orgPermission) =>
        orgPermission.organization.id === orgId &&
        hasCommonElement(orgPermission.permissions, permissions)
    );
  } else {
    return hasCommonElement(
      user.orgPermissions.reduce((arr: string[], orgPermission) => arr.concat(orgPermission.permissions), []),
      permissions
    );
  }
}

export function hasPropertyPermissions(
  permissions: PropertyPermissionArray,
  propertyId: string | null = null
) {
  const user = useUserStore.getState().user;

  if (!user || !user.userPermissionGroups) {
    return false;
  }

  if (propertyId) {
    return user.userPermissionGroups.some(
      (userPermissionGroup) =>
        userPermissionGroup.property.id === propertyId &&
        hasCommonElement(userPermissionGroup.permissions, permissions)
    );
  } else {
    return hasCommonElement(
      user.userPermissionGroups.reduce((arr: string[], userPermissionGroup) => arr.concat(userPermissionGroup.permissions), []),
      permissions
    );
  }
}

export function getEmailFromSearchParams(searchParams: URLSearchParams): string | null {
  return searchParams.get("email")?.replace(/ /g, "+").replace(/%40/g, "@") || null;
}
export function partialSearch<T = any>(array: T[], searchTerm: string) {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return array.filter((item: any) => {
    for (const key in item) {
      if (typeof item[key] === 'string' && item[key].toLowerCase().includes(lowerCaseSearchTerm)) {
        return true;
      }
    }
    return false;
  });
}

type DebouncedFunction<T, Args extends any[]> = (this: T, ...args: Args) => void;

export function debounce<T, Args extends any[]>(
  func: DebouncedFunction<T, Args>,
  delay: number
): DebouncedFunction<T, Args> {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: T, ...args: Args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(' ');
}

export function getBackendLink(url: string) {
  return `${process.env.REACT_APP_API_BASE_URL}${url}`;
}

export function getTotalStayNights(checkInDate: Dayjs, checkOutDate: Dayjs) {
  return checkOutDate.diff(checkInDate, "day");
}

export function getUniqueProperties(user?: DeepPartial<User>): Property[] {
  if (user && user.userPermissionGroups) {
    const initialAcc: Record<string, Property> = {};
    const uniquePropertiesObject = user.userPermissionGroups.reduce((acc, userPermissionGroup) => {
      if (!userPermissionGroup.property?.id) return acc;
      else {
        acc[userPermissionGroup.property.id] = userPermissionGroup.property as Property;
        return acc;
      }
    }, initialAcc);
    return Object.values(uniquePropertiesObject);
  } else {
    return [];
  }
}

export function getEditedFieldValues<T extends FieldValues>(
  data: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  let changedData: Partial<T> = {};
  Object.keys(dirtyFields).forEach(fieldName => {
    const key = fieldName as keyof T;
    if (data[key] !== undefined) {
      changedData = {
        ...changedData,
        [key]: data[key],
      }
    }
  });

  return changedData;
}

export function sanitizeNumberField(value: string | number | any) {
  return typeof value === "string" && !isNaN(parseInt(value)) ? parseInt(value) :
    isNaN(value) ? 0 :
      value;
}

export const toValueLabelList = (obj: Record<string, string>) => {
  return Object.entries(obj).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

export const slugify = (str: string) =>
  faker.helpers.slugify(str).toLowerCase();

export const getNestedValue = (obj: any, path: string): any => {
  // get value from obj using dot-notation string path
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const getTranslatedName = (obj: { name: string, nameTranslations: Translation[] }, userLocale: string): string =>
  obj.nameTranslations.length ? obj.nameTranslations.find(t => t.language === userLocale)?.translation ?? obj.name : obj.name;

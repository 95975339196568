import React from "react";
import dayjs from "dayjs";
import { useGlobalStore } from "../../store/globalStore";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(RelativeTime);

export type HumanizedDateProps = {
  date: string;
};
export const HumanizedDate: React.FC<HumanizedDateProps> = ({ date }) => {
  const isHumanizedDate = useGlobalStore((state) => state.isHumanizedDate);
  const toggleHumanizedDate = useGlobalStore(
    (state) => state.toggleHumanizedDate
  );

  const relativeTime = dayjs(date).fromNow();
  const absoluteTime = dayjs(date).format("MMM DD, YYYY h:mm A");

  return (
    <>
      <span
        onClick={() => toggleHumanizedDate()}
        style={{ cursor: "pointer" }}
        title={isHumanizedDate ? absoluteTime : relativeTime}
      >
        {isHumanizedDate ? relativeTime : absoluteTime}
      </span>
    </>
  );
};

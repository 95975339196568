import { Checkbox, Col, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { TeamOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Fragment, useState } from "react";

import { ReservationDetailDTO } from "../../../../types/reservation.dto"
import { RoomAllocationDTO, SingleReservationRoomAllocationDTO } from "../../../../types/roomAllocation.dto";

dayjs.extend(isBetween);

const { Text } = Typography;

type CalendarProps = {
  reservation: ReservationDetailDTO;
  roomAllocationData: SingleReservationRoomAllocationDTO;
}

export function RoomAllocationCalendar({ reservation, roomAllocationData }: CalendarProps) {
  const [showOtherRooms, setShowOtherRooms] = useState(false);

  const handleShowRoomTypes = (e: CheckboxChangeEvent) => {
    setShowOtherRooms(e.target.checked);
  }

  const {
    roomTypeDetails,
    roomDetails,
    checkOutDate,
    checkInDate,
  } = reservation;

  const startDate = dayjs(checkInDate).add(-3, "days");
  const availableRooms = [
    ...roomAllocationData.availableRoomsForRoomType,
  ]

  if (roomDetails) {
    const { id, name, label } = roomDetails;
    availableRooms.unshift({
      name,
      id,
      label,
      reservations: [],
      roomTypeId: roomTypeDetails.id,
      roomTypeDetails: roomTypeDetails,
      colour: roomTypeDetails.colour,
    });
  }


  const generateHeaders = () => {
    const headers = [];
    const columnSize = 3;
    for (let i = 0; i < 7; i++) {
      const currentDate = startDate.add(i, "day");
      const backgroundColor = currentDate.isBetween(checkInDate, checkOutDate, "day", "[)") ? "#ffebd0" : "none"
      headers.push(
        <Col span={columnSize} key={i} style={{ textAlign: "center", backgroundColor, }}>
          <Text>{currentDate.format("D")}</Text><br />
          <Text>{currentDate.format("ddd")}</Text>
        </Col>
      )
    }
    
    return (
      <Row 
        gutter={16}
        style={{ marginBottom: 18 }}
      >
        <Col span={columnSize}>
          <Checkbox onChange={handleShowRoomTypes}>Show all room types</Checkbox>
        </Col>
        {headers}
      </Row>
    )
  }

  const generateRoomAllocationCells = (data: RoomAllocationDTO[]) => {
    const columnSize = 3;
    const rows = [];

    for (const room of data) {
      const columnData = [];
      const lastDateColumn = startDate.add(6, "day");

      for (let i = 0; i < 7; i++) {
        const currentDate = startDate.add(i, "day");
        const event = room.reservations.find(r => currentDate.isSame(r.checkInDate, "day"));
        if (event) {
          const { checkInDate, checkOutDate } = event;
          const nights = dayjs(checkOutDate).diff(checkInDate, "day")

          const checkOutDateIsOutOfBounds = dayjs(checkOutDate).isAfter(lastDateColumn, "day");
          const outOfBoundsDiff = checkOutDateIsOutOfBounds ? dayjs(checkOutDate).diff(lastDateColumn, "day") -1 : 0;

          const length = `${100 * (nights - outOfBoundsDiff)}%`
          columnData.push(
            <Col key={i} span={columnSize}>
              <div 
                className="room-allocation-event-item" 
                style={{ 
                  background: room.colour,
                  position: "absolute", 
                  width: length,
                  padding: "0 5px",
                  color: "white",
                  left: 0,
                }}
              >
                <Row style={{fontSize: "12px"}}>
                  <Col span={12}>
                    {event.guestName}
                  </Col>

                  <Col span={12} style={{ textAlign: "right" }}>
                    <TeamOutlined /> {event.numberOfGuests}
                  </Col>

                </Row>

              </div>
            </Col>
          )
        } else {
          columnData.push(
            <Col key={i} flex="auto" span={columnSize}></Col>
          )
        }
      }

      rows.push(
        <Row 
          key={room.id}
          gutter={16}
          style={{ marginBottom: 18, overflow: "hidden" }}
        >
          <Col span={columnSize}>
            {room.roomTypeDetails.name}<br />
            {room.name}
          </Col>
          {columnData}
        </Row>
      )
    }

    return rows;
  }

  return (
    <Fragment>
      {generateHeaders()}
      {generateRoomAllocationCells(roomAllocationData.roomsForRoomTypeAndDates)}
      <div style={{
        display: showOtherRooms ? "inherit" : "none"
      }}>
        {generateRoomAllocationCells(roomAllocationData.roomsForPropertyAndDates)}
      </div>
    </Fragment>
  )
}

interface ContentLayoutProps {
  header?: any;
  sideColumn?: any;
  children: any;
}

export const ContentLayout: React.FC<ContentLayoutProps> = ({
  header,
  sideColumn,
  children,
}) => {
  return (
    <div className="flex min-h-full flex-col">
      {header && (
      <header className="shrink-0 border-b border-gray-200 bg-white">
        {header}
      </header>
      )}
    <div className="mx-auto flex w-full max-w-7xl items-start gap-x-8 px-2">
      {sideColumn && (
        <aside className="sticky top-8 max-w-xs shrink-0 block">
          {sideColumn}
        </aside>
      )}

      <main className="flex-1 overflow-x-auto">{children}</main>
    </div>
    </div>
  );
};

export const ContentLayoutWithFullWidth: React.FC<ContentLayoutProps> = ({
  header,
  sideColumn,
  children,
}) => {
  return (
    <div className="flex min-h-full flex-col">
      {header && (
      <header className="shrink-0 border-b border-gray-200 bg-white">
        {header}
      </header>
      )}
    <div className="mx-auto fixed flex w-full h-full items-start gap-x-8 overflow-y-auto">
      {sideColumn && (
        <aside className="fixed max-w-xs h-full shrink-0 block bg-neutral-200">
          {sideColumn}
        </aside>
      )}

      <main className="absolute left-52 overflow-auto">{children}</main>
    </div>
    </div>
  );
};

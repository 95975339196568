type CardProps = {
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Card: React.FC<CardProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={`bg-white border border-gray-200 rounded-md shadow-md ${className}`}
      {...props}
    >
      <div className="p-4">{children}</div>
    </div>
  );
};

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ReservationStatusDTO } from "../../types/reservation.dto";

export type GlobalState = {
  readonly err: string;
  readonly loading: boolean;
  readonly isHumanizedDate: boolean;
  readonly currentProperty: string;
  readonly reservationStatuses: ReservationStatusDTO[];
  setError: (message: string) => void;
  setLoading: (loading: boolean) => void;
  toggleHumanizedDate: () => void;
  setCurrentProperty: (propertyId: string) => void;
  setReservationStatuses: (reservationStatuses: ReservationStatusDTO[]) => void;
} 

export const useGlobalStore = create(
  persist(
    (set) => ({
    err: "",
    loading: false,
    isHumanizedDate: true,
    currentProperty: "",
    reservationStatuses: [],
    setError: (errorMessage: string) => set({ err: errorMessage }),
    setLoading: (loading: boolean) => set({ loading }),
    toggleHumanizedDate: () => set((state: GlobalState) => ({ isHumanizedDate: !state.isHumanizedDate })),
    setCurrentProperty: (propertyId: string) => set({ currentProperty: propertyId }),
    setReservationStatuses: (reservationStatuses: ReservationStatusDTO[]) => set({ reservationStatuses }),
  }),
  {
    name: "global-storage",
    partialize: (state: GlobalState) => ({ isHumanizedDate: state.isHumanizedDate, currentProperty: state.currentProperty })
  })
);


import { Card, Col, Row, Space, Typography } from "antd";
import { PropertyMemo } from "../../../../types/propertyMemo";
import { HumanizedDate } from "../../../components/elements";
import { UserOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";

const { Paragraph } = Typography;

interface PropertyDashboardMemoProps {
  memos?: PropertyMemo[];
}

function Memo({ memo }: { memo: PropertyMemo }) {
  return (
    <div key={memo.id}>
      <Paragraph>
        <Space>
          <UserOutlined />
          {memo.legacyCreatedByName}
          <HumanizedDate date={memo.createdAt} />
        </Space>
      </Paragraph>
      <Paragraph>
        <blockquote>
          <pre>{memo.content}</pre>
          <Row justify="space-between" align="middle">
            <Col>
              {memo.updatedAt && (
                <Trans>
                  Edited <HumanizedDate date={memo.updatedAt} />
                </Trans>
              )}
            </Col>
            <Col><Trans>Edit</Trans>・<Trans>Delete</Trans></Col>
          </Row>
        </blockquote>
      </Paragraph>
      <Paragraph>
        <Trans>
          Applies from <HumanizedDate date={memo.startDate} /> -{" "}
          {memo.endDate && <HumanizedDate date={memo.endDate} />}
        </Trans>
      </Paragraph>
    </div>
  );
}

export function PropertyDashboardMemo({ memos }: PropertyDashboardMemoProps) {
  const filteredMemos = memos?.filter((memo) => {
    const today = new Date();
    const startDate = new Date(memo.startDate);
    const endDate = new Date(memo.endDate);
    if (memo.endDate === null) {
      return today >= startDate;
    }
    return today >= startDate && today <= endDate;
  });

  return (
    <Card bordered={false}>
      {filteredMemos && filteredMemos.length ? (
        filteredMemos.map((memo) => <Memo key={memo.id} memo={memo} />)
      ) : (
        <Trans>
          <p>No Memo</p>
        </Trans>
      )}
    </Card>
  );
}

import { ReactNode } from "react";
import { create } from "zustand";

export type SlideOverState = {
  slideOver?: ReactNode;
  readonly setSlideOver: (slideOver?: ReactNode) => void;
};

export const useSlideOverStore = create<SlideOverState>((set) => ({
  slideOver: null,
  setSlideOver: (slideOver?: ReactNode) => set(({ slideOver })),
}));


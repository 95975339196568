import { create } from "zustand";
import { TaxDTO, TaxListDTO } from "../../types/tax.dto";

export type TaxState = {
  readonly tax?: TaxDTO;
  readonly taxes: TaxListDTO;
  readonly setTax: (tax?: TaxDTO) => void;
  readonly setTaxes: (taxes: TaxListDTO) => void;
}

export const useTaxStore = create<TaxState>((set) => ({
  tax: undefined,
  taxes: [],
  setTax: (tax?: TaxDTO) => set({ tax }),
  setTaxes: (taxes: TaxListDTO) => set({ taxes }),
}));

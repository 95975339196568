import { create } from "zustand";
import { Department } from "../../types/department";
import { PaginationParam } from "../app.d";
import { defaultPaginationValues } from "./utils";

export type DepartmentState = {
  readonly departments: Department[];
  readonly department?: Department;
  readonly pagination: PaginationParam;
  readonly setDepartments: (departments: Department[]) => void;
  readonly setDepartment: (department: Department | undefined) => void;
  readonly setPagination: (pagination: PaginationParam) => void;
}

export const useDepartmentStore = create<DepartmentState>((set) => ({
  department: undefined,
  departments: [],
  pagination: defaultPaginationValues,
  setDepartments: (departments: Department[]) => set({ departments }),
  setDepartment: (department: Department | undefined) => set({ department }),
  setPagination: (pagination: PaginationParam) => set({ pagination }),
}));


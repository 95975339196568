import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { createFacilityClient } from "../api/createFacilityClient";
import { useFacilityStore } from "../store/facilityStore";
import { GenericGetQueryParam } from "../api/createApiClient";
import { CreateFacilityDTO, UpdateFacilityDTO } from "../../types/facility.dto";

export function useFacilityService(propertyId: string) {
  const client = createFacilityClient(propertyId);

  const setFacilities = useFacilityStore(state => state.setFacilities);
  const setFacility = useFacilityStore(state => state.setFacility);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);

  const getFacility = useCallback(
    async (
      id: number | string | any,
    ) => {
      try {
        setLoading(true);
        const res = await client.getFacility(id);
        setFacility(res.facility);

      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  const getFacilities = useCallback(
    async (data?: GenericGetQueryParam) => {
      try {
        setLoading(true);
        const res = await client.getFacilities(data);
        setFacilities(res.facilities);

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false)
    },
    [setError, setLoading, setFacilities, client],
  );

  const createFacility = useCallback(
    async (data: CreateFacilityDTO) => {
      try {
        setLoading(true);
        await client.createFacility(data);
        await getFacilities();

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false);
    },
    [],
  );

  const updateFacility = useCallback(
    async (
      data: UpdateFacilityDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateFacility(data);
        await getFacilities();
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [setError, setLoading, setFacility, client],
  );

  const deleteFacility = useCallback(
    async(
      id: number | string | any,
    ) => {
      try {
        setLoading(true);
        await client.deleteFacility(id);
        await getFacilities();
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  return {
    createFacility,
    updateFacility,
    getFacility,
    getFacilities,
    deleteFacility,
  };
}

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Controller, useFormContext } from "react-hook-form";
import { classNames } from "../../utils";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const baseClassNames =
  "block rounded-md border-0 py-1.5 focus:ring-2 focus:ring-inset text-sm leading-6";
const defaultColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600";
const disabledColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200";
const errorColorClassNames =
  "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500";

type CountryDropdownProps = {
  name: string;
  className?: string;
  label?: string;
};
type ProvinceDropdownProps = {
  name: string;
  countryName: string;
  className?: string;
  label?: string;
};
export function StackedCountryDropdown({ name, className, label }: CountryDropdownProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const error = errors[name]?.message;
  let activeClassNames = classNames(baseClassNames, "w-full");
  if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <CountryDropdown
              {...field}
              classes={activeClassNames}
              priorityOptions={["JP"]}
            />
          )}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}

export function StackedProvinceDropdown({ name, countryName, className, label }: ProvinceDropdownProps) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const selectedCountry = watch(countryName);
  const error = errors[name]?.message;
  let activeClassNames = classNames(baseClassNames, "w-full");
  if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else if (!Boolean(selectedCountry)) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RegionDropdown
              {...field}
              country={selectedCountry}
              classes={activeClassNames}
              disableWhenEmpty
            />
          )}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}

import { t } from "@lingui/macro";
import {
  CreatePropertyPermissionGroupReqBody,
  CreateUserRequestBody,
  createOrgSettingsClient,
} from "../../../api/createOrgSettingsClient";
import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import { useNotificationStore } from "../../../store/notificationStore";
import { OrgState, useOrgStore } from "../../../store/orgStore";
import { useCallback } from "react";

export function useOrgSettingsService(organizationId: string) {
  const client = createOrgSettingsClient(organizationId);

  const setErrorMessage = useGlobalStore(
    (state: GlobalState) => state.setError
  );
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const setOrgInFocus = useOrgStore((state: OrgState) => state.setOrgInFocus);
  const setPropertyPermissionGroups = useOrgStore(
    (state: OrgState) => state.setPropertyPermissionGroups
  );
  const setUsers = useOrgStore((state: OrgState) => state.setUsers);

  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );

  const getOrg = async () => {
    try {
      setLoading(true);
      const res = await client.getOrg();
      setOrgInFocus(res.org);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setErrorMessage(String(e));
    }
  };

  const createPropertyPermissionGroup = async (
    form: CreatePropertyPermissionGroupReqBody
  ) => {
    try {
      await client.createPropertyPermissionGroup(form);
      await getPropertyPermissionGroups();
      addNotification({
        type: "success",
        title: t`Success!`,
        message: t`User group created`,
      });
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const editPropertyPermissionGroup = async (
    propertyPermissionGroupId: string,
    form: CreatePropertyPermissionGroupReqBody
  ) => {
    try {
      await client.editPropertyPermissionGroup(propertyPermissionGroupId, form);
      await getPropertyPermissionGroups();
      addNotification({
        type: "success",
        title: t`Success!`,
        message: t`User group updated`,
      });
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const deletePropertyPermissionGroup = async (
    propertyPermissionGroupId: string
  ) => {
    try {
      await client.deletePropertyPermissionGroup(propertyPermissionGroupId);
      await getPropertyPermissionGroups();
      addNotification({
        type: "success",
        title: t`Success!`,
        message: t`User group deleted`,
      });
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const getPropertyPermissionGroups = useCallback(async () => {
    try {
      setLoading(true);
      const res = await client.getPropertyPermissionGroups();
      setPropertyPermissionGroups(res.groups);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setErrorMessage(String(e));
    }
  }, [client, setErrorMessage, setLoading, setPropertyPermissionGroups]);

  const createUser = async (form: CreateUserRequestBody) => {
    try {
      await client.createUser(form);
      await getUsers();
      addNotification({
        type: "success",
        title: t`Success!`,
        message: t`User created`,
      });
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await client.getUsers();
      setUsers(res.users);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setErrorMessage(String(e));
    }
  };

  const editUser = async (userId: string, form: CreateUserRequestBody) => {
    try {
      await client.editUser(userId, form);
      // delay to allow for the user to be updated in the db
      // TODO: remove this when we have a better solution
      setTimeout(() => {
        getUsers();
        addNotification({
          type: "success",
          title: t`Success!`,
          message: t`User updated`,
        });
      }, 200);
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await client.deleteUser(userId);
      await getUsers();
    } catch (e: unknown) {
      setErrorMessage(String(e));
    }
  };

  const getUsersPageData = async () => {
    try {
      setLoading(true);
      const [orgRes, propertyPermissionGroupsRes, usersRes] = await Promise.all(
        [
          client.getOrg(),
          client.getPropertyPermissionGroups(),
          client.getUsers(),
        ]
      );
      setOrgInFocus(orgRes.org);
      setPropertyPermissionGroups(propertyPermissionGroupsRes.groups);
      setUsers(usersRes.users);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setErrorMessage(String(e));
    }
  };

  return {
    getOrg,
    createPropertyPermissionGroup,
    getPropertyPermissionGroups,
    editPropertyPermissionGroup,
    deletePropertyPermissionGroup,
    createUser,
    getUsers,
    editUser,
    deleteUser,
    getUsersPageData,
  };
}

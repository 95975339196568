import { create } from "zustand";
import { FacilityDTO, FacilityListDTO } from "../../types/facility.dto";

export type FacilityState = {
  readonly facilities: FacilityListDTO
  readonly facility?: FacilityDTO;
  readonly setFacilities : (facilities: FacilityListDTO) => void;
  readonly setFacility: (facility: FacilityDTO) => void;
}

export const useFacilityStore = create<FacilityState>((set) => ({
  facility: undefined,
  facilities: [],
  setFacilities: (facilities: FacilityListDTO) => set({ facilities }),
  setFacility: (facility: FacilityDTO) => set({ facility }),
}));


import React, { useEffect } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { PropertyState, usePropertyStore } from '../../store/propertyStore';
import { useMockingbirdPropertyService as usePropertyService } from '../../services/useMockingbirdPropertyService';
import { Button } from '../../components/elements';
import { Loading } from '../../components/elements/Loading';

export const PropertyListView: React.FC = () => {
  const properties = usePropertyStore((state: PropertyState) => state.properties);
  const initialCallMade = usePropertyStore((state: PropertyState) => state.initialCallMade);
  const setInitialCall = usePropertyStore((state: PropertyState) => state.setInitialCall);

  const { getProperties } = usePropertyService()

  useEffect(() => {
    if (properties.length === 0 && !initialCallMade) {
      getProperties();
      setInitialCall(true);
    }
  }, [ properties, getProperties, initialCallMade, setInitialCall ]);

  return properties.length ? (
      <Row gutter={18} style={{ marginTop: 50 }}>
        {
          properties.map(property => (
            <Col span={8} key={property.id} style={{ marginBottom: 20 }}>
              <Card title={property.name} bordered={false}>
                <Link to={`/mockingbird/mock/properties/${property.id}/create-mock-reservation`} state={{ fromListView: true }}>
                  <Button>
                    Create Mock Reservation
                  </Button>
                </Link>
                <Link to={`/mockingbird/mock/properties/${property.id}/reservations`} state={{ fromListView: true }}>
                  <Button>
                    View Reservations
                  </Button>
                </Link>
              </Card>
            </Col>
          ))
        }
      </Row>
  ) : (<Loading />)
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Table,
  Modal,
  Form,
  Input,
  Select,
  Card,
  Divider,
  Row,
  Col,
} from "antd";
import { Controller, set, useFieldArray, useForm } from "react-hook-form";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useOrgSettingsService } from "../services/useOrgSettingsService";
import { OrgState, useOrgStore } from "../../../store/orgStore";
import { PropertyGroups, UserOrgDTO } from "../../../../types/user.dto";
import { PropertyPermissionGroup } from "../../../../types/propertyPermissionGroup";
import { CloseOutlined } from "@ant-design/icons";
import { CreateUserRequestBody } from "../../../api/createOrgSettingsClient";
import { UserState, useUserStore } from "../../../store/userStore";
import { useAuthService } from "../../../services/useAuthService";
import { Button } from "../../../components/elements";
import { Trans, t } from "@lingui/macro";

const UsersPage: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const [emailSentToUsers, setEmailSentToUsers] = useState<string[]>([]);
  const [selectedPropertyGroups, setSelectedPropertyGroups] = useState<
    Set<string>
  >(new Set());

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    register,
    getValues,
    watch,
    formState,
  } = useForm();

  const {
    fields: propertyGroupFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "propertyGroups",
  });

  const user = useUserStore((state: UserState) => state.user);
  const { refreshUser, sendInviteEmail } = useAuthService();
  const users = useOrgStore((state: OrgState) => state.users);
  const orgInFocus = useOrgStore((state: OrgState) => state.orgInFocus);
  const propertyPermissionGroups = useOrgStore(
    (state: OrgState) => state.propertyPermissionGroups
  );
  const { editUser, createUser, getUsersPageData } =
    useOrgSettingsService(organizationId);

  useEffect(() => {
    getUsersPageData();
  }, [organizationId]);

  const getPropertyName = useCallback(
    (propertyId: string) => {
      const property = orgInFocus?.properties?.find(
        (property) => property.id === propertyId
      );
      return property?.name;
    },
    [orgInFocus]
  );

  const handleAddPropertyGroup = useCallback(() => {
    const values = getValues();
    const propertyGroups = values.propertyGroups || [];
    const newSelectedPropertyGroups = new Set(
      Array.from(selectedPropertyGroups)
    );
    propertyGroups.filter(Boolean).forEach((group: any) => {
      newSelectedPropertyGroups.add(group.property);
    });
    setSelectedPropertyGroups(newSelectedPropertyGroups);
  }, [getValues, selectedPropertyGroups]);

  const handleEdit = useCallback(
    (userId: number) => {
      const userToEdit = users.find((user) => user.id === userId);
      const propertyGroups = Object.values(
        userToEdit?.propertyGroups || {}
      ).map((item) => ({
        property: item.property,
        groups: item.groups.map((group) => group.id),
      }));

      if (userToEdit) {
        setValue("firstName", userToEdit.firstName);
        setValue("lastName", userToEdit.lastName);
        setValue("locale", userToEdit.locale);
        setValue("email", userToEdit.email);
        setValue("propertyGroups", propertyGroups);

        setEditingUserId(userId);
        setIsModalOpen(true);
      }
    },
    [setValue, users]
  );

  const handleSave = useCallback(
    handleSubmit(async (values) => {
      const requestBody = values as CreateUserRequestBody;
      if (editingUserId) {
        await editUser(String(editingUserId), requestBody);
        if (editingUserId === user?.id) {
          await refreshUser();
        }
      } else {
        await createUser(requestBody);
        await sendInviteEmail(requestBody.email);
      }

      reset();
      setEditingUserId(null);
      setIsModalOpen(false);
    }),
    [handleSubmit, editingUserId, editUser, createUser, sendInviteEmail, reset]
  );

  const handleSendVerificationEmail = useCallback(
    async (email: string) => {
      const oldEmailSentToUsers = [...emailSentToUsers];
      try {
        setEmailSentToUsers([...emailSentToUsers, email]);
        await sendInviteEmail(email);
      } catch (e) {
        setEmailSentToUsers(oldEmailSentToUsers);
      }
    },
    [sendInviteEmail, emailSentToUsers]
  );

  const columns = useMemo(
    () => [
      {
        title: t`Full Name`,
        dataIndex: "fullName",
        key: "fullName",
      },
      {
        title: t`Email`,
        dataIndex: "email",
        key: "email",
      },
      {
        title: t`Verified`,
        dataIndex: "verified",
        key: "verified",
        render: (verified: boolean) => (
          <div className="flex justify-center">
            {verified ? (
              <CheckCircleIcon className="h-6 w-6 text-green-800 self" />
            ) : (
              <CheckCircleIcon className="h-6 w-6 text-gray-500" />
            )}
          </div>
        ),
      },
      {
        title: t`Property Groups`,
        dataIndex: "propertyGroups",
        key: "propertyGroups",
        render: (propertyGroups: PropertyGroups) => (
          <>
            {Object.keys(propertyGroups).map((key, index) => {
              const propertyGroup = propertyGroups[key];
              return (
                <div key={index}>
                  <div>
                    <strong>{getPropertyName(propertyGroup.property)}: </strong>
                    {propertyGroup.groups.map((item) => item.name).join(", ")}
                  </div>
                </div>
              );
            })}
          </>
        ),
      },
      {
        title: t`Actions`,
        key: "actions",
        render: (_: any, user: UserOrgDTO) => (
          <>
            <Button type="link" onClick={() => handleEdit(user.id)}>
              <Trans>Edit</Trans>
            </Button>
            {!user?.verified && (
              <Button
                type="link"
                onClick={() => handleSendVerificationEmail(user.email)}
                disabled={emailSentToUsers.includes(user.email)}
              >
                <Trans>Resend Verification Email</Trans>
              </Button>
            )}
          </>
        ),
      },
    ],
    [handleEdit, emailSentToUsers]
  );

  return (
    <div>
      <Table columns={columns} dataSource={users} rowKey="id" />
      <Button
        type="primary"
        onClick={() => {
          reset({
            firstName: "",
            lastName: "",
            email: "",
            locale: "en",
            propertyGroups: [{ property: "", group: "" }],
          });
          setIsModalOpen(true);
        }}
      >
        <Trans>Add User</Trans>
      </Button>

      <Modal
        title={editingUserId ? t`Edit User` : t`Add User`}
        open={isModalOpen}
        onCancel={() => {
          reset();
          setEditingUserId(null);
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              reset();
              setEditingUserId(null);
              setIsModalOpen(false);
            }}
          >
            <Trans>Cancel</Trans>
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            <Trans>OK</Trans>
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Divider orientation="left" plain>
            <Trans>Basic Information</Trans>
          </Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: t`Please enter the first name` }}
                render={({ field, fieldState }) => (
                  <Form.Item label={t`First Name`}>
                    <Input {...field} />
                    {fieldState.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </Form.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: t`Please enter the last name` }}
                render={({ field, fieldState }) => (
                  <Form.Item label={t`Last Name`}>
                    <Input {...field} />
                    {fieldState.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t`Please enter an email`,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t`Please enter a valid email`,
                  },
                }}
                render={({ field, fieldState }) => (
                  <Form.Item label={t`Email`}>
                    <Input {...field} />
                    {fieldState.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </Form.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <Controller
                name="locale"
                control={control}
                rules={{ required: t`Please select a locale` }}
                render={({ field, fieldState }) => (
                  <Form.Item label={t`Locale`}>
                    <Select {...field}>
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="ja">日本語</Select.Option>
                    </Select>
                    {fieldState.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <Divider orientation="left" plain>
            <Trans>Property Groups</Trans>
          </Divider>
          {propertyGroupFields?.map((item, index) => (
            <Card
              key={item.id}
              style={{ marginBottom: "16px" }}
              size="small"
              extra={index && <CloseOutlined onClick={() => remove(index)} />}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Controller
                    name={`propertyGroups.${index}.property`}
                    control={control}
                    rules={{
                      required: t`Please select a property`,
                    }}
                    render={({ field, fieldState }) => (
                      <Form.Item label={t`Property`}>
                        <Select {...field}>
                          {orgInFocus?.properties
                            ?.filter(
                              (property) =>
                                !selectedPropertyGroups.has(property.id)
                            )
                            .map((property) => (
                              <Select.Option
                                key={property.id}
                                value={property.id}
                              >
                                {property.name}
                              </Select.Option>
                            ))}
                        </Select>
                        {fieldState.error && (
                          <span>{fieldState.error.message}</span>
                        )}
                      </Form.Item>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name={`propertyGroups.${index}.groups`}
                    control={control}
                    rules={{
                      required: t`Please select at least one group`,
                    }}
                    render={({ field, fieldState }) => (
                      <Form.Item label={t`Groups`}>
                        <Select {...field} mode="multiple">
                          {propertyPermissionGroups?.map(
                            (
                              propertyPermissionGroup: PropertyPermissionGroup
                            ) => (
                              <Select.Option
                                key={propertyPermissionGroup.id}
                                value={propertyPermissionGroup.id}
                              >
                                {propertyPermissionGroup.name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                        {fieldState.error && (
                          <span>{fieldState.error.message}</span>
                        )}
                      </Form.Item>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          ))}
          <Form.Item>
            <Button
              onClick={() => {
                append({ property: "", groups: [] });
                handleAddPropertyGroup();
              }}
            >
              <Trans>Add Property Group</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UsersPage;

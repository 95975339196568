import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { createDepartmentClient } from "../api/createDepartmentClient";
import { useDepartmentStore } from "../store/departmentStore";
import { GenericGetQueryParam } from "../api/createApiClient";
import { CreateDepartmentDTO, UpdateDepartmentDTO } from "../../types/department.dto";

export function useDepartmentService(propertyId: string) {
  const client = createDepartmentClient(propertyId);

  const setDepartments = useDepartmentStore(state => state.setDepartments);
  const setDepartment = useDepartmentStore(state => state.setDepartment);
  const pagination = useDepartmentStore(state => state.pagination);
  const setPagination = useDepartmentStore(state => state.setPagination);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);

  const paginationQueryParams: GenericGetQueryParam = {
    limit: pagination.pageSize,
    page: pagination.currentPage,
  };

  const getDepartment = useCallback(
    async (
      departmentId: number | string | any,
    ) => {
      try {
        setLoading(true);
        const res = await client.getDepartment(departmentId);
        setDepartment(res.department);

      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  const searchDepartment = useCallback(
    async (
      term: string,
    ) => {
      try {
        setLoading(true);
        const res = await client.searchDepartment(term);
        setDepartments(res.departments);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  )

  const getDepartments = useCallback(
    async (data?: GenericGetQueryParam) => {
      try {
        setLoading(true);
        const res = await client.getDepartments(data);
        setDepartments(res.departments);
        setPagination({
          ...res.pagination,
          pageSize: data?.limit ?? 30,
        });

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false)
    },
    [setError, setLoading, setDepartments, client],
  );

  const createDepartment = useCallback(
    async (data: CreateDepartmentDTO) => {
      try {
        setLoading(true);
        await client.createDepartment(data);
        await getDepartments(paginationQueryParams);

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false);
    },
    [],
  );

  const updateDepartment = useCallback(
    async (
      data: UpdateDepartmentDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateDepartment(data);
        await getDepartments(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [setError, setLoading, setDepartment, client],
  );

  const deleteDepartment = useCallback(
    async(
      id: number | string | any,
    ) => {
      try {
        setLoading(true);
        await client.deleteDepartment(id);
        await getDepartments(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  return {
    createDepartment,
    updateDepartment,
    getDepartment,
    getDepartments,
    deleteDepartment,
    searchDepartment,
  };
}


import { ChangeEvent } from 'react';

export type RadioOption = {
  value: string | number | any;
  label: string;
  description?: string;
}

type RadioGroupProps = {
  options: RadioOption[];
  selectedOption?: string | number | any;
  label: string;
  onChange: (selectedOption: string | number | any) => void;
}

export function RadioGroup({ label, options, selectedOption, onChange }: RadioGroupProps) {
  return (
    <fieldset className="grid grid-cols-3 items-start gap-4 space-y-0 px-6 py-5">
      <legend className="sr-only">{label}</legend>
      <div className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
        {label}
      </div>
      <div className="space-y-5 col-span-2">
        <div className="space-y-5 mt-0">
          {options.map(option => {
            return (
              <div key={option.value} className="relative flex items-start">
                <div className="absolute flex h-6 items-center">
                  <input
                    id={option.value}
                    name={label}
                    aria-describedby="public-access-description"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-600"
                    defaultChecked={selectedOption === option.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.id)
                    }}
                  />
                </div>
                <div className="pl-7 text-sm leading-6">
                  <label htmlFor="public-access" className="font-medium text-gray-900">
                    {option.label}
                  </label>
                  {
                    option.description ?? 
                      <p id="public-access-description" className="text-gray-500">
                        {option.description}
                      </p>
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </fieldset>
  )
}


import { useCallback } from "react";
import { MockTemairazuInsertDTO } from "../../types/mockTemairazuInsertDTO";
import { createMockingbirdClient, ReservationsQueryParam } from "../api/createMockingbirdClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { MockTemairazuReservationState, useMockTemairazuReservationStore } from "../store/mockTemairazuReservationStore";
import { DeepPartial } from "../utils";

export function useMockTemairazuReservationService () {
  const client = createMockingbirdClient();

  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const setXmlResponse = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setXmlResponse);
  const setReservations = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setReservations);
  const setReservationInFocus = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setReservationInFocus);
  const reservations = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.reservations);

  const createReservation = useCallback(
    async (
      propertyId: string,
      data: DeepPartial<MockTemairazuInsertDTO>,
    ) => {
      try {
        const res = await client.createMockTemairazuReservation(propertyId, data);
        setXmlResponse(res);
      } catch (e: unknown) {
        setError(String(e));
      }
    },
    [setError, setXmlResponse, client],
  );

  const getReservations = useCallback(
    async (
      propertyId: string,
      data: ReservationsQueryParam,
    ) => {
      try {
        setLoading(true);

        const res = await client.getMockTemairazuReservations(propertyId, data);
        setReservations(res.reservations);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [setError, setReservations, setLoading, client],
  );

  const getReservation = useCallback(
    async (
      reservationId: number,
      propertyId: string,
    ) => {
      try {
        setLoading(true);
        const reservation = reservations.find(r => r.id === reservationId);

        if (reservation) {
          setReservationInFocus(reservation);
        } else {
          const res = await client.getMockTemairazuReservation(propertyId, reservationId);
          setReservationInFocus(res.reservation);
        }

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [reservations, setReservationInFocus, setError, setLoading, client],
  );

  const processTemairazuReservations = useCallback(
    async () => {
      setLoading(true);
      try {
        await client.processTemairazuReservations();
        setLoading(false)
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [setLoading, setError, client],
  )

  return {
    createReservation,
    getReservation,
    getReservations,
    processTemairazuReservations,
  }
}

export function createMockReservationInsertDTO() {} // This function will normalize the mock reservation mock input

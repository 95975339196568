import { useState } from 'react'
import { Controller, SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { t } from '@lingui/macro';

import { SlideOver } from "../../../components/slideover/SlideOver";
import { useNotificationStore } from '../../../store/notificationStore';
import { InputGroup } from '../../../components/forms/Input';
import { useGlobalStore } from '../../../store/globalStore';
import { OptionDTO } from '../../../../types/option.dto';
import { usePropertyStore } from '../../../store/propertyStore';
import { useOptionStore } from '../../../store/optionStore';
import { useTaxStore } from '../../../store/taxStore';
import { useOptionService } from '../../../services/useOptionService';
import { TaxDropDown } from '../../reservation/components/TaxDropDown';
import { OptionCategoryDropDown } from '../../reservation/components/OptionCategoryDropDown';
import { SelectInputGroup } from '../../../components/forms/Select';

enum PriceType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
}

type Inputs = {
  name: string;
  nameJa?: string;
  price: number;
  taxId?: number;
  optionCategoryId: number;
  priceType: PriceType;
}

type Param = {
  option?: OptionDTO;
}

export function PropertyOptionForm({ option }: Param) {
  const [open, setOpen] = useState(true);
  const property = usePropertyStore(state => state.propertyInFocus);
  const propertyId = property?.id ?? "";

  const setOptionCategory = useOptionStore(state => state.setOptionCategory);
  const setTax = useTaxStore(state => state.setTax);
  const tax = useTaxStore(state => state.tax);

  const loading = useGlobalStore(state => state.loading);

  const addNotification = useNotificationStore((state) => state.addNotification);

  const { createOption, updateOption } = useOptionService(propertyId);

  const methods = useForm<Inputs>({
    defaultValues: {
      price: option?.price ?? 0,
      name: option?.name ?? "",
      priceType: PriceType[option?.priceType as PriceType ?? "FIXED"],
      optionCategoryId: option?.optionCategory?.id,
      taxId: option?.tax?.id,
    }
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = methods;

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      if (option) {
        await updateOption({
          ...data,
          id: option.id,
        })
      } else {
        await createOption({
          ...data,
          taxId: tax?.id,
        });
      }

      setOpen(false);

      setTimeout(() => {
        addNotification({
          title: t`Option(s) successfully ${option ? 'updated' : 'created'}`,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);

    } catch (e: unknown) {
      addNotification({
        title: t`Unable to ${option ? 'update' : 'create'} option`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
    }
  }

  const renderForm = () => {
    return (
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div>
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputGroup
                label={t`Option Name*`}
                inputProps={{
                  name: "name",
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="nameJa"
            render={({ field }) => (
              <InputGroup
                label={t`Option Name (JA)`}
                inputProps={{
                  name: "nameJa",
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="price"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputGroup
                label={t`Price`}
                inputProps={{
                  name: "price",
                  type: "number",
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="priceType"
            render={({ field }) => {
              return <SelectInputGroup
                selectProps={{
                  options: [
                    { value: PriceType.FIXED, label: "Fixed" },
                    { value: PriceType.VARIABLE, label: "Variable" },
                  ],
                  inlineLabel: t`Select Price Type`,
                  selected: field.value,
                  onChange: (data) => {
                    field.onChange(data.value);
                  }
                }}
                label={t`Price Type`}
              />
            }}
          />
          <TaxDropDown />
        </div>

        <div>
          <Controller
            control={control}
            name="optionCategoryId"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return <OptionCategoryDropDown 
                onChange={(selectedValue) => field.onChange(selectedValue)}
              />
            }}
          />
        </div>

      </div>
    );
  }

  const clearForm = () => {
    setOptionCategory(undefined);
    setTax(undefined);
    reset();
  }

  const disableSubmitButton = () =>
    isSubmitSuccessful ||
    isSubmitting ||
    loading ||
    !isValid;

  return <SlideOver
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit(submitHandler)}
    afterLeave={() => clearForm()}
    title={option ? t`Update Option` : t`Add Option`}
    disableSubmitButton={disableSubmitButton()}
    content={
      <FormProvider {...methods}>
        {renderForm()}
      </FormProvider>
    }
  />
}


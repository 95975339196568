import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const withUrlSlideOver = (
  WrappedSlideOver: React.ComponentType<any>
) => {
  return (props: any) => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleOpen = useCallback(() => {
      setOpen(true);
      if (!location.pathname.endsWith(props.urlSuffix)) {
        navigate(`${location.pathname}/${props.urlSuffix}`);
      }
    }, []);

    const handleClose = useCallback(() => {
      setOpen(false);
      if (location.pathname.endsWith(props.urlSuffix)) {
        navigate(location.pathname.replace(`/${props.urlSuffix}`, ""));
      }
    }, [location.pathname, props.urlSuffix]);

    return (
      <WrappedSlideOver
        {...props}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    );
  };
};

import { create } from "zustand"
import { OptionCategoryDTO, OptionCategoryListDTO, OptionDTO, OptionListDTO } from "../../types/option.dto"
import { PaginationParam } from "../app.d";
import { defaultPaginationValues } from "./utils";

export type OptionState = {
  readonly option?: OptionDTO;
  readonly options: OptionListDTO;
  readonly optionCategories: OptionCategoryListDTO;
  readonly optionCategory?: OptionCategoryDTO;
  readonly pagination: PaginationParam;
  readonly setOption: (option?: OptionDTO) => void;
  readonly setOptions: (options: OptionListDTO) => void;
  readonly setOptionCategories: (categories: OptionCategoryListDTO) => void;
  readonly setOptionCategory: (category?: OptionCategoryDTO) => void;
  readonly setPagination: (pagination: PaginationParam) => void;
}

export const useOptionStore = create<OptionState>((set) => ({
  option: undefined,
  options: [],
  optionCategory: undefined,
  optionCategories: [],
  pagination: defaultPaginationValues,
  setOption: (option?: OptionDTO) => set({ option }),
  setOptions: (options: OptionListDTO) => set({ options }),
  setOptionCategories: (categories: OptionCategoryListDTO) => set({ optionCategories: categories }),
  setOptionCategory: (category?: OptionCategoryDTO) => set({ optionCategory: category }),
  setPagination: (pagination: PaginationParam) => set({ pagination }),
}));


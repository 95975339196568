import React, { useEffect, useMemo, useState } from "react";
import {
  SlideOver,
  SlideOverProps,
} from "../../../components/slideover/SlideOver";
import { Trans, t } from "@lingui/macro";
import { withUrlSlideOver } from "../../../components/hocs/withUrlSlideOver";
import { FormProvider, useForm } from "react-hook-form";
import { CreateReservationDTO } from "../../../../types/reservation.dto";
import DynamicFormGroups from "../../../components/forms/DynamicFormGroups";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { StackedSelectInputGroup } from "../../../components/forms/Select";
import { useReservationService } from "../../../services/useReservationService";
import { GuestInformationGuestForm } from "./GuestInformationGuestForm";
import { useGlobalStore } from "../../../store/globalStore";
import { useRoomStore } from "../../../store/roomStore";
import { Title } from "../../../components/elements/typography/Title";
import { StackedInputGroup } from "../../../components/forms/Input";
import { useNotificationStore } from "../../../store/notificationStore";
import { getTranslatedName } from "../../../utils";
import { useUserStore } from "../../../store/userStore";
import { useRoomService } from "../../../services/useRoomService";

const blankReservationForm = {
  checkInDate: "",
  checkOutDate: "",
  adults: 1,
  children: 0,
  roomTypeId: undefined,
  bookingPrice: 0,
  pointsUsed: 0,
  rooms: 1,
  guests: [],
};

const blankGuestForm = {
  firstName: "",
  firstNameFurigana: "",
  lastName: "",
  lastNameFurigana: "",
  alternateName: "",
  kanjiName: null,
  email: "",
  phone: "",
  gender: "",
  age: null,
  country: "",
  nationality: "",
  province: "",
  dob: null,
  address1: "",
  address2: "",
  zipcode: "",
  optOutEmail: false,
  isRepresentative: false,
  ageGroup: "",
  city: "",
};

const ReservationFormSlideOver: React.FC<SlideOverProps> = ({
  open,
  onOpen,
  onClose,
}) => {
  const propertyId = useGlobalStore((state) => state.currentProperty);
  const user = useUserStore((state) => state.user);

  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );
  const { roomTypes } = useRoomStore();
  const { getRoomTypes } = useRoomService(propertyId);
  const { addReservation } = useReservationService(propertyId);
  const methods = useForm<CreateReservationDTO>({
    defaultValues: blankReservationForm,
  });
  const { reset } = methods;

  useEffect(() => {
    if (!roomTypes.length) {
      getRoomTypes();
    }
  }, [roomTypes]);

  const roomTypeOptions = useMemo(
    () =>
      user
        ? roomTypes.map((roomType) => ({
            label: getTranslatedName(roomType, user.locale),
            value: roomType.id,
          }))
        : [],
    [roomTypes, user]
  );

  const onSubmit = async (data: CreateReservationDTO) => {
    // Handle form submission
    const originalData = data;
    try {
      setTimeout(() => {
        addNotification({
          title: t`New reservation created`,
          type: "success",
          timeoutMs: 1500,
        });
      });
      await addReservation(data);
      onClose();
    } catch (e: unknown) {
      addNotification({
        title: t`Unable to create reservation`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
      reset(originalData);
    }
  };

  return (
    <FormProvider {...methods}>
      <SlideOver
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        title={t`Create New Reservation`}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <>
          <div className="ml-[72px] mt-3">
            <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
              <div className="mx-4 grid gap-x-6 gap-y-8 grid-cols-2 pb-3">
                <StackedInputGroup
                  label={t`Check In Date`}
                  name="checkInDate"
                  type="date"
                  options={{ required: t`Please select check in date` }}
                />
                <StackedInputGroup
                  label={t`Check Out Date`}
                  name="checkOutDate"
                  type="date"
                  options={{ required: t`Please select check out date` }}
                />
                <StackedInputGroup
                  label={t`Num of Adults`}
                  name="adults"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Num of Children`}
                  name="children"
                  type="number"
                />
                <StackedSelectInputGroup
                  label={t`Room Type`}
                  selectProps={{
                    name: "roomTypeId",
                    options: roomTypeOptions,
                  }}
                  options={{ required: t`Please select room type` }}
                />

                <StackedInputGroup
                  label={t`Rooms`}
                  name="rooms"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Booking Price`}
                  name="bookingPrice"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Points Used`}
                  name="pointsUsed"
                  type="number"
                />
              </div>
            </div>
            <Title level={5} className="mt-3">
              <Trans>Guests</Trans>
            </Title>
          </div>
          <DynamicFormGroups
            name="guests"
            FormComponent={GuestInformationGuestForm}
            formMethods={methods}
            blankForm={blankGuestForm}
            addLabel={<UserPlusIcon className="h-5 w-5 text-gray-600" />}
            extraForms={1}
          />
        </>
      </SlideOver>
    </FormProvider>
  );
};

export default withUrlSlideOver(ReservationFormSlideOver);

import { Card, DatePicker, Form, Layout, Select, Typography } from "antd"
import dayjs, { Dayjs } from "dayjs";
import { Fragment, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { useReservationService } from "../../../services/useReservationService";
import { ReservationState, useReservationStore } from "../../../store/reservationStore";
import { ReservationRouteParam } from "./ReservationDetailView";
import { ReservationsQueryParam } from "../../../api/createReservationClient";
import { Some } from "../../../utils";
import { SearchDateType } from "../../../app.d";
import { LinkReservationTable } from "../components/LinkReservationTable";
import { Button } from "../../../components/elements";
import { LinkReservationState, useLinkReservationStore } from "../../../store/linkReservationStore";
import { useNotificationStore } from "../../../store/notificationStore";
import { Trans } from "@lingui/macro";

dayjs.extend(localizedFormat);

const { Title } = Typography;
const { RangePicker } = DatePicker;

type SearchInputs = {
  searchDateType: string;
  searchDates: Dayjs[];
}

export function LinkReservationView() {
  const navigate = useNavigate();

  const addNotification = useNotificationStore((state) => state.addNotification);

  const params = useParams<ReservationRouteParam>();
  const { reservationId, propertyId } = params;

  const reservation = useReservationStore((state: ReservationState) => state.reservation);
  const setReservationSearchParam = useReservationStore((state: ReservationState) => state.setReservationSearchParam);
  const reservationsToLink = useLinkReservationStore((state: LinkReservationState) => state.reservationsToLink);

  const {
    getReservation,
    linkReservation,
  } = useReservationService(propertyId || "");

  useEffect(() => {
    if (!reservation) {
      if (reservationId) {
        getReservation(reservationId);
      }
    }
  }, []);

  const initialSearchInputs: SearchInputs = {
    searchDateType: SearchDateType.CHECK_IN_DATE,
    searchDates: [
      dayjs(),
      dayjs(),
    ],
  }

  const { control, handleSubmit, formState: { isSubmitting } } = useForm<SearchInputs>({
    defaultValues: initialSearchInputs
  });

  if (Some(reservation)) {
    const {
      roomType,
      room,
      guestName,
      numberOfGuests,
      checkOutDate,
      checkInDate,
    } = reservation;
    const stayDates = (
      dayjs(checkInDate).format("LL") + " - " + dayjs(checkOutDate).format("LL")
    );

    const onSubmit: SubmitHandler<SearchInputs> = async data => {
      const baseSearchParam: ReservationsQueryParam = {
        page: "1",
        limit: "100"
      }

      const startDate = data.searchDates[0].toISOString();
      const endDate = data.searchDates[1].toISOString();

      switch (data.searchDateType) {
        case SearchDateType.CHECK_IN_DATE:
          setReservationSearchParam({
            ...baseSearchParam,
            checkInDateStart: startDate,
            checkInDateEnd: endDate,
          });
        break;
        case SearchDateType.CHECK_OUT_DATE:
          setReservationSearchParam({
            ...baseSearchParam,
            checkOutDateStart: startDate,
            checkOutDateEnd: endDate,
          });
        break;
        case SearchDateType.BOOKING_DATE:
          setReservationSearchParam({
            ...baseSearchParam,
            bookingDateStart: startDate,
            bookingDateEnd: endDate,
          });
        break;
      }
    }

    const handleLinkReservation = async () => {
      try {
        await linkReservation(reservation.id, reservationsToLink);

        addNotification({
          title: "Successfully linked reservation",
          type: "success",
          timeoutMs: 1500,
        });

        setTimeout(() => {
          navigate("..", { relative: "path" })
        }, 2000);
      } catch (e: unknown) {
        addNotification({
          title: "Unable to link reservation",
          type: "error",
        })
      }
    }

    const shouldButtonBeDisabled = () => {
      const currentReservationLinkedReservationIds = reservation.linkedReservations.map(r => r.id).sort();
      return isSubmitting || JSON.stringify(currentReservationLinkedReservationIds) === JSON.stringify(reservationsToLink.sort());
    }

    return (
      <Layout>
        <Title style={{ paddingTop: 10 }} level={4}>Link Reservation</Title>
        <Card
          title={
            <Fragment>
              <UserOutlined /> {roomType} ・ (Room Number: {room || "Unallocated"}) ・ {guestName} ・ {numberOfGuests} guests・ {stayDates}
            </Fragment>
          }>
          <Form
            layout="inline"
            onFinish={handleSubmit(onSubmit)}
          >
            <Form.Item label={`Search by`}>
              <Controller
                control={control}
                name="searchDateType"
                defaultValue={initialSearchInputs.searchDateType}
                render={({ field }) => (
                  <Select
                    style={{ minWidth: 130 }}
                    defaultValue={initialSearchInputs.searchDateType}
                    onChange={(e) => field.onChange(e)}
                    options={[
                      { label: `Check In`, value: SearchDateType.CHECK_IN_DATE },
                      { label: `Check Out`, value: SearchDateType.CHECK_OUT_DATE },
                      { label: `Booking Date`, value: SearchDateType.BOOKING_DATE },
                    ]}
                  />
                )}
              />

            </Form.Item>
            <Form.Item label={`Dates:`}>
              <Controller
                control={control}
                name="searchDates"
                defaultValue={initialSearchInputs.searchDates}
                render={({ field }) => (
                  <RangePicker 
                    defaultValue={[dayjs(), dayjs()]} 
                    onChange={(dates) => field.onChange(dates)}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary">Search</Button>
            </Form.Item>
          </Form>
        </Card>
        <LinkReservationTable reservation={reservation} />

        <footer className="bg-white fixed w-full bottom-0 h-16">
          <div className="max-w-[90%] py-3 flex items-center justify-between px-8">
            <Button
              size="lg"
              onClick={() => navigate(-1)}
            >
              <Trans>
                Back
              </Trans>
            </Button>

            <Button
              size="lg"
              type="primary"
              onClick={handleLinkReservation}
              disabled={shouldButtonBeDisabled()}
            >
              <Trans>Confirm Link</Trans>
            </Button>
          </div>
        </footer>
      </Layout>
    );
  }
  return null;
}


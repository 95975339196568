import { useCallback } from "react";
import { EditUserReqBody, createAuthClient } from "../api/createAuthClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { UserState, useUserStore } from "../store/userStore";
import { Some } from "../utils";
import { ResetPasswordEmailType } from "../constants/common/mailer";
import { t } from "@lingui/macro";
import { useNotificationStore } from "../store/notificationStore";

export function useAuthService() {
  const user = useUserStore((state: UserState) => state.user);
  const setUser = useUserStore((state: UserState) => state.setUser);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setAuthError = useUserStore((state: UserState) => state.setAuthError);
  
  const addNotification = useNotificationStore((state) => state.addNotification);

  const authClient = createAuthClient();

  const getCurrentUser = useCallback(
    async () => {
      try {
        setLoading(true);

        if (Some(user)) {
          setUser(user);
        } else {
          const res = await authClient.getUserInfo();
          setUser(res.user);
        }
        setAuthError(undefined);
        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setAuthError(String(e));
      }
    },
    [setLoading, setAuthError, user, setUser, authClient],
  );
  
  const refreshUser = useCallback(
    async () => {
      try {
        setLoading(true);
        const res = await authClient.getUserInfo();
        setUser(res.user);
        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
      }
    }, [setLoading, setUser, authClient])

  const logoutUser = useCallback(
    async () => {
      try {
        setLoading(true);
        await authClient.logout();
        setUser(undefined);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  )

  const editUser = useCallback(
    async (form: EditUserReqBody) => {
      try {
        setLoading(true);
        const res = await authClient.editUserInfo(form);
        setUser(res.user);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [authClient, setUser, setLoading, setError]
  )
  
  const sendInviteEmail = useCallback(
    async (email: string) => {
      try {
        setLoading(true);
        await authClient.requestResetPassword({
          email,
          emailType: ResetPasswordEmailType.Confirm,
        });
        addNotification({
          type: "success",
          title: t`Success!`,
          message: t`Verification email sent`,
        })
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [authClient, setLoading, setError]
  );

  return {
    getCurrentUser,
    refreshUser,
    logoutUser,
    editUser,
    sendInviteEmail,
  };
}

import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { PropertyState, usePropertyStore } from "../store/propertyStore";
import { None } from "../utils";
import { PropertyQueryParam, createPropertyClient } from "../api/createPropertyClient";
import { useTaxStore } from "../store/taxStore";
import { useReservationMemoStore } from "../store/reservationMemoStore";

export function usePropertyService() {
  const client = createPropertyClient();

  const setPropertyInFocus = usePropertyStore((state: PropertyState) => state.setPropertyInFocus);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setMemoCategories = useReservationMemoStore(state => state.setMemoCategories);

  const setTaxes = useTaxStore(state => state.setTaxes);

  const getProperty = useCallback(
    async (propertyId: string, data: PropertyQueryParam) => {
      try {
        setLoading(true);

        const res = await client.getProperty(propertyId, data);
        if (None(res.property)) throw new Error("Property not found");
        setPropertyInFocus(res.property);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [setPropertyInFocus, setLoading, client, setError],
  );

  const getTaxes = useCallback(
    async (propertyId: string) => {
      try {
        setLoading(true);

        const res = await client.getTaxes(propertyId);
        setTaxes(res.taxes);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [],
  );

  const getReservationMemoCategories = useCallback(
    async (propertyId: string) => {
      try {
        setLoading(true);

        const res = await client.getReservationMemoCategories(propertyId);
        setMemoCategories(res.categories);

        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        setError(String(e));
      }
    },
    [],
  )

  return {
    getProperty,
    getReservationMemoCategories,
    getTaxes,
  }
}

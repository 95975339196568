import React, { useEffect, useState } from "react";
import { Button } from "../../../components/elements";
import { Table } from "../../../components/datadisplay/Table";
import { Loading } from "../../../components/elements/Loading";
import { usePropertyStore } from "../../../store/propertyStore";
import { useUserStore } from "../../../store/userStore";
import { useSlideOverStore } from "../../../store/slideOverStore";
import { useNotificationStore } from "../../../store/notificationStore";
import { t } from "@lingui/macro";
import { ConfirmationModal } from "../../../components/elements/ConfirmationModal";
import { useOptionStore } from "../../../store/optionStore";
import { useOptionService } from "../../../services/useOptionService";
import { PropertyOptionForm } from "./PropertyOptionSlideOver";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { Pagination } from "../../../components/datadisplay/Pagination";
import { defaultPaginationValues } from "../../../store/utils";
import { getTranslatedName } from "../../../utils";

const defaultPaginationQueryParam = {
  limit: defaultPaginationValues.pageSize,
  page: defaultPaginationValues.currentPage,
}

export const PropertyOptionsListView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState<number| null>(null);
  const [itemToDeleteName, setItemToDeleteName] = useState<string | undefined>("")
  const [searchParams, setSearchParams] = useSearchParams();
  const addNotification = useNotificationStore((state) => state.addNotification);

  const options = useOptionStore(state => state.options);
  const pagination = useOptionStore(state => state.pagination);
  const property = usePropertyStore(state => state.propertyInFocus);
  const user = useUserStore(state => state.user);
  const setSlideOver = useSlideOverStore(state => state.setSlideOver);

  const propertyId = property?.id ?? "";
  const queryParams = searchParams.toString();

  const { getOptions, deleteOption } = useOptionService(propertyId)

  const handleDeleteModal = (itemId: number) => {
    const optionName = options.find(o => o.id === itemId)?.name;
    setItemToDeleteName(optionName);
    setItemToDeleteId(itemId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDeleteId) {
      try {
        await deleteOption(itemToDeleteId);
        setTimeout(() => {
          addNotification({
            title: t`Succesfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }
    }

    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handlePageChange = (newPage: number) => {
    setSearchParams(queryString.stringify({
      ...defaultPaginationQueryParam,
      page: newPage,
    }));
  }

  useEffect(() => {
    if (propertyId) {
      if (queryParams) {
        const parsedQueryParams = queryString.parse(queryParams);
        getOptions(parsedQueryParams);
      } else {
        setSearchParams(queryString.stringify(defaultPaginationQueryParam));
      }
    }
  }, [propertyId, queryParams]);

  return options.length && user ? (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <Button type="primary" onClick={() => setSlideOver(<PropertyOptionForm />)}>Add Option</Button>
      </div>
      <div className="mt-6">
        <Table
          data={options.map(o => {
            const name = getTranslatedName(o, user.locale);

            const { optionCategory } = o;
            const optionCategoryName = optionCategory ? getTranslatedName(optionCategory, user.locale) : "";

            const { tax } = o;
            const taxName = tax && (getTranslatedName(tax, user.locale) ?? tax?.name);

            return {
              id: o.id,
              name,
              price: o.price,
              category: optionCategoryName,
              tax: taxName,
            }
          })}

          columns={[
            {
              key: "name",
              title: t`Name`,
            },
            {
              key: "category",
              title: t`Category`,
            },
            {
              key: "price",
              title: t`Price`,
            },
            {
              key: "tax",
              title: t`Tax`,
            },
          ]}

          onEdit={(item) => setSlideOver(
            <PropertyOptionForm option={options.find(o => o.id === item.id)} />
          )}
          onDelete={(id) => handleDeleteModal(id)}
        />
        <Pagination
          onPageChange={handlePageChange}
          currentPage={pagination.currentPage}
          totalPages={pagination.pageCount ?? 0}
          resultsPerPage={pagination.pageSize}
          totalResults={pagination.total ?? 0}
        />
        <ConfirmationModal
          title={t`Delete Option`}
          message={t`Are you sure you want to permanently remove this option '${itemToDeleteName}' from this property?`}
          buttonName={t`Delete`}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          isOpen={isModalOpen}
        />
      </div>
    </div>
  ) : <Loading />;
}

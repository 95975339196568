import React from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { useAuthService } from "../../../services/useAuthService";
import { createAuthClient } from "../../../api/createAuthClient";
import { Link, useNavigate } from "react-router-dom";
import { useNotificationStore } from "../../../store/notificationStore";
import { LogoIcon } from "../../../components/icons";
import { Button } from "../../../components/elements";
import { Trans } from "@lingui/macro";

type Inputs = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = createAuthClient();
  const { getCurrentUser } = useAuthService();
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );

  const initialValues: Inputs = {
    email: "",
    password: "",
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  const notifyLoginError = (serverResponse: string) => {
    addNotification({
      title: "Unable to login",
      message: serverResponse,
      type: "error",
    });
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await login({ ...data });
      await getCurrentUser();
      navigate(`/`);
    } catch (e: any) {
      notifyLoginError(e.response.data.message);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 px-8">
        <div className="mx-auto w-full max-w-sm">
          <LogoIcon className="mx-auto h-10 w-auto text-gray-600" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-600">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 mx-auto w-full max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <Controller
                  control={control}
                  rules={{ required: "Email is required" }}
                  name="email"
                  render={({ field }) => (
                    <input
                      {...field}
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6"
                    />
                  )}
                />
              </div>
              {errors.email && (
                <div className="text-red-500 text-sm">
                  {errors.email.message}
                </div>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/request-reset-password"
                    className="font-semibold text-gray-600 hover:text-gray-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <Controller
                  control={control}
                  rules={{ required: "Password is required" }}
                  name="password"
                  render={({ field }) => (
                    // <input {...field} type="password" className="p-3 w-full border rounded-md" placeholder="Password" />
                    <input
                      {...field}
                      autoComplete="current-password"
                      type="password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6"
                    />
                  )}
                />
              </div>
              {errors.password && (
                <div className="text-red-500 text-sm">
                  {errors.password.message}
                </div>
              )}
            </div>

            <div>
              <Button
                type="primary"
                loading={isSubmitting}
                size="lg"
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;

export type PaginationParam = {
  currentPage: number;
  pageCount?: number;
  pageSize: number;
  total?: number;
  nextPageAvailable?: boolean;
};

export enum SearchDateType {
  CHECK_IN_DATE = "checkInDate",
  CHECK_OUT_DATE = "checkOutdate",
  BOOKING_DATE = "bookingDate",
}


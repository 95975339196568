import { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import queryString from "query-string";
import Title from "antd/es/typography/Title";
import {
  Row,
  Col,
  Card,
  Tabs,
  Breadcrumb,
  Layout,
  Space,
  DatePicker,
  Affix,
} from "antd";
import type { TabsProps } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import {
  DashboardState,
  useDashboardStore,
} from "../../../store/dashboardStore";
import { ReservationsQueryParam } from "../../../api/createReservationClient";
import { ArrivalsTable } from "../components/ArrivalsTable";
import { useDashboardService } from "../services/useDashboardService";
import { DeparturesTable } from "../components/DeparturesTable";
import { PropertyState, usePropertyStore } from "../../../store/propertyStore";
import { PropertyDashboardMemo } from "../components/PropertyDashboardMemo";
import { usePropertyService } from "../../../services/usePropertyService";
import { ContentLayout } from "../../../components/layouts";
import { Trans, t } from "@lingui/macro";
import { Button } from "../../../components/elements";
import { LogoAltIcon } from "../../../components/icons/LogoAlt";
import { Loading } from "../../../components/elements/Loading";

const dateFormat = "YYYY-MM-DD";

type DashboardQueryParams = {
  limit: string;
  page: string;
  date: string; // used for both checkInDate and checkOutDate
};

const defaultReservationQueryParam: DashboardQueryParams = {
  limit: "10",
  page: "1",
  date: dayjs().format(dateFormat),
};

export function PropertyDashboardView() {
  const params = useParams<{ propertyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getProperty } = usePropertyService();
  const property = usePropertyStore(
    (state: PropertyState) => state.propertyInFocus
  );

  const arrivals = useDashboardStore((state: DashboardState) => state.arrivals);
  const departures = useDashboardStore(
    (state: DashboardState) => state.departures
  );

  const isLoading = useGlobalStore((state: GlobalState) => state.loading);
  const { getArrivals, getDepartures } = useDashboardService(
    params.propertyId as string
  );

  const rawQueryParams = searchParams.toString();
  const { date = defaultReservationQueryParam.date } = queryString.parse(
    rawQueryParams
  ) as DashboardQueryParams;

  const handlePrevDay = () => {
    if (rawQueryParams) {
      const parsedQueryParams: DashboardQueryParams = queryString.parse(
        rawQueryParams
      ) as DashboardQueryParams;
      const { date, ...rest } = parsedQueryParams;
      setSearchParams(
        queryString.stringify({
          ...rest,
          date: dayjs(date).subtract(1, "day").format(dateFormat),
        })
      );
    }
  };

  const handleNextDay = () => {
    if (rawQueryParams) {
      const parsedQueryParams: DashboardQueryParams = queryString.parse(
        rawQueryParams
      ) as DashboardQueryParams;
      const { date, ...rest } = parsedQueryParams;
      setSearchParams(
        queryString.stringify({
          ...rest,
          date: dayjs(date).add(1, "day").format(dateFormat),
        })
      );
    }
  };

  const handleDateChange = (date: any, dateString: string) => {
    if (rawQueryParams) {
      const parsedQueryParams: DashboardQueryParams = queryString.parse(
        rawQueryParams
      ) as DashboardQueryParams;
      const { date, ...rest } = parsedQueryParams;
      setSearchParams(
        queryString.stringify({
          ...rest,
          date: dateString,
        })
      );
    }
  };

  const reservationTabItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: t`Arrival`,
        children: <ArrivalsTable reservations={arrivals} />,
      },
      {
        key: "2",
        label: t`Departure`,
        children: <DeparturesTable reservations={departures} />,
      },
    ],
    [arrivals, departures]
  );

  const transformQueryParams = (rawQueryParams: string) => {
    // date -> checkInDate, checkOutDate (same value)
    const parsedQueryParams: DashboardQueryParams = queryString.parse(
      rawQueryParams
    ) as DashboardQueryParams;
    const { date, ...rest } = parsedQueryParams;
    return {
      arrivalQueryParams: {
        checkInDate: date,
        ...rest,
      } as ReservationsQueryParam,
      departureQueryParams: {
        checkOutDate: date,
        ...rest,
      } as ReservationsQueryParam,
    };
  };

  useEffect(() => {
    if (params.propertyId) {
      getProperty(params.propertyId, { showMemos: true });
      if (rawQueryParams) {
        const { arrivalQueryParams, departureQueryParams } =
          transformQueryParams(rawQueryParams);
        getArrivals({
          ...arrivalQueryParams,
        });
        getDepartures({
          ...departureQueryParams,
        });
      } else {
        setSearchParams(queryString.stringify(defaultReservationQueryParam));
      }
    }
  }, [params.propertyId, rawQueryParams]); // eslint-disable-line

  if (isLoading) return <Loading />;
  if (!property) return <div>No property</div>;

  return (
    <ContentLayout
      header={
        <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-6">
          <div className="flex items-center gap-x-8">
            <Trans>Dashboard</Trans>
            <Button
              icon={<LeftOutlined />}
              onClick={handlePrevDay}
              type="text"
            />
            <DatePicker
              defaultValue={dayjs(date, dateFormat)}
              value={dayjs(date, dateFormat)}
              format={dateFormat}
              onChange={handleDateChange}
            />
            <Button
              icon={<RightOutlined />}
              onClick={handleNextDay}
              type="text"
            />
          </div>
          <Button type="primary">
            <Trans>GO TO HIKITSUGICHO</Trans>
          </Button>
        </div>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={5}>
            <Trans>General Memo</Trans>
          </Title>
          <PropertyDashboardMemo memos={property?.memos} />
        </Col>
        <Col span={24}>
          <Title level={5}>
            <Trans>Reservations</Trans>
          </Title>
          <Card bordered={false}>
            <Tabs defaultActiveKey="1" items={reservationTabItems} />
          </Card>
        </Col>
      </Row>
    </ContentLayout>
  );
}

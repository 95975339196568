// @NOTE:
// In order to use this component properly, the form must be wrapped with <FormProvider>
// otherwise, the `useFormContext` will return null;
import { useEffect } from 'react'
import { t } from '@lingui/macro';
import { SelectInputGroup, SelectOption } from '../../../components/forms/Select';
import { useFormContext } from 'react-hook-form';
import { useDepartmentStore } from '../../../store/departmentStore';
import { useDepartmentService } from '../../../services/useDepartmentService';
import { useGlobalStore } from '../../../store/globalStore';

export function DepartmentDropDown() {
  const departments = useDepartmentStore(state => state.departments);
  const department = useDepartmentStore(state => state.department);
  const setDepartment = useDepartmentStore(state => state.setDepartment);
  const propertyId = useGlobalStore(state => state.currentProperty);

  const { getDepartments } = useDepartmentService(propertyId);
  const { getValues, setValue } = useFormContext<{departmentId: number}>();
  const departmentId = getValues("departmentId");

  useEffect(() => {
    if (propertyId) {
      getDepartments();
    }
  }, [propertyId]);

  useEffect(() => {
    if (departmentId && departments.length) {
      const dept = departments.find(d => d.id === departmentId);
      setDepartment(dept);
      setValue("departmentId", departmentId);
    }
  }, [departmentId, departments])

  const onSelectHandler = async (data: SelectOption) => {
    const dept = departments.find(d => d.id === data.value);
    if (dept) {
      setDepartment(dept);
      setValue("departmentId", dept.id);
    }
  }

  return (
    <SelectInputGroup
      selectProps={{
        options: departments.map(dept => {
          return {
            value: dept.id,
            label: dept.name,
          }
        }),
        selected: department && department.name,
        inlineLabel: t`Select department`,
        onChange: onSelectHandler,
      }}
      label={t`Department`}
    />
  )
}


import { useState, ReactNode, MouseEventHandler } from 'react';

interface TooltipProps {
  content: string;
  children: ReactNode;
}

export function Tooltip({ content, children }: TooltipProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip: MouseEventHandler = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip: MouseEventHandler = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className="relative inline-block">
      <span
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="cursor-pointer"
      >
        {children}
      </span>
      {isTooltipVisible && (
        <div className="absolute z-10 bg-gray-800 text-white text-xs p-2 rounded-md min-w-[5rem] left-0 right-0 top-[-2rem] text-center">
          {content}
        </div>
      )}
    </div>
  );
};


import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { createPropertyClient } from "../api/createPropertyClient";

export function useCommonService() {
  const client = createPropertyClient();

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setReservationStatuses = useGlobalStore(
    (state: GlobalState) => state.setReservationStatuses
  );

  const getReservationStatuses = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.getReservationStatuses();
      setReservationStatuses(data);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
    }
  }, []);

  return {
    getReservationStatuses,
  };
}

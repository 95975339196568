import { apiClient, GenericGetQueryParam } from "./createApiClient"
import { GenericHTTPResponse } from "./createApiClient";
import { FacilityDTO, FacilityListDTO, CreateFacilityDTO, UpdateFacilityDTO } from "../../types/facility.dto";

type GetFacilitiesResponse = {
  message: string;
  facilities: FacilityListDTO;
};

type GetFacilityResponse = {
  message: string;
  facility: FacilityDTO;
}

export function createFacilityClient (propertyId: string) {
  const baseUrl = `/property/${propertyId}/facilities`;

  const getFacilities = (data?: GenericGetQueryParam) => apiClient.get<GetFacilitiesResponse>(baseUrl, data);
  const getFacility = (deptId: number) => apiClient.get<GetFacilityResponse>(`${baseUrl}/${deptId}`);

  const createFacility = (data: CreateFacilityDTO) => apiClient.post<
    CreateFacilityDTO,
    GenericHTTPResponse
  >(baseUrl, data);

  const updateFacility = (data: UpdateFacilityDTO) => apiClient.patch<
    UpdateFacilityDTO,
    GenericHTTPResponse
  >(`${baseUrl}/${data.id}`, data);

  const deleteFacility = (id: number) => apiClient.delete<GenericHTTPResponse>(`${baseUrl}/${id}`);

  return {
    getFacilities,
    getFacility,
    createFacility,
    updateFacility,
    deleteFacility,
  };
}



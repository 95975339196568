import { PropertyDTO } from "../../types/property.dto";
import { ReservationStatusDTO } from "../../types/reservation.dto";
import { ReservationMemoCategoryListDTO } from "../../types/reservationMemoCategory.dto";
import { RoomTypeDTO } from "../../types/roomType.dto";
import { TaxListDTO } from "../../types/tax.dto";
import { apiClient } from "./createApiClient";

type PropertyResponse = {
  message: string;
  property: PropertyDTO;
};

type TaxResponse = {
  message: string;
  taxes: TaxListDTO;
}

type ReservationStatusResponse = {
  message: string;
  data: ReservationStatusDTO[];
}

type RoomTypeResponse = {
  message: string;
  error?: string;
  roomTypes: RoomTypeDTO[];
}

export type PropertyQueryParam = {
  showRoomTypes?: boolean;
  showOptions?: boolean;
  showPlans?: boolean;
  showTasks?: boolean;
  showMemos?: boolean;
};

export function createPropertyClient() {
  const getProperty = (id: string, data: PropertyQueryParam) =>
    apiClient.get<PropertyResponse>(`/property/${id}`, data);

  const getTaxes = (id: string) =>
    apiClient.get<TaxResponse>(`/property/${id}/tax`);

  const getReservationMemoCategories = (id: string) =>
    apiClient.get<{
      message: string;
      error?: string;
      categories: ReservationMemoCategoryListDTO;
    }>(`/property/${id}/reservation-memo-categories`);

  const getRoomTypes = (id: string) =>
    apiClient.get<RoomTypeResponse>(`/property/${id}/room-types`)

  const getReservationStatuses = () =>
    apiClient.get<ReservationStatusResponse>(`/common/reservation-status`);

  return {
    getProperty,
    getReservationMemoCategories,
    getTaxes,
    getReservationStatuses,
    getRoomTypes,
  };
}

import { Department } from "../../types/department";
import { CreateDepartmentDTO, UpdateDepartmentDTO } from "../../types/department.dto";
import { PaginationParam } from "../app.d";
import { apiClient, GenericGetQueryParam } from "./createApiClient"
import { GenericHTTPResponse } from "./createApiClient";

type GetDepartmentsResponse = {
  message: string;
  departments: Department[];
  pagination: PaginationParam;
};

type SearchResponse = Omit<GetDepartmentsResponse, "pagination">;

type GetDepartmentResponse = {
  message: string;
  department: Department;
}

export function createDepartmentClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/departments`;

  const getDepartments = (data?: GenericGetQueryParam) => apiClient.get<GetDepartmentsResponse>(baseUrl, data);
  const getDepartment = (deptId: number) => apiClient.get<GetDepartmentResponse>(`${baseUrl}/${deptId}`);

  const searchDepartment = (term: string) => apiClient.get<SearchResponse>(`${baseUrl}/search`, { term });

  const createDepartment = (data: CreateDepartmentDTO) => apiClient.post<
    CreateDepartmentDTO,
    GenericHTTPResponse
  >(baseUrl, data);

  const updateDepartment = (data: UpdateDepartmentDTO) => apiClient.patch<
    UpdateDepartmentDTO,
    GenericHTTPResponse
  >(`${baseUrl}/${data.id}`, data);

  const deleteDepartment = (id: number) => apiClient.delete<GenericHTTPResponse>(`${baseUrl}/${id}`);

  return {
    getDepartments,
    getDepartment,
    createDepartment,
    updateDepartment,
    deleteDepartment,
    searchDepartment,
  };
}


import { ChangeEvent, useEffect, useState } from "react";
import { Transition } from '@headlessui/react'
import { Trans, t } from '@lingui/macro';
import { useParams } from "react-router-dom";

import { SlideOver } from "../../../components/slideover/SlideOver";
import { useReservationMemoStore } from "../../../store/reservationMemoStore";
import { MemoCategoryDropDown } from "./MemoCategoryDropDown";
import { Checkbox } from "../../../components/forms/Checkbox";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useReservationStore } from "../../../store/reservationStore";
import { getTotalStayNights, Some } from "../../../utils";
import dayjs from "dayjs";
import { TextArea } from "../../../components/forms/TextArea";
import { InputGroup } from "../../../components/forms/Input";
import { useUserStore } from "../../../store/userStore";
import { useReservationService } from "../../../services/useReservationService";
import { ReservationRouteParam } from "../routes/ReservationDetailView";
import { useNotificationStore } from "../../../store/notificationStore";
import { ReservationMemoTopicDTO } from "../../../../types/reservationMemo.dto";

type Inputs = {
  title?: string;
  categoryId?: number;
  datesToShow: { date: string; checked: boolean }[];
  content: string;
  showOnDailyPlannerFB: boolean;
  showOnDailyPlannerFull: boolean;
}

type Props = {
  existingTopic?: ReservationMemoTopicDTO;
}

export function CreateReservationMemoTopicForm({ existingTopic = undefined }: Props) {
  const [open, setOpen] = useState(true);

  const memoCategory = useReservationMemoStore(state => state.memoCategory);
  const setMemoCategory = useReservationMemoStore(state => state.setMemoCategory);
  const memoCategories = useReservationMemoStore(state => state.memoCategories);

  const reservation = useReservationStore(state => state.reservation);
  const user = useUserStore(state => state.user);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const params = useParams<ReservationRouteParam>();

  const { addReservationMemoTopic, updateReservationMemoTopic } = useReservationService(params.propertyId || "");
  const datesToShowCheckboxItems: { date: string; checked: boolean }[] = [];

  const initialValues: Inputs = {
    categoryId: memoCategory?.id,
    title: memoCategory?.name,
    content: "",
    showOnDailyPlannerFull: true,
    showOnDailyPlannerFB: true,
    datesToShow: [],
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid, isDirty }
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "datesToShow",
  });

  const selectedDatesToShow = watch("datesToShow").filter(d => d.checked);

  useEffect(() => {
    if (memoCategory) {
      setValue("categoryId", memoCategory.id);
    } else {
      setValue("categoryId", undefined);
    }
  }, [memoCategory]);

  useEffect(() => {
    if (reservation) {
      const { checkInDate, checkOutDate } = reservation;
      const totalStayNights = getTotalStayNights(dayjs(checkInDate), dayjs(checkOutDate));

      for (let i = 0; i < totalStayNights + 1; i++) {
        const modifiedCheckInDate = dayjs(checkInDate).add(i, "day");
        const checked = existingTopic ?
          existingTopic.datesToShow
            .map(d => dayjs(d).format("YYYY-MM-DD"))
            .includes(modifiedCheckInDate.format("YYYY-MM-DD"))
          : false;

        datesToShowCheckboxItems.push({
          date: modifiedCheckInDate.toISOString(),
          checked,
        });
      }

      replace(datesToShowCheckboxItems);
    }
  }, [existingTopic]);

  useEffect(() => {
    if (existingTopic && memoCategories) {
      setValue("categoryId", existingTopic.categoryId);
      setValue("title", existingTopic.title);
      setMemoCategory(memoCategories.find(c => c.id === existingTopic.categoryId));
    }
  }, [existingTopic, memoCategories]);

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    if (Some(reservation) && Some(user) && Some(memoCategory)) {
      try {
        const title = data.title || memoCategory.name || "";
        const categoryId = memoCategory.id;
        const datesToShow = data.datesToShow.filter(d => d.checked).map(d => d.date);

        if (existingTopic) {
          await updateReservationMemoTopic(
            reservation.id,
            {
              id: existingTopic.id,
              categoryId,
              title,
              datesToShow,
            },
          )
        } else {
          await addReservationMemoTopic({
            reservationId: reservation.id,
            userId: user.id,
            categoryId,
            title,
            datesToShow,
            memo: {
              content: data.content,
              showOnDailyPlannerFull: data.showOnDailyPlannerFull,
              showOnDailyPlannerFB: data.showOnDailyPlannerFB,
            },
          });
        }

        setOpen(false);

        setTimeout(() => {
          addNotification({
            title: t`Topic & memo successfully added`,
            type: "success",
            timeoutMs: 1500,
          });
        }, 500);
      } catch (e: unknown) {
        addNotification({
          title: t`Unable to create reservation topic & memo`,
          type: "error",
          message: String(e),
          timeoutMs: 1500,
        });
      }
    }
  }

  const renderTopicAndMemoForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div className="px-6 pt-4">
          <Controller
            control={control}
            name="showOnDailyPlannerFB"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                label={t`Show on Daily Planner F&B`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.checked)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="showOnDailyPlannerFull"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                label={t`Show on Daily Planner Full`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.checked)
                }}
              />
            )}
          />
        </div>
        <div className="px-6 pt-4">
          <legend className="text-sm font-semibold leading-6 text-gray-900 pb-2">
            <Trans>Dates to Show</Trans>
          </legend>
          {fields.map((field, idx) => {
            const label = dayjs(field.date).format("MMMM DD, YYYY");
            return (
              <div key={field.id} className="relative flex items-start">
                <Controller
                  control={control}
                  name={`datesToShow.${idx}.checked`}
                  render={({ field }) => {
                    return <Checkbox
                      checked={field.value}
                      label={label}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  }}
                />
              </div>
            )
           })}
        </div>
        {!existingTopic &&
          <div className="px-6 py-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="content"
              render={({ field }) => (
                <TextArea
                  label={t`Memo`}
                  value={field.value}
                  required={true}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>
        }
      </div>
    )
  }

  const clearForm = () => {
    setMemoCategory(undefined);
    reset();
  }

  const disableSubmitButton = () =>
    isSubmitting ||
      isSubmitSuccessful ||
      !memoCategory ||
      selectedDatesToShow.length === 0 ||
      !isDirty ||
      !isValid;

  const generateForm = () => (
    <div className="space-y-0 divide-y divide-gray-200 py-0">
      <div>
        <MemoCategoryDropDown />
        {memoCategory &&
          <Transition
            enter="ease-in-out duration-250"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-250"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field }) => (
                <InputGroup
                  label={t`Memo Topic`}
                  inputProps={{
                    name: "title",
                    value: field.value,
                    onChange: (value) => {
                      field.onChange(value);
                    }
                  }}
                />
              )}
            />
          </Transition>
        }
      </div>
      {memoCategory &&
        <Transition
          enter="ease-in-out duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {renderTopicAndMemoForm}
        </Transition>
      }
    </div>
  );

  return <SlideOver
    title={t`Add topic and memo to reservation`}
    open={open}
    onClose={() => setOpen(false)}
    afterLeave={() => {
      clearForm();
    }}
    disableSubmitButton={disableSubmitButton()}
    onSubmit={handleSubmit(submitHandler)}
    content={generateForm()}
  />
}


// @NOTE:
// In order to use this component properly, the form must be wrapped with <FormProvider>
// otherwise, the `useFormContext` will return null;
import { useEffect } from 'react'
import { t } from '@lingui/macro';
import { useTaxStore } from '../../../store/taxStore';
import { usePropertyStore } from '../../../store/propertyStore';
import { usePropertyService } from '../../../services/usePropertyService';
import { SelectInputGroup, SelectOption } from '../../../components/forms/Select';
import { useFormContext } from 'react-hook-form';

export function TaxDropDown() {
  const taxes = useTaxStore(state => state.taxes);
  const tax = useTaxStore(state => state.tax);
  const setTax = useTaxStore(state => state.setTax);
  const property = usePropertyStore(state => state.propertyInFocus);

  const propertyId = property?.id ?? "";

  const { getTaxes } = usePropertyService();
  const { getValues, setValue } = useFormContext<{taxId: number}>();
  const taxId = getValues("taxId");

  useEffect(() => {
    if (propertyId) {
      getTaxes(propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (taxId && taxes.length) {
      const tax = taxes.find(t => t.id === taxId);
      setTax(tax);
      setValue("taxId", taxId);
    }
  }, [taxId, taxes])

  const onSelectHandler = async (data: SelectOption) => {
    const tax = taxes.find(tax => tax.id === data.value);
    if (tax) {
      setTax(tax);
      setValue("taxId", tax.id);
    }
  }

  return (
    <SelectInputGroup
      selectProps={{
        options: taxes.map(tax => {
          return {
            value: tax.id,
            label: tax.name,
          }
        }),
        selected: tax && tax.name,
        inlineLabel: t`Select tax`,
        onChange: onSelectHandler,
      }}
      label={t`Tax`}
    />
  )
}

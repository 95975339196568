import Table, { ColumnsType } from "antd/es/table";
import React from "react";
import { Reservation } from "../../../../types/reservation";
import {
  ReservationListDTO,
  ReservationListItemDTO,
} from "../../../../types/reservation.dto";
import { Trans } from "@lingui/macro";

const columns: ColumnsType<ReservationListItemDTO> = [
  {
    title: <Trans>Room</Trans>,
    dataIndex: "roomType",
    key: "room",
  },
  {
    title: <Trans>Guest Name</Trans>,
    dataIndex: "guestName",
    key: "guestName",
  },
  {
    title: <Trans>Check In Date</Trans>,
    dataIndex: "checkInDate",
    key: "checkOutDate",
  },
  {
    title: <Trans>OTA ID</Trans>,
    dataIndex: "temairazuOTABookingId",
    key: "otaId",
  },
];

interface DeparturesTableProps {
  reservations: ReservationListDTO;
}
export const DeparturesTable: React.FC<DeparturesTableProps> = ({
  reservations,
}) => {
  return <Table rowKey="id" columns={columns} dataSource={reservations} />;
};

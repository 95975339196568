import React from "react";
import { Route, Routes } from "react-router-dom";
import BasicInformationPage from "../components/BasicInformationPage";
import { ContentLayoutWithFullWidth } from "../../../components/layouts";
import { t } from "@lingui/macro";
import { HomeModernIcon } from "@heroicons/react/24/outline";
import { PropertyState, usePropertyStore } from "../../../store/propertyStore";
import { Sidebar } from "../../../components/elements/Sidebar";
import { OTAListView } from "../components/OTAListView";
import { PropertyOptionsListView } from "../components/PropertyOptionsListView";
import { DepartmentListView } from "../components/DepartmentListView";
import { FacilityListView } from "../components/FacilityListView";


export const PropertySettingsView: React.FC = () => {
  const property = usePropertyStore((state: PropertyState) => state.propertyInFocus);

  if (!property) return <div>No property</div>;

  return (
    <ContentLayoutWithFullWidth sideColumn={
      <div className="space-y-1">
        <Sidebar
          navItems={[
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Basic information`,
              to: "/property/settings/basic",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Facilities`,
              to: "/property/settings/facilities",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Departments`,
              to: "/property/settings/departments",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Options`,
              to: "/property/settings/options",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Plans`,
              to: "/property/settings/plan",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`Rooms`,
              to: "/property/settings/room",
            },
            {
              icon: <HomeModernIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />,
              label: t`OTA`,
              to: "/property/settings/ota",
            },
          ]}
        />
      </div>
    }>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Routes>
          <Route
            path="basic"
            element={<BasicInformationPage propertyId={property.id} />}
          />

          <Route
            path="ota"
            element={<OTAListView />}
          />

          <Route
            path="options"
            element={<PropertyOptionsListView />}
          />

          <Route
            path="departments"
            element={<DepartmentListView />}
          />

          <Route
            path="facilities"
            element={<FacilityListView />}
          />
        </Routes>
      </div>
    </ContentLayoutWithFullWidth>
  );
};

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { useReservationService } from "../../../services/useReservationService";
import { ReservationState, useReservationStore } from "../../../store/reservationStore";
import { LinkReservationState, useLinkReservationStore } from "../../../store/linkReservationStore";
import { ReservationRouteParam } from "../routes/ReservationDetailView";
import { ReservationsQueryParam } from "../../../api/createReservationClient";
import { ReservationDetailDTO, ReservationListDTO } from "../../../../types/reservation.dto";
import { debounce, partialSearch } from "../../../utils";

type Props = {
  reservation: ReservationDetailDTO;
}

export function LinkReservationTable({ reservation }: Props) {
  const params = useParams<ReservationRouteParam>();
  const { propertyId } = params;

  const [searchState, setSearchState] = useState<{ isSearching: boolean, results: ReservationListDTO }>({
    isSearching: false,
    results: [],
  });

  const reservations = useReservationStore((state: ReservationState) => state.reservations);
  const setReservations = useReservationStore((state: ReservationState) => state.setReservations);
  const reservationSearchParam = useReservationStore((state: ReservationState) => state.reservationSearchParam);
  const setReservationSearchParam = useReservationStore((state: ReservationState) => state.setReservationSearchParam);

  const reservationsToLink = useLinkReservationStore((state: LinkReservationState) => state.reservationsToLink);
  const setReservationsToLink = useLinkReservationStore((state: LinkReservationState) => state.setReservationsToLink);

  const { getReservations } = useReservationService(propertyId as string);

  const initialSearchParams: ReservationsQueryParam = {
    page: "1",
    limit: "100",
    checkInDateStart: dayjs().toISOString(),
    checkInDateEnd: dayjs().toISOString(),
  }

  useEffect(() => {
    if (reservationSearchParam) {
      getReservations(reservationSearchParam);
    } else {
      setReservationSearchParam(initialSearchParams);
    }
  }, [reservationSearchParam]);

  useEffect(() => {
    if (reservations && reservations.length) {
      setReservations(
        reservations.filter(r => r.id !== reservation.id)
      )
    }
  }, [reservations.length]);

  useEffect(() => {
    const { linkedReservations } = reservation;
    if (linkedReservations && linkedReservations.length ) {
      setReservationsToLink(linkedReservations.map(r => r.id));
    }
  }, []);

  const searchHandler = (e: any) => {
    const searchTerm = e.target.value
    const results = partialSearch(reservations, searchTerm);

    setSearchState({
      isSearching: true,
      results,
    });

    if (!searchTerm) {
      setSearchState({
        isSearching: false,
        results: [],
      })
    }
  }

  const reservationsToRender = searchState.isSearching ? searchState.results : reservations;

  const headers = [
    "Check In",
    "Check Out",
    "Guest Name",
    "Room Type",
    "OTA",
    "Reservation OTA ID",
  ];

  return (
    <>
      <div className="px-8 mt-10">
        <div className="flex items-center">
          <div className="relative rounded-md shadow-sm ml-auto">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>

            <label className="sr-only">
              Filter / Search in Table
            </label>
            <input
              name="quicksearch"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm leading-6"
              placeholder="Filter / Search within Table"
              onChange={debounce(searchHandler, 250)}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="relative">
              <table 
                className="min-w-full table-fixed divide-y divide-gray-300 mb-16" 
                style={{
                  background: "white",
                }}>
                <thead>
                  <tr>
                    <th scope="col" className="relative w-12 px-6">
                    </th>
                    {headers.map(header => (
                      <th key={header} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {header}
                      </th>
                    ))}
                    <th scope="col" className="relative py-3.5 pl-3 pr-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {reservationsToRender.map(reservation => {
                    const { id } = reservation;
                    return (
                      <tr key={id}>
                        <td className="relative w-12 px-6">
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                            checked={reservationsToLink.includes(id)}
                            onChange={() => {
                              if (reservationsToLink.includes(id)) {
                                setReservationsToLink(
                                  reservationsToLink.filter(reservationId => reservationId !== id)
                                )
                              } else {
                                setReservationsToLink([...reservationsToLink, id])
                              }
                            }}
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.checkInDate}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.checkOutDate}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.guestName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.roomType}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.ota}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{reservation.temairazuOTABookingId}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


import { Button } from "../../../components/elements";
import React, { useEffect, useState } from "react";
import { Table } from "../../../components/datadisplay/Table";
import { Loading } from "../../../components/elements/Loading";
import { usePropertyStore, PropertyState } from "../../../store/propertyStore";
import { useUserStore, UserState } from "../../../store/userStore";
import { useSlideOverStore } from "../../../store/slideOverStore";
import { useNotificationStore } from "../../../store/notificationStore";
import { t, Trans } from "@lingui/macro";
import { ConfirmationModal } from "../../../components/elements/ConfirmationModal";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { defaultPaginationValues } from "../../../store/utils";
import { Pagination } from "../../../components/datadisplay/Pagination";
import { Input } from "../../../components/forms/Input";
import { useDepartmentStore } from "../../../store/departmentStore";
import { useDepartmentService } from "../../../services/useDepartmentService";
import { DepartmentForm } from "./DepartmentSlideOver";

const defaultPaginationQueryParam = {
  limit: defaultPaginationValues.pageSize,
  page: defaultPaginationValues.currentPage,
}

export const DepartmentListView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState<number| null>(null);
  const [itemToDeleteName, setItemToDeleteName] = useState<string | undefined>("")
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  const addNotification = useNotificationStore((state) => state.addNotification);

  const departments = useDepartmentStore(state => state.departments);
  const pagination = useDepartmentStore(state => state.pagination);
  const property = usePropertyStore((state: PropertyState) => state.propertyInFocus);
  const user = useUserStore((state: UserState) => state.user);
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);

  const propertyId = property?.id ?? "";
  const queryParams = searchParams.toString();

  const { getDepartments, deleteDepartment, searchDepartment } = useDepartmentService(propertyId);

  const handleDeleteModal = (itemId: number) => {
    const departmentName = departments.find(d => d.id === itemId)?.name;
    setItemToDeleteName(departmentName);
    setItemToDeleteId(itemId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDeleteId) {
      try {
        await deleteDepartment(itemToDeleteId);
        setTimeout(() => {
          addNotification({
            title: t`Succesfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }
    }

    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handlePageChange = (newPage: number) => {
    setSearchParams(queryString.stringify({
      ...defaultPaginationQueryParam,
      sort: sortColumn,
      direction: sortDirection,
      page: newPage,
    }));
  }

  const handleSearch = (term: string) => {
    searchDepartment(term);
  }

  const handleSort = (column: string, direction: 'asc' | 'desc') => {
    setSortColumn(column);
    setSortDirection(direction);
    setSearchParams(queryString.stringify({
      ...searchParams,
      limit: pagination.pageSize,
      page: pagination.currentPage,
      sort: column,
      direction,
    }));
  };

  useEffect(() => {
    if (propertyId) {
      if (queryParams) {
        const parsedQueryParams = queryString.parse(queryParams);
        getDepartments(parsedQueryParams);
      } else {
        setSearchParams(queryString.stringify(defaultPaginationQueryParam));
      }
    }
  }, [propertyId, queryParams]);

  return departments.length && user ? (
    <div className="px-8">
      <div className="flex items-center">
        <div className="flex-auto">
          <div className="ml-0 mt-0 inline-flex">
            <Input
              name="department-search"
              type="text"
              placeholder={t`search name`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
          </div>
          <div className="ml-2 mt-0 inline-flex">
            <Button
              type="primary"
              className="px-4 pt-2 pb-2"
              disabled={searchTerm === ""}
              onClick={() => handleSearch(searchTerm)}>
                <Trans>Search</Trans>
            </Button>
          </div>
          <div className="ml-2 mt-0 inline-flex">
            <Button
              type="default"
              className="px-4 pt-2 pb-2"
              onClick={() => {
                setSearchParams({});
                setSearchTerm("");
              }}>
               <Trans>Reset</Trans>
            </Button>
          </div>
        </div>
        <div className="ml-8 mt-0 flex-none">
          <Button
            type="primary"
            className="px-4"
            onClick={() => setSlideOver(<DepartmentForm />)}>
            <Trans>Add Department</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <Table
          data={departments.map(d => {
            return {
              id: d.id,
              name: d.name,
              description: d.description,
            }
          })}
          columns={[
            {
              key: "name",
              title: t`Name`,
              sortable: true,
            },
            {
              key: "description",
              title: t`Description`,
            },
          ]}

          onSort={handleSort}
          currentSortColumn={sortColumn}
          currentSortDirection={sortDirection}

          onEdit={(item) => setSlideOver(
            <DepartmentForm department={departments.find(d => d.id === item.id)} />
          )}
          onDelete={(id) => handleDeleteModal(id)}
        />
        <Pagination
          onPageChange={handlePageChange}
          currentPage={pagination.currentPage}
          totalPages={pagination.pageCount ?? 0}
          resultsPerPage={pagination.pageSize}
          totalResults={pagination.total ?? 0}
        />
        <ConfirmationModal
          title={t`Delete OTA`}
          message={t`Are you sure you want to permanently remove this department ${itemToDeleteName} from this property?`}
          buttonName={t`Delete`}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          isOpen={isModalOpen}
        />
      </div>
    </div>
  ) : <Loading />;
}

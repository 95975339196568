import { create } from "zustand"
import { ReservationGuestDTO, ReservationGuestListDTO } from "../../types/guest.dto";

export type GuestState = {
  readonly guest?: ReservationGuestDTO;
  readonly guests: ReservationGuestListDTO;
  readonly setGuest: (guest?: ReservationGuestDTO) => void;
  readonly setGuests: (guests: ReservationGuestListDTO) => void;
}

export const useGuestStore = create<GuestState>((set) => ({
  guest: undefined,
  guests: [],
  setGuest: (guest?: ReservationGuestDTO) => set({ guest }),
  setGuests: (guests: ReservationGuestListDTO) => set({ guests }),
}));


import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const LogoSvg = () => (
  <svg height="100%" viewBox="0 0 195 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Hamlet</title>
    <defs>
      <linearGradient x1="46.8357533%" y1="100%" x2="46.8357533%" y2="0%" id="linearGradient-1">
        <stop stopColor="#A0C9FF" offset="0%"></stop>
        <stop stopColor="#FFAD8A" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="logo-(light)" transform="translate(-8.000000, 0.000000)" fillRule="nonzero">
        <g id="hamlet_logo_dec2017_light" transform="translate(8.000000, 0.000000)">
          <path d="M35.3,0 L0.7,0 L9,8.9 L9,25.2 L0.7,34 L35.3,34 L19.4,17 L35.3,0 Z M18,15.5 L12.8,10 L23.2,10 L18,15.5 Z M30.7,2 L25,8 L10.9,8 L5.3,2 L30.7,2 Z M11,11.1 L16.6,17.1 L11,23.1 L11,11.1 L11,11.1 Z M5.3,32 L10.9,26 L25,26 L30.6,32 L5.3,32 Z M23.2,24 L12.8,24 L18,18.5 L23.2,24 Z" id="Shape" fill="url(#linearGradient-1)"></path>
          <g id="Group" transform="translate(46.000000, 7.000000)" fill="currentColor">
            <polygon id="Shape" points="12 19.5 9 19.5 2.5 13.1 2.5 19.5 0.2 19.5 0.2 0.6 2.5 0.6 2.5 12.7 8.6 6.9 11.1 6.9 5.1 12.6"></polygon>
            <path d="M14.3,0.2 C15.2,0.2 15.8,0.9 15.8,1.7 C15.8,2.6 15.1,3.2 14.3,3.2 C13.4,3.2 12.8,2.5 12.8,1.7 C12.7,0.8 13.4,0.2 14.3,0.2 Z M13.2,6.9 L15.5,6.9 L15.5,19.5 L13.2,19.5 L13.2,6.9 Z" id="Shape"></path>
            <path d="M25.6,6.8 L25.6,9.1 C25.3,9 24.9,9 24.4,9 C22.9,9 20.9,9.3 20.9,11.5 L20.9,19.5 L18.6,19.5 L18.6,6.9 L21,6.9 L21,9.9 C21.6,8 23.3,6.8 24.8,6.8 C25,6.8 25.3,6.8 25.6,6.8 Z" id="Shape"></path>
            <path d="M35.2,16.4 C34.8,18.4 33.6,19.9 31.2,19.9 C28.8,19.9 27,18.2 27,16.1 C27,13.9 28.8,12.7 31.5,12.2 C33.9,11.7 34.9,11.3 35.2,10.7 L35.2,10.3 C35.2,8.9 33.9,8.4 32.5,8.4 C30.7,8.4 29.3,9.1 28.1,10.4 L28.1,8.2 C29.2,7.3 30.7,6.6 32.8,6.6 C35.2,6.6 37.5,7.5 37.5,10.6 L37.5,19.5 L35.2,19.5 C35.2,19.5 35.2,16.4 35.2,16.4 Z M35.2,12.3 L31.8,13.5 C30.3,14 29.4,14.6 29.4,15.9 C29.4,17.4 30.4,18.3 31.9,18.3 C33.5,18.3 35.2,16.9 35.1,14.1 L35.1,12.3 L35.2,12.3 L35.2,12.3 Z" id="Shape"></path>
            <polygon id="Shape" points="52.5 19.5 49.5 19.5 43 13.1 43 19.5 40.7 19.5 40.7 0.6 43 0.6 43 12.7 49.1 6.9 51.6 6.9 45.6 12.6"></polygon>
            <path d="M62,6.9 L64.3,6.9 L64.3,19.5 L62,19.5 L62,16.9 C61.4,18.7 60,19.9 57.9,19.9 C55.3,19.9 53.8,18.4 53.8,15.3 L53.8,7 L56.1,7 L56.1,14.9 C56.1,17.2 57.2,18.3 58.9,18.3 C60.7,18.3 62.1,17.3 62.1,15.1 L62.1,6.9 C62.1,6.9 62,6.9 62,6.9 Z" id="Shape"></path>
            <path d="M84.8,11.2 L84.8,19.5 L82.5,19.5 L82.5,11.7 C82.5,9.4 81.4,8.3 79.6,8.3 C77.8,8.3 76.4,9.3 76.4,11.5 L76.4,19.5 L74.1,19.5 L74.1,0.6 L76.4,0.6 L76.4,9.6 C77,7.8 78.4,6.6 80.5,6.6 C83.2,6.6 84.8,8.1 84.8,11.2 Z" id="Shape"></path>
            <path d="M95.4,16.4 C95,18.4 93.8,19.9 91.4,19.9 C89,19.9 87.2,18.2 87.2,16.1 C87.2,13.9 89,12.7 91.7,12.2 C94.1,11.7 95.1,11.3 95.4,10.7 L95.4,10.3 C95.4,8.9 94.1,8.4 92.7,8.4 C90.9,8.4 89.5,9.1 88.3,10.4 L88.3,8.2 C89.4,7.3 90.9,6.6 93,6.6 C95.4,6.6 97.7,7.5 97.7,10.6 L97.7,19.5 L95.4,19.5 L95.4,16.4 L95.4,16.4 Z M95.4,12.3 L92,13.5 C90.5,14 89.6,14.6 89.6,15.9 C89.6,17.4 90.6,18.3 92.1,18.3 C93.7,18.3 95.4,16.9 95.3,14.1 L95.4,12.3 L95.4,12.3 Z" id="Shape"></path>
            <path d="M119.3,11.2 L119.3,19.5 L117,19.5 L117,11.7 C117,9.4 115.9,8.3 114.3,8.3 C112.6,8.3 111.2,9.3 111.2,11.5 L111.2,19.5 L108.9,19.5 L108.9,11.7 C108.9,9.4 107.8,8.3 106.2,8.3 C104.5,8.3 103.1,9.3 103.1,11.5 L103.1,19.5 L100.8,19.5 L100.8,6.9 L103.1,6.9 L103.1,9.6 C103.7,7.8 105.1,6.6 107.1,6.6 C109.2,6.6 110.6,7.6 111.1,9.7 C111.7,7.9 113.3,6.6 115.3,6.6 C117.7,6.6 119.3,8.1 119.3,11.2 Z" id="Shape"></path>
            <polygon id="Shape" points="122.2 0.6 124.5 0.6 124.5 19.5 122.2 19.5"></polygon>
            <path d="M129.3,13.2 C129.3,16.4 131.3,18 133.7,18 C135.7,18 137,17.2 138.3,15.9 L138.3,18 C136.9,19.2 135.1,19.8 133.2,19.8 C129.7,19.8 126.9,17.2 126.9,13.2 C126.9,9 129.8,6.6 133.1,6.6 C136.2,6.6 138.8,8.8 138.9,13.1 L129.4,13.1 L129.3,13.2 L129.3,13.2 Z M129.4,11.5 L136.3,11.5 C136.1,9.4 134.9,8.3 132.9,8.3 C131.4,8.3 129.8,9 129.4,11.5 Z" id="Shape"></path>
            <path d="M144.3,8.7 L144.3,15.5 C144.3,17.7 145,18 146.1,18 C146.9,18 147.8,17.7 148.6,17.2 L148.6,19.1 C147.9,19.5 146.8,19.8 145.9,19.8 C143.3,19.8 142.1,18.8 142.1,15.6 L142.1,8.7 L139.4,8.7 L139.4,8.6 L144.4,3.5 L144.4,6.9 L148.8,6.9 L148.8,8.7 L144.3,8.7 L144.3,8.7 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LogoIcon = (props: Partial<CustomIconComponentProps>) => (
  <div style={{ display: "", alignItems: "center" }}>
    <Icon component={LogoSvg} {...props} />
  </div>
);
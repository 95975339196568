import { create } from "zustand";
import { SingleReservationRoomAllocationDTO } from "../../types/roomAllocation.dto"

export type RoomAllocationState = {
  readonly roomAllocationData?: SingleReservationRoomAllocationDTO;
  readonly setRoomAllocationData: (roomAllocationData: SingleReservationRoomAllocationDTO) => void;
}

export const useRoomAllocationStore = create<RoomAllocationState>((set) => ({
  roomAllocationData: undefined,
  setRoomAllocationData: (roomAllocationData: SingleReservationRoomAllocationDTO) => set({ roomAllocationData }),
}));


import { useCallback } from "react";
import { createReservationClient, ReservationsQueryParam } from "../../../api/createReservationClient";
import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import { DashboardState } from "../../../store/dashboardStore";
import { useDashboardStore } from "../../../store/dashboardStore";
import { Reservation } from "../../../../types/reservation";
import { ReservationListDTO } from "../../../../types/reservation.dto";

export function useDashboardService(propertyId: string) {
  const client = createReservationClient(propertyId);

  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const setArrivals = useDashboardStore((state: DashboardState) => state.setArrivals);
  const setDepartures = useDashboardStore((state: DashboardState) => state.setDepartures);
  const setPagination = useDashboardStore((state: DashboardState) => state.setPagination);

  const getReservations = useCallback(
    async (
      data: ReservationsQueryParam, setReservations: (reservations: ReservationListDTO) => void
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservations(data);
        setReservations(res.reservations);
        setPagination({
          ...res.pagination,
          pageSize: parseInt(data.limit),
        });

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [setLoading, setPagination, client, setError],
  );

  const getArrivals = useCallback(
    async (
      data: ReservationsQueryParam
    ) => {
      await getReservations(data, setArrivals);
    },
    [getReservations, setArrivals],
  );

  const getDepartures = useCallback(
    async (
      data: ReservationsQueryParam
    ) => {
      await getReservations(data, setDepartures);
    },
    [getReservations, setDepartures],
  );

  return {
    getArrivals,
    getDepartures,
  }
}

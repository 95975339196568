import { classNames } from "../../../utils";

type TitleProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  className?: string;
};

export function Title({ level, children, className }: TitleProps) {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  const textSize = (() => {
    switch (level) {
      case 1:
        return 'text-3xl';
      case 2:
        return 'text-2xl';
      case 3:
        return 'text-xl';
      case 4:
        return 'text-lg';
      case 5:
        return 'text-base';
      case 6:
        return 'text-sm';
      default:
        return 'text-base';
    }
  })();
  return (
    <Tag className={classNames(textSize, "mb-2 font-semibold text-gray-700", className)}>{children}</Tag>
  );
}

// think of these as page permissions
export enum Permission {
  Admin = "admin",
  OrgReadWrite = "org:rw",
  OrgRead = "org:r",
}

export type PermissionArray = Permission[];

export enum PropertyPermission {
  Admin = "admin",
  PropertyReadWrite = "property:rw",
  PropertyRead = "property:r",
  DashboardReadWrite = "dashboard:rw",
  DashboardRead = "dashboard:r",
}

export type PropertyPermissionArray = PropertyPermission[];

import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const LogoSvg = () => (
  <svg width="100%" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Hamlet</title>
    <defs>
      <linearGradient x1="46.8357533%" y1="100%" x2="46.8357533%" y2="0%" id="linearGradient-1">
        <stop stopColor="#A0C9FF" offset="0%"></stop>
        <stop stopColor="#FFAD8A" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="logo-(light)" transform="translate(-8.000000, 0.000000)" fillRule="nonzero">
        <g id="hamlet_logo_dec2017_light" transform="translate(8.000000, 0.000000)">
          <path d="M35.3,0 L0.7,0 L9,8.9 L9,25.2 L0.7,34 L35.3,34 L19.4,17 L35.3,0 Z M18,15.5 L12.8,10 L23.2,10 L18,15.5 Z M30.7,2 L25,8 L10.9,8 L5.3,2 L30.7,2 Z M11,11.1 L16.6,17.1 L11,23.1 L11,11.1 L11,11.1 Z M5.3,32 L10.9,26 L25,26 L30.6,32 L5.3,32 Z M23.2,24 L12.8,24 L18,18.5 L23.2,24 Z" id="Shape" fill="url(#linearGradient-1)"></path>
        </g>
      </g>
    </g>
  </svg>
);

export const LogoAltIcon = (props: Partial<CustomIconComponentProps>) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Icon component={LogoSvg} {...props} />
  </div>
);
import React, { useEffect } from 'react';
import { Layout, Input, Form, DatePicker, Select, Checkbox, InputNumber, Spin, notification, Alert } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import { useMockingbirdPropertyService as usePropertyService } from '../../services/useMockingbirdPropertyService';
import { PropertyState, usePropertyStore } from '../../store/propertyStore';
import { None, Some } from '../../utils';
import { GlobalState, useGlobalStore } from '../../store/globalStore';
import { OTAState, useOtaStore } from '../../store/otaStore';
import { useOtaService } from '../../services/useOtaService';
import { transformMockInput } from '../../mockingbird/transformMockInput';
import { Button } from '../../components/elements';
import dayjs, { Dayjs } from 'dayjs';
import { useMockTemairazuReservationService } from '../../services/useMockTemairazuReservationService';
import { NotificationPlacement } from 'antd/es/notification/interface';

const { RangePicker } = DatePicker;

type RatePerDay = {
  date: string;
  rate: number;
}

export type Inputs = {
  otaCode: string;
  guestFirstName: string;
  guestSurName: string;
  contactEmail: string;
  stayDates: Dayjs[];
  checkInDate: string;
  checkOutDate: string;
  nights: number;
  packagePlanId?: number;
  mealCondition: string;
  specificMealCondition: string;
  specialServiceRequest: string;
  otherServiceInformation: string;
  roomTypeId: string;
  adults: number;
  usePoints: number;
  prepaid: boolean;
  price: number;
  roomQuantity: number;
  propertyCode: string;
  ratesPerDay: RatePerDay[];
  address: string;
  phoneNumber: string;
}

export type PropertyRouteParam = {
  propertyId: string
}

export function CreateMockTemairazuReservation() {
  const params = useParams<PropertyRouteParam>();
  const propertyInFocus = usePropertyStore((state: PropertyState) => state.propertyInFocus);
  const otas = useOtaStore((state: OTAState) => state.otas);
  const err = useGlobalStore((state: GlobalState) => state.err);

  const { getProperty } = usePropertyService();
  const { getOtas}  = useOtaService(params.propertyId ?? "");
  const { createReservation } = useMockTemairazuReservationService();

  const [api, contextHolder] = notification.useNotification();

  const triggerNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "Reservation successfully created",
      description: "The Temairazu reservation has successfully been created, please hit the sync button to pull the reservaiton",
      placement,
      duration: 2,
    })
  }

  useEffect(() => {
    if (!err && None(propertyInFocus) && params.propertyId) {
      getProperty(params.propertyId);
    }
  }, [getProperty, propertyInFocus, err, params.propertyId]);

  useEffect(() => {
    if(!err && !otas.length)  {
      getOtas();
    }
  }, [err, getOtas, otas])

  const initialValues: Inputs = {
    stayDates: [],
    contactEmail: "",
    otaCode: "",
    guestFirstName: "",
    guestSurName: "",
    checkInDate: "",
    checkOutDate: "",
    nights: 1,
    packagePlanId: undefined,
    mealCondition: "",
    specialServiceRequest: "",
    specificMealCondition: "",
    otherServiceInformation: "",
    roomTypeId: "",
    adults: 1,
    usePoints: 0,
    prepaid: false,
    price: 0,
    roomQuantity: 1,
    propertyCode: propertyInFocus?.temairazuAccommodationId as string,
    ratesPerDay: [],
    address: "",
    phoneNumber: "",
  }

  const { handleSubmit, control, formState: { isSubmitting, isSubmitSuccessful, errors }, watch } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  const { remove, fields, replace } = useFieldArray({
    name: "ratesPerDay",
    control,
  })

  useEffect(() => {
    remove(0);
  }, [remove])

  const calculateNights = (checkInDate: Dayjs, checkOutDate: Dayjs) => {
    return checkOutDate.diff(checkInDate, "day") || initialValues.nights;
  }

  const totalPriceCalculation = () => {
    const watchStayDates = watch("stayDates");
    const watchRoomQuantity = watch("roomQuantity");

    const nights = watchStayDates.length ? calculateNights(watchStayDates[0], watchStayDates[1]) : initialValues.nights;

    const watchRatesPerDayArray = watch("ratesPerDay");
    const totalStayRate = watchRatesPerDayArray.reduce((acc, current) => acc + current.rate, 0);

    const totalPrice = totalStayRate * watchRoomQuantity;
    return [totalPrice, nights];
  }

  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = async data => {
    if (Some(propertyInFocus)) {
      const [totalPrice, nights] = totalPriceCalculation();
      const inputDataWithParsedStayDates: Inputs = {
        ...data,
        checkInDate: data.stayDates[0].format("YYYY-MM-DD"),
        checkOutDate: data.stayDates[1].format("YYYY-MM-DD"),
        price: totalPrice,
        nights,
        propertyCode: propertyInFocus.temairazuAccommodationId ?? "",
      };

      const result = transformMockInput(inputDataWithParsedStayDates, propertyInFocus);
      await createReservation(propertyInFocus.id, result);
      triggerNotification("top");

      setTimeout(() => {
        navigate(`/mockingbird/mock/properties/${propertyInFocus.id}/reservations`);
      }, 2500);
    } else {
      console.log("Property information not available");
    }
  };

  return Some(propertyInFocus) ? (
    <Layout>
      {contextHolder}
      <Form
        disabled={isSubmitting || isSubmitSuccessful}
        style={{ maxWidth: 600 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit(onSubmit)}
      >
        <Form.Item label="First Name">
          <Controller
            control={control}
            rules={{
              required: "First name is required"
            }}
            name="guestFirstName"
            render={({ field }) => <Input {...field} placeholder="John" />}
          />
          {errors.guestFirstName && <Alert type="error" message={errors.guestFirstName.message} /> }
        </Form.Item>

        <Form.Item label="Last Name">
          <Controller
            control={control}
            rules={{
              required: "Last name is required"
            }}
            name="guestSurName"
            render={({ field }) => <Input {...field} placeholder="Smith" />}
          />
          {errors.guestSurName && <Alert type="error" message={errors.guestSurName.message} /> }
        </Form.Item>

        <Form.Item label="Email">
          <Controller
            control={control}
            name="contactEmail"
            rules={{
              required: "Email is required",
            }}
            defaultValue={initialValues.contactEmail}
            render={({ field }) => <Input {...field} />}
          />
          {errors.contactEmail && <Alert type="error" message={errors.contactEmail.message} /> }
        </Form.Item>

        <Form.Item label="Dates">
          <Controller
            control={control}
            name="stayDates"
            rules={{ required: "Stay dates are required" }}
            render={({ field }) => (
              <RangePicker
                onChange={(dates) => {
                  field.onChange(dates)
                  if (dates && dates.length > 1) {
                    const startDate = dayjs(dates[0]);
                    const endDate = dayjs(dates[1]);
                    const nights = calculateNights(startDate, endDate);

                    const ratesPerDay: RatePerDay[] = [];

                    for (let i = 0; i < nights; i++) {
                      const date = startDate.add(i, "day").format("YYYY-MM-DD");
                      ratesPerDay.push({ date, rate: 0 });
                    }

                    replace(ratesPerDay);
                  }
                }}
              />
            )}
          />
          {errors.stayDates && <Alert type="error" message={errors.stayDates.message} /> }
        </Form.Item>

        {fields.map((field, idx) => (
          <div key={field.id}>
            <Form.Item label="Date">
              <Controller
                control={control}
                name={`ratesPerDay.${idx}.date`}
                render={({ field }) => <Input {...field} disabled />}
              />
              {/* {errors.ratePerRoomPerDay && <Alert type="error" message={errors.ratePerRoomPerDay.message} />} */}
            </Form.Item>

            <Form.Item label="Rate">
              <Controller
                control={control}
                name={`ratesPerDay.${idx}.rate`}
                rules={{
                  required: "Rate per room per day is required",
                  min: {
                    message: "Rate cannot be 0",
                    value: 1,
                  }
                }}
                render={({ field }) => <InputNumber type="number" {...field} />}
              />
              {errors.ratesPerDay && <Alert type="error" message={errors.ratesPerDay[idx]?.rate?.message} />}
            </Form.Item>
          </div>
        ))}

        <Form.Item label="Room">
          <Controller
            control={control}
            name="roomTypeId"
            rules={{
              required: "Room type is required"
            }}
            render={({ field }) => (
              <Select
                style={{minWidth: 100}}
                onChange={(e) => field.onChange(e)}
                options={propertyInFocus.roomTypes?.map(room=> {
                  return { value: room.temairazuCode, label: room.name, key: room.id }
                })}
              />
            )}
          />
          {errors.roomTypeId && <Alert type="error" message={errors.roomTypeId.message} /> }
        </Form.Item>

        <Form.Item label="Room Quantity">
          <Controller
            control={control}
            name="roomQuantity"
            rules={{
              min: {
                value: 1,
                message: "A minimum of 1 room is required to make a reservation"
              },
              required: "Room quantity is required",
            }}
            defaultValue={initialValues.roomQuantity}
            render={({ field }) => <InputNumber {...field} />}
          />
          {errors.roomQuantity&& <Alert type="error" message={errors.roomQuantity.message} /> }
        </Form.Item>

        <Form.Item label="Package">
          <Controller
            control={control}
            name="packagePlanId"
            render={({ field }) => (
              <Select
                style={{minWidth: 100}}
                onChange={(e) => field.onChange(e)}
                options={
                  propertyInFocus.plans?.map(plan => {
                    return { value: plan.id, label: plan.name, key: plan.id }
                })}
              />
            )}
          />
        </Form.Item>

        <Form.Item label="OTA">
          <Controller
            control={control}
            name="otaCode"
            rules={{
              required: "OTA is required"
            }}
            render={({ field }) => (
              <Select
                style={{minWidth: 100}}
                onChange={(e) => field.onChange(e)}
                options={otas?.map(ota=> {
                  return { value: ota.temairazuCode, label: ota.name, key: ota.id }
                })}
              />
            )}
          />
          {errors.otaCode && <Alert type="error" message={errors.otaCode.message} />}
        </Form.Item>

        <Form.Item label="Points Used">
          <Controller
            control={control}
            name="usePoints"
            rules={{
              min: {
                message: "It has to be a number.",
                value: 0,
              }
            }}
            defaultValue={initialValues.usePoints}
            render={({ field }) => <InputNumber {...field} />}
          />
          {errors.usePoints && <Alert type="error" message={errors.usePoints.message} />}
        </Form.Item>

        <Form.Item label="Adults">
          <Controller
            control={control}
            name="adults"
            rules={{
              min: {
                message: "Cannot be 0",
                value: 1,
              },
              required: "Field is required"
            }}
            defaultValue={initialValues.adults}
            render={({ field }) => <InputNumber {...field} />}
          />
          {errors.adults && <Alert type="error" message={errors.adults.message} />}
        </Form.Item>

        <Form.Item label="prepaid">
          <Controller
            control={control}
            name="prepaid"
            defaultValue={initialValues.prepaid}
            render={({ field }) => <Checkbox onChange={(e) => field.onChange(e.target.checked)} />}
          />
        </Form.Item>

        <Form.Item label="Meal Condition">
          <Controller
            control={control}
            name="mealCondition"
            defaultValue={initialValues.mealCondition}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item label="Specific Meal Condition">
          <Controller
            control={control}
            name="specificMealCondition"
            defaultValue={initialValues.specificMealCondition}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item label="Special Service Request">
          <Controller
            control={control}
            name="specialServiceRequest"
            defaultValue={initialValues.specialServiceRequest}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item label="Other Service Information">
          <Controller
            control={control}
            name="otherServiceInformation"
            defaultValue={initialValues.otherServiceInformation}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item label="Address">
          <Controller
            control={control}
            name="address"
            defaultValue={initialValues.address}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item label="Phone Number">
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue={initialValues.phoneNumber}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        Total Cost: {totalPriceCalculation()[0]}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={isSubmitting} type="primary" disabled={isSubmitSuccessful}>
            Submit
          </Button>
        </Form.Item>
      </Form>

    </Layout>
  ) : (<Spin tip="loading" size="default" />);
}

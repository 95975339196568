import React from "react";


const BasicInformationPage: React.FC<{ propertyId: string }> = ({
  propertyId,
}) => {
  return (
    <div>
      <h1>Basic Information</h1>
      <p>Property ID: {propertyId}</p>
    </div>
  )
};

export default BasicInformationPage;
import dayjs from "dayjs";
import { Tooltip } from "./Tooltip";


export type DateWithTooltipProps = {
  date: string;
};
export function DateWithTooltip({ date }: DateWithTooltipProps) {
  const relativeDate = dayjs(date).format("MMM D");
  const absoluteDate = dayjs(date).format("L");

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
      >
        <Tooltip content={absoluteDate}>
          {relativeDate}
        </Tooltip>
      </span>
    </>
  );
};

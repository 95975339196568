import { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Layout, Space, Tag, TablePaginationConfig } from "antd";
import Table from "antd/es/table";
import Column from "antd/es/table/Column";

import { useReservationService } from "../../../services/useReservationService";
import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import {
  ReservationState,
  useReservationStore,
} from "../../../store/reservationStore";

import { Reservation } from "../../../../types/reservation";
import dayjs from "dayjs";
import { ReservationListFilter } from "../components/ReservationListFilter";
import { ReservationsQueryParam } from "../../../api/createReservationClient";
import queryString from "query-string";
import { Trans, t } from "@lingui/macro";
import { ContentLayout } from "../../../components/layouts";
import { Button } from "../../../components/elements";
import ReservationFormSlideOver from "../components/ReservationFormSlideOver";
import { useSlideOverStore } from "../../../store/slideOverStore";

export const defaultReservationQueryParam: ReservationsQueryParam = {
  limit: "10",
  page: "1",
  checkInDateStart: dayjs().format("YYYY-MM-DD"),
  checkInDateEnd: dayjs().add(1, "month").format("YYYY-MM-DD"),
};

const newReservationSuffix = "create-new-reservation";

export function ReservationListView() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ propertyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);

  const reservations = useReservationStore(
    (state: ReservationState) => state.reservations
  );
  const pagination = useReservationStore(
    (state: ReservationState) => state.pagination
  );

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const { getReservations } = useReservationService(
    params.propertyId as string
  );

  const queryParams = searchParams.toString();

  useEffect(() => {
    if (location.pathname.endsWith(newReservationSuffix)) {
      setSlideOver(
        <ReservationFormSlideOver urlSuffix={newReservationSuffix} />
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (params.propertyId) {
      if (queryParams) {
        const parsedQueryParams = queryString.parse(queryParams);
        getReservations({
          ...(parsedQueryParams as ReservationsQueryParam),
        });
      } else {
        setSearchParams(queryString.stringify(defaultReservationQueryParam));
      }
    }
  }, [params.propertyId, queryParams]); // eslint-disable-line

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    if (pagination.current && pagination.pageSize) {
      const currentFilterParams = queryString.parse(
        queryParams
      ) as ReservationsQueryParam;
      const filterParams: ReservationsQueryParam = {
        ...currentFilterParams,
        page: pagination.current.toString(),
        limit: pagination.pageSize.toString(),
      };

      setSearchParams(queryString.stringify(filterParams));
    }
  };

  return (
    <ContentLayout sideColumn={<ReservationListFilter />}>
      <Table
        dataSource={reservations}
        pagination={{
          current: pagination.currentPage,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.id}
      >
        <Column
          title={t`Check In`}
          key="checkInDate"
          dataIndex="checkInDate"
          defaultSortOrder="descend"
          sorter={(a, b: Reservation) =>
            dayjs(b.checkInDate).unix() - dayjs(a.checkInDate).unix()
          }
        />

        <Column
          title={t`Check Out`}
          key="checkOutDate"
          dataIndex="checkOutDate"
        />

        <Column title={t`Room`} key="roomType" dataIndex="roomType" />

        <Column title={t`Guest`} key="guestName" dataIndex="guestName" />

        <Column
          title={t`# of Guests`}
          key="adults"
          dataIndex="numberOfGuests"
        />

        <Column
          title={t`Temairazu Booking ID`}
          key="temairazuBookingId"
          dataIndex="temairazuOTABookingId"
        />

        <Column title={t`OTA`} key="ota" dataIndex="ota" />

        <Column title={t`Package`} key="packagePlan" dataIndex="plan" />

        <Column
          title={t`Multiple Room Reservation`}
          key="linkedReservations"
          dataIndex="linkedReservations"
          render={(linkedReservations) => {
            if (linkedReservations && linkedReservations.length) {
              return (
                <Tag color="default">
                  <Trans>YES</Trans>
                </Tag>
              );
            } else return null;
          }}
        />

        <Column
          title=""
          key="view"
          dataIndex="id"
          render={(reservationId: string) => (
            <Space size="small">
              <Link
                to={`/properties/${params.propertyId}/reservations/${reservationId}`}
                state={{ fromListView: true }}
              >
                <Trans>View details</Trans>
              </Link>
            </Space>
          )}
        />
      </Table>
      <Button size="lg" onClick={() => navigate(`./${newReservationSuffix}`)}>
        <Trans>New Reservation</Trans>
      </Button>
    </ContentLayout>
  );
}

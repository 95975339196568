import { create } from "zustand";

export type LinkReservationState = {
  readonly reservationsToLink: number[];
  readonly setReservationsToLink: (reservationIds: number[]) => void; 
}

export const useLinkReservationStore = create<LinkReservationState>((set) => ({
  reservationsToLink: [],
  setReservationsToLink: (reservationIds: number[]) => set({ reservationsToLink: reservationIds }),
}));


import { CreateOtaDTO, OtaDTO, OtaListDTO, UpdateOtaDTO } from "../../types/ota.dto";
import { PaginationParam } from "../app.d";
import { apiClient } from "./createApiClient"
import { GenericHTTPResponse } from "./createApiClient";

export type GetOtasQueryParam = {
  page?: number;
  limit?: number;
  sort?: string;
  direction?: "asc" | "desc";
}

type GetOTAsResponse = {
  message: string;
  otas: OtaListDTO;
  pagination: PaginationParam;
}

type SearchOtaResponse = Omit<GetOTAsResponse, "pagination">;

type GetOTAResponse = {
  message: string;
  ota: OtaDTO;
}

export function createOTAClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/otas`;

  const getOTAs = (data?: GetOtasQueryParam) => apiClient.get<GetOTAsResponse>(baseUrl, data);

  const getOTA = (otaId: number) => apiClient.get<GetOTAResponse>(`${baseUrl}/${otaId}`);

  const createOTA = (data: CreateOtaDTO) => apiClient.post<
    CreateOtaDTO,
    GenericHTTPResponse
  >(baseUrl, data);

  const updateOTA = (data: UpdateOtaDTO) => apiClient.patch<
    UpdateOtaDTO,
    GenericHTTPResponse
  >(`${baseUrl}/${data.id}`, data);

  const deleteOTA = (otaId: number) => apiClient.delete<
    { message: string; error?: string[] | string }
  >(`${baseUrl}/${otaId}`);

  const searchOta = (term: string) => apiClient.get<SearchOtaResponse>(`${baseUrl}/search`, { term });

  return {
    createOTA,
    deleteOTA,
    getOTAs,
    getOTA,
    updateOTA,
    searchOta,
  }
}


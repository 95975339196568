import React, { useEffect, useMemo, useRef, useState } from "react";
import { LogoIcon } from "../icons";
import { UserState, useUserStore } from "../../store/userStore";
import { useAuthService } from "../../services/useAuthService";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import {
  getUniqueProperties,
  hasOrgPermission,
  hasPropertyPermissions,
} from "../../utils";

import { Trans, t } from "@lingui/macro";
import {
  Permission,
  PropertyPermission,
} from "../../constants/common/permissions";
import { Notifications } from "../notifications/Notifications";
import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftRightIcon,
  DocumentChartBarIcon,
  HomeIcon,
  TableCellsIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { NavItem } from "../elements/NavItem";
import { Property } from "../../../types/property";
import { GlobalState, useGlobalStore } from "../../store/globalStore";
import { useSlideOverStore } from "../../store/slideOverStore";
import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import styled from "@emotion/styled";

const iconClassName = "h-5 w-5 flex-shrink-0";
const collapsedSidebarWidth = 44;
const collapsedSidebarThreshold = 150;
const defaultSidebarWidth = 220;

const HoverableDiv = styled.div`
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px; /* Extending hover area */
    width: 20px;
    height: 100%;
  }
`;

export const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = useUserStore((state: UserState) => state.user);
  const currentProperty = useGlobalStore(
    (state: GlobalState) => state.currentProperty
  );
  const setCurrentProperty = useGlobalStore(
    (state: GlobalState) => state.setCurrentProperty
  );
  const { propertyId: pathPropertyId } = useParams();

  const slideOverContext = useSlideOverStore((state) => state.slideOver);
  const { logoutUser } = useAuthService();

  const handleLogout = async () => {
    await logoutUser();
    navigate("/login");
  };
  const showOrgSettings = hasOrgPermission([
    Permission.Admin,
    Permission.OrgRead,
    Permission.OrgReadWrite,
  ]);
  const showPropertySettings = hasPropertyPermissions([
    PropertyPermission.Admin,
  ]);

  const visibleProperties = useMemo(() => {
    if (!user) {
      return [];
    }
    return getUniqueProperties(user);
  }, [user]);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(defaultSidebarWidth);
  const [peeked, setPeeked] = useState<boolean>(false);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const isHovered = useRef<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const toggleSidebar = () => setCollapsed(!collapsed);

  const handleMouseOver = (): void => {
    if (collapsed) {
      isHovered.current = true;
      setPeeked(true);
    }
  };

  const handleMouseOut = (): void => {
    isHovered.current = false;
    delayTimer.current = setTimeout(() => {
      if (collapsed && !isHovered.current) {
        setPeeked(false);
      }
    }, 500);
  };

  const resizeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const oldWidth = width;
    const onMouseMove = (e: MouseEvent) => {
      setWidth(e.clientX);
      setIsResizing(true);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener(
      "mouseup",
      (e: MouseEvent) => {
        if (e.clientX < collapsedSidebarThreshold) {
          setWidth(oldWidth);
          setCollapsed(true);
        }
        setIsResizing(false);
        document.removeEventListener("mousemove", onMouseMove);
      },
      { once: true }
    );
  };
  useEffect(() => {
    // this is for manually entering the url
    if (pathPropertyId) {
      if (
        visibleProperties.find((property) => property.id === pathPropertyId)
      ) {
        setCurrentProperty(pathPropertyId);
      }
    }
  }, []);

  useEffect(() => {
    if (visibleProperties.length > 0) {
      if (currentProperty) {
        if (
          !visibleProperties.find((property) => property.id === currentProperty)
        ) {
          setCurrentProperty(visibleProperties[0].id);
        }
      } else {
        setCurrentProperty(visibleProperties[0].id);
      }
    }
  }, [visibleProperties, currentProperty]);

  const handleChangeProperty = (event: React.FormEvent<HTMLSelectElement>) => {
    const propertyId = event.currentTarget.value;
    setCurrentProperty(propertyId);
    navigate(`/properties/${propertyId}/dashboard`);
  };

  return (
    <div className="min-h-full min-w-[1024px]">
      <div className="flex">
        {/* sidebar */}
        <div className="relative z-10">
          <div
            style={{ width: collapsed ? collapsedSidebarWidth : width }}
            className={`duration-300 ease-in-out-cubic ${
              isResizing ? "transition-none" : "transition-all"
            }`}
          />

          <HoverableDiv
            className="fixed"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="flex">
              <div
                style={{
                  width: collapsed
                    ? peeked
                      ? width
                      : collapsedSidebarWidth
                    : width,
                }}
                className={`h-screen duration-300 ease-in-out-cubic bg-gray-100 overflow-hidden ${
                  isResizing ? "transition-none" : "transition-all"
                }`}
              >
                {/* sidebar contents */}

                <LogoIcon className="h-[34px] font-semibold text-gray-600 px-1 my-3 overflow-hidden" />
                <div className="overflow-y-auto h-full">
                  <div className="flex flex-col h-screen overflow-x-hidden">
                    <div className="flex-grow-0">
                      {/* navigation */}
                      <nav className="px-1">
                        <div className="space-y-1 whitespace-nowrap">
                          <div
                            className={`relative rounded-md inline-block w-full mb-6 ${
                              collapsed && !peeked && "invisible"
                            }`}
                          >
                            <select
                              value={currentProperty}
                              onChange={handleChangeProperty}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-400 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm leading-6"
                            >
                              {visibleProperties?.map((property: Property) => (
                                <option key={property.id} value={property.id}>
                                  {property.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <NavItem
                            icon={<HomeIcon className={iconClassName} />}
                            label={t`Dashboard`}
                            to={`/properties/${currentProperty}/dashboard`}
                          />
                          <NavItem
                            icon={<TableCellsIcon className={iconClassName} />}
                            label={t`Room Allocation`}
                            to="/#"
                          />
                          <NavItem
                            icon={
                              <CalendarDaysIcon className={iconClassName} />
                            }
                            label={t`Reservation List`}
                            to={`/properties/${currentProperty}/reservations`}
                            pathRegex={
                              new RegExp(
                                `properties/${currentProperty}/reservation`
                              )
                            }
                          />
                          <NavItem
                            icon={
                              <ChartBarSquareIcon className={iconClassName} />
                            }
                            label="Analytics"
                            to="/#"
                          />
                          <NavItem
                            icon={
                              <DocumentChartBarIcon className={iconClassName} />
                            }
                            label={t`Revenue Report`}
                            to="/#"
                          />
                          <NavItem
                            icon={
                              <ChatBubbleLeftRightIcon
                                className={iconClassName}
                              />
                            }
                            label={t`Compiled Memos`}
                            to="/#"
                          />
                        </div>
                      </nav>
                    </div>
                    <div className="flex-grow flex justify-end flex-col px-1">
                      {/* account */}
                      <div className="space-y-1 mt-10 whitespace-nowrap">
                        {showPropertySettings && (
                          <NavItem
                            icon={
                              <AdjustmentsHorizontalIcon
                                className={iconClassName}
                              />
                            }
                            label={t`Property Settings`}
                            to="/property/settings/basic"
                            pathRegex={/property\/settings/}
                          />
                        )}
                        {showOrgSettings && (
                          <NavItem
                            icon={
                              <AdjustmentsHorizontalIcon
                                className={iconClassName}
                              />
                            }
                            label={t`Organization Settings`}
                            to="/org/settings/groups"
                            pathRegex={/org\/settings/}
                          />
                        )}
                        <div className="block flex-shrink-0 p-2">
                          <div className="flex items-center">
                            <div>
                              <UserCircleIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />
                            </div>
                            <div className="ml-3">
                              <p className="text-sm font-medium text-gray-700">
                                {user?.fullName}
                              </p>
                              <span>
                                <Link
                                  to={"/settings/basic"}
                                  className="text-xs font-medium text-gray-500 hover:text-gray-700"
                                >
                                  <Trans>Settings</Trans>
                                </Link>
                                {" - "}
                                <a
                                  href="#"
                                  onClick={handleLogout}
                                  className="text-xs font-medium text-gray-500 hover:text-gray-700"
                                >
                                  <Trans>Logout</Trans>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Resizable handle */}
              <div className="relative">
                <button
                  onClick={toggleSidebar}
                  className="absolute -left-3.5 top-8 p-1 border-gray-500 bg-white border rounded-full z-50"
                >
                  <ChevronLeftIcon
                    className={`h-5 w-5 text-gray-500 transition-transform duration-300 ${
                      collapsed ? "-rotate-180" : ""
                    }`}
                  />
                </button>
                {!collapsed ? (
                  <HoverableDiv
                    onMouseDown={resizeSidebar}
                    className="cursor-col-resize pr-0.5 bg-gray-500 absolute inset-y-0 z-20"
                  />
                ) : (
                  <div className="pr-px bg-gray-300 absolute inset-y-0" />
                )}
              </div>
            </div>
          </HoverableDiv>
        </div>

        {/* main content */}
        <div className="flex-grow">
          <main className="">
            <Outlet />
          </main>
        </div>
      </div>

      <Notifications />
      {slideOverContext}
    </div>
  );
};

import { useCallback } from "react";
import { useOtaStore } from "../store/otaStore";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { createOTAClient, GetOtasQueryParam } from "../api/createOTAClient";
import { CreateOtaDTO, UpdateOtaDTO } from "../../types/ota.dto";

export function useOtaService(propertyId: string) {
  const client = createOTAClient(propertyId);

  const setOtas = useOtaStore(state => state.setOtas);
  const setOta = useOtaStore(state => state.setOta);
  const pagination = useOtaStore(state => state.pagination);
  const setPagination = useOtaStore(state => state.setPagination);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);

  const paginationQueryParams: GetOtasQueryParam = {
    limit: pagination.pageSize,
    page: pagination.currentPage,
  };

  const getOta = useCallback(
    async (
      otaId: number | string | any,
    ) => {
      try {
        setLoading(true);
        const res = await client.getOTA(otaId);
        setOta(res.ota);

      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  const searchOta = useCallback(
    async (
      term: string,
    ) => {
      try {
        setLoading(true);
        const res = await client.searchOta(term);
        setOtas(res.otas);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  )

  const getOtas = useCallback(
    async (data?: GetOtasQueryParam) => {
      try {
        setLoading(true);
        const res = await client.getOTAs(data);
        setOtas(res.otas);
        setPagination({
          ...res.pagination,
          pageSize: data?.limit ?? 30,
        });

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false)
    },
    [setError, setLoading, setOtas, client],
  );

  const createOta = useCallback(
    async (data: CreateOtaDTO) => {
      try {
        setLoading(true);
        await client.createOTA(data);
        await getOtas(paginationQueryParams);

      } catch (e: unknown) {
        setError(String(e));
      }

      setLoading(false);
    },
    [],
  );

  const updateOta = useCallback(
    async (
      data: UpdateOtaDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateOTA(data);
        await getOtas(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [setError, setLoading, setOta, client],
  );

  const deleteOta = useCallback(
    async(
      id: number | string | any,
    ) => {
      try {
        setLoading(true);
        await client.deleteOTA(id);
        await getOtas(paginationQueryParams);
      } catch (e: unknown) {
        setError(String(e));
      }
      setLoading(false);
    },
    [],
  );

  return {
    createOta,
    updateOta,
    getOta,
    getOtas,
    deleteOta,
    searchOta,
  }
}

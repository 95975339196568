import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useOptionStore } from '../../../store/optionStore';
import { useOptionService } from '../../../services/useOptionService';
import { ReservationRouteParam } from '../../reservation/routes/ReservationDetailView';
import { SelectInputGroup } from '../../../components/forms/Select';
import { RadioGroup, RadioOption } from '../../../components/forms/Radio';
import { EntrySide } from '../../../constants/common/entrySide';
import { useTaxStore } from '../../../store/taxStore';
import { usePropertyService } from '../../../services/usePropertyService';
import { Switch } from '../../../components/forms/Switch';
import { InputGroup, } from '../../../components/forms/Input';
import { useReservationStore } from '../../../store/reservationStore';
import { sanitizeNumberField, Some } from '../../../utils';
import { useReservationService } from '../../../services/useReservationService';
import { useGlobalStore } from '../../../store/globalStore';
import { useNotificationStore } from '../../../store/notificationStore';
import { SlideOver } from '../../../components/slideover/SlideOver';
import { InvoiceItemDTO } from '../../../../types/invoiceItem.dto';
import { PriceSummary } from '../../../components/forms/PriceSummary';

type Inputs = {
  name: string;
  price: number;
  taxId?: number; 
  optionId?: number;
  quantity: number;
  entrySide: string;
  cost?: number;
}

type Props = {
  invoiceItem: InvoiceItemDTO;
}

export function UpdateInvoiceItemForm({ invoiceItem }: Props) {
  const [open, setOpen] = useState(true);
  const params = useParams<ReservationRouteParam>();
  const propertyId = params.propertyId || ""

  const options = useOptionStore(state => state.options);
  const option = useOptionStore(state => state.option);
  const setOption = useOptionStore(state => state.setOption);
  const setOptionCategory = useOptionStore(state => state.setOptionCategory);
  const reservation = useReservationStore(state => state.reservation);

  const taxes = useTaxStore(state => state.taxes);
  const loading = useGlobalStore(state => state.loading);

  const addNotification = useNotificationStore((state) => state.addNotification);

  const { getOptions } = useOptionService(propertyId);
  const { updateReservationInvoiceItem } = useReservationService(propertyId);
  const { getTaxes } = usePropertyService();

  useEffect(() => {
    if (!taxes.length) {
      getTaxes(propertyId);
    }
  }, []);

  const [overrideCost, setOverrideCost] = useState(false);
  const [overridePrice, setOverridePrice] = useState(false);

  const initialValues: Inputs = {
    name: invoiceItem.name,
    price: invoiceItem.unitPrice,
    entrySide: invoiceItem.entrySide,
    taxId: invoiceItem.tax?.id,
    optionId: invoiceItem.option?.id,
    quantity: invoiceItem.quantity,
    cost: invoiceItem.cost,
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, isValid, isDirty },
  } = useForm<Inputs>({
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (invoiceItem.option && invoiceItem.option.optionCategory && !option) {
      const { optionCategory } = invoiceItem.option;
      setOptionCategory(invoiceItem.option.optionCategory);
      getOptions({ categoryId: optionCategory.id });
      setOption(invoiceItem.option);
    }

    if (option && invoiceItem.option && option.id !== invoiceItem.option.id) {
      setValue("taxId", option.tax?.id);
      setValue("price", option.price);
      setValue("name", option.name);
      setValue("optionId", option.id);
    }

  }, [option]);

  // Summary calculation
  const watchQuantity = watch("quantity");
  const selectedTaxId = watch("taxId");
  const price = watch("price");


  const selectedTaxValue = taxes.find(tax => tax.id === selectedTaxId)?.value ?? 0;
  const quantity = sanitizeNumberField(watchQuantity);

  const pricePerItem = price ?? 0;
  const subTotal = quantity * pricePerItem;
  const totalPrice = Math.floor(subTotal * (1 + selectedTaxValue));
  const totalTax = totalPrice - subTotal;

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      if (Some(reservation)) {
        await updateReservationInvoiceItem(reservation.id, {
          ...data, 
          quantity: sanitizeNumberField(data.quantity),
          id: invoiceItem.id
        });
      }
      setOpen(false);

      setTimeout(() => {
        addNotification({
          title: t`Item successfully updated`,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);
    } catch (e: unknown) {
      addNotification({
        title: t`Unable to update item`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
    }
  }

  const renderOptionForm = () => {
    const entrySideOptions: RadioOption[]= [
      {
        value: EntrySide.EXPENSE,
        label: t`Expense`,
      },
      {
        value: EntrySide.REVENUE,
        label: t`Revenue`,
      },
    ];
    return (
      <>
        <div className="space-y-0 divide-y divide-gray-200 py-0">
          <InputGroup
            label={t`Date`}
            inputProps={{
              name: "date",
              value: invoiceItem.date,
              disabled: true,
            }}
          />
          <Controller 
            control={control}
            name={"optionId"}
            defaultValue={initialValues.optionId}
            render={({ field }) => {
              return (
                <SelectInputGroup
                  selectProps={{
                    options: options.map(option => {
                      return {
                        value: option.id,
                        label: option.name,
                      }
                    }),
                    inlineLabel: t`Select an option`,
                    selected: option && option.name,
                    onChange: async (data) => {
                      field.onChange(data.value)
                      const selectedOption = options.find(option => option.id === data.value);
                      setOption(selectedOption);
                    }
                  }}
                  label={t`Options`}
                />
              )
            }}
          />
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputGroup
                label={t`Name`}
                inputProps={{
                  name: "name",
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="entrySide"
            defaultValue={initialValues.entrySide}
            render={({ field }) => (
              <RadioGroup
                options={entrySideOptions}
                label={t`Entry Side`}
                selectedOption={field.value}
                onChange={(entrySideValue) => {
                  field.onChange(entrySideValue);
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="taxId"
            defaultValue={option?.tax?.id}
            render={({ field }) => {
              const currentTaxId = option?.tax?.id
              const selectedTax = taxes.find(tax => tax.id === field.value)?.name;
              const currentTax = taxes.find(tax => tax.id === currentTaxId)?.name;

              return (
                <SelectInputGroup
                  selectProps={{
                    options: taxes.map(tax=> {
                      return {
                        value: tax.id,
                        label: tax.name,
                      }
                    }),
                    inlineLabel: t`Select tax`,
                    selected: selectedTax || currentTax,
                    onChange: async (data) => {
                      field.onChange(data.value)
                    }
                  }}
                  label={t`Tax`}
                />
              )
            }}
          />

          <div>
            <Switch
              label={t`Override price`}
              onChange={(value) => {
                setOverridePrice(value);
              }}
            />

            <Controller
              control={control}
              name="price"
              defaultValue={initialValues.price}
              render={({ field }) => (
                <InputGroup
                  label={t`Price`}
                  inputProps={{
                    type: "number",
                    name: "price",
                    value: field.value,
                    disabled: !overridePrice,
                    onChange: (e) => {
                      field.onChange(e.target.value);
                    }
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="quantity"
              defaultValue={initialValues.quantity}
              render={({ field }) => (
                <InputGroup
                  label={t`Quantity`}
                  inputProps={{
                    type: "number",
                    name: "quantity",
                    value: field.value,
                    onChange: (e) => {
                      field.onChange(e.target.value);
                    }
                  }}
                />
              )}
            />
          </div>

          <div>
            <Switch
              label={t`Override default cost`}
              onChange={(value) => {
                setOverrideCost(value);
              }}
            />

            <Controller
              control={control}
              name="cost"
              defaultValue={option?.cost || 0}
              render={({ field }) => (
                <InputGroup
                  label={t`Cost`}
                  inputProps={{
                    type: "number",
                    name: "cost",
                    defaultValue: field.value,
                    disabled: !overrideCost,
                    onChange: (value) => {
                      field.onChange(value)
                    }
                  }}
                />
              )}
            />
          </div>
        </div>

        <PriceSummary
          pricePerItem={pricePerItem}
          subTotal={subTotal}
          totalTax={totalTax}
          totalPrice={totalPrice}
          taxValue={selectedTaxValue ? selectedTaxValue * 100 : undefined}
        />
      </>
    )
  };

  const clearForm = () => {
    setOptionCategory(undefined)
    setOption(undefined);
    reset();
  }
  const disableSubmitButton = () =>
    quantity === 0 ||
      isSubmitSuccessful ||
      isSubmitting ||
      loading ||
      !isValid ||
      !isDirty;

  const generateForm = () => (
    <div className="space-y-0 divide-y divide-gray-200 py-0">
      {renderOptionForm()}
    </div>
  )

  return <SlideOver
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit(submitHandler)}
    title={t`Edit item`}
    afterLeave={() => {
      clearForm();
    }}
    disableSubmitButton={disableSubmitButton()}
    content={generateForm()} 
  />

}


import React from 'react';
import { SlideOver } from '../../../components/slideover/SlideOver';
import { withUrlSlideOver } from '../../../components/hocs/withUrlSlideOver';

export type PlaceholderSlideOverProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const PlaceholderSlideOver: React.FC<PlaceholderSlideOverProps> = ({ open, onOpen, onClose }) => {
  return (
    <SlideOver
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title="Placeholder Title"
    >
      <div className="p-4">
        This is a placeholder SlideOver component.
      </div>
    </SlideOver>
  );
};

export default withUrlSlideOver(PlaceholderSlideOver);
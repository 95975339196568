import { create } from "zustand";
import { ReservationDetailDTO, ReservationListDTO } from "../../types/reservation.dto"
import { PaginationParam } from "../app.d";
import { ReservationsQueryParam } from "../api/createReservationClient";
import { SurveyResponseDTO } from "../../types/surveyResponse.dto";

export type ReservationState = {
  readonly reservation?: ReservationDetailDTO;
  readonly reservations: ReservationListDTO;
  readonly surveyResponse?: SurveyResponseDTO;
  readonly pagination: PaginationParam;
  readonly reservationSearchParam?: ReservationsQueryParam;
  readonly setReservation: (reservation?: ReservationDetailDTO) => void;
  readonly setReservations: (reservations: ReservationListDTO) => void;
  readonly setSurveyResponse: (surveyResponse?: SurveyResponseDTO) => void;
  readonly setPagination: (pagination: PaginationParam) => void;
  readonly setReservationSearchParam: (reservationSearchParam: ReservationsQueryParam) => void;
}

export const useReservationStore = create<ReservationState>((set) => ({
  reservation: undefined,
  reservations: [],
  surveyResponse: undefined,
  reservationSearchParam: undefined,
  pagination: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    total: 0,
    nextPageAvailable: false,
  },
  setReservation: (reservation?: ReservationDetailDTO) => set({ reservation }),
  setReservations: (reservations: ReservationListDTO) => set({ reservations }),
  setSurveyResponse: (surveyResponse?: SurveyResponseDTO) => set({ surveyResponse }),
  setPagination: (pagination: PaginationParam) => set({ pagination }),
  setReservationSearchParam: (reservationSearchParam: ReservationsQueryParam) => set({ reservationSearchParam }),
}));


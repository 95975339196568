import { Trans } from '@lingui/macro';
type Props = {
  pricePerItem: number;
  subTotal: number;
  totalTax: number;
  taxValue?: number;
  totalPrice: number;
}

export function PriceSummary({
  pricePerItem,
  subTotal,
  totalTax,
  taxValue,
  totalPrice,
}: Props) {
  return (
    <section aria-labelledby="summary-heading" className="mt-auto">
      <div className="bg-gray-50 rounded-lg p-7">
        <h2 id="summary-heading" className="sr-only">
          <Trans>
            Summary
          </Trans>
        </h2>

        <div className="flow-root">
          <dl className="-my-4 divide-y divide-gray-200 text-sm">
            <div className="flex items-center justify-between py-4">
              <dt className="text-gray-600">
                <Trans>
                  Price per item
                </Trans>
              </dt>
              <dd className="font-medium text-gray-900">{pricePerItem} JPY</dd>
            </div>
            <div className="flex items-center justify-between py-4">
              <dt className="text-gray-600">
                <Trans>
                  Subtotal
                </Trans>
              </dt>
              <dd className="font-medium text-gray-900">{subTotal} JPY</dd>
            </div>
            <div className="flex items-center justify-between py-4">
              <dt className="text-gray-600">
                <Trans>Tax</Trans> {taxValue? `(${taxValue}%)`: ""}
              </dt>
              <dd className="font-medium text-gray-900">
                {totalTax} JPY
              </dd>
            </div>
            <div className="flex items-center justify-between py-4">
              <dt className="text-base font-medium text-gray-900">
                <Trans>
                  Total Price
                </Trans>
              </dt>
              <dd className="text-base font-medium text-gray-900">
                {totalPrice} JPY
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  )
} 

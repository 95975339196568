import ReactDOM from "react-dom/client";
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";

import "antd/dist/reset.css";
import "./index.css";
import { MockingbirdRoot } from "./routes/mockingbird/mockingbirdRoot";
import { CreateMockTemairazuReservation } from "./routes/mockingbird/CreateMockReservationForm";
import { PropertyListView as MockingbirdPropertyListView } from "./routes/mockingbird/PropertyListView";
import { MockReservationListView } from "./routes/mockingbird/MockReservationListView";
import { MockReservationDetailView } from "./routes/mockingbird/MockReservationDetailView";

import { Login } from "./features/auth/routes/Login";
import { App } from "./App";
import { PropertyDashboardView } from "./features/properties/routes/PropertyDashboardView";
import { ReservationListView } from "./features/reservation/routes/ReservationListView";
import { ResetPassword } from "./features/auth/routes/ResetPassword";
import { RequestResetPassword } from "./features/auth/routes/RequestResetPassword";
import { ReservationDetailView } from "./features/reservation/routes/ReservationDetailView";
import { MainLayout } from "./components/layouts";
import { UserSettingsView } from "./features/usermanagement/routes/UserSettingsView";
import { OrgSettingsView } from "./features/usermanagement/routes/OrgSettingsView";
import { RoomAllocationView } from "./features/room-allocation/routes/RoomAllocationView";
import { LinkReservationView } from "./features/reservation/routes/LinkReservationView";
import { I18nProvider } from "./providers/I18nProvider/I18nProvider";
import { PublicLayout } from "./components/layouts/PublicLayout";
import { Interstitial } from "./routes/Interstitial";
import { NotFound } from "./routes/NotFound";
import { ValidatePropertyLayout } from "./components/layouts/ValidatePropertyLayout";
import { useEffect } from "react";
import { PropertySettingsView } from "./features/propertymanagement/routes/PropertySettingsView";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/request-reset-password",
        element: <RequestResetPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "/",
            element: <Interstitial />,
          },
          {
            path: "/settings/*",
            element: <UserSettingsView />,
          },
          {
            path: "/org/settings/*",
            element: <OrgSettingsView />,
          },
          {
            path: "/property/settings/*",
            element: <PropertySettingsView />,
          },
        ],
      },
      {
        element: <ValidatePropertyLayout />,
        children: [
          {
            path: "/properties/:propertyId/dashboard",
            element: <PropertyDashboardView />,
          },
          {
            path: "/properties/:propertyId/reservations",
            element: <ReservationListView />,
            children: [
              {
                path: "create-new-reservation",
                element: <ReservationListView />,
              }
            ]
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/*",
            element: <ReservationDetailView />,
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/allocate-room",
            element: <RoomAllocationView />,
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/link-reservation",
            element: <LinkReservationView />,
          },
        ],
      },
    ],
  },
  // Mockingbird routes
  {
    path: "/mockingbird",
    element: <MockingbirdRoot />,
    children: [
      {
        path: "mock/properties/:propertyId/create-mock-reservation",
        element: <CreateMockTemairazuReservation />,
        handle: {
          clearState() {
            console.log("clear state called");
          },
        },
      },
      {
        path: "mock/properties",
        element: <MockingbirdPropertyListView />,
      },
      {
        path: "mock/properties/:propertyId/reservations",
        element: <MockReservationListView />,
      },
      {
        path: "mock/properties/:propertyId/reservations/:reservationId",
        element: <MockReservationDetailView />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <I18nProvider>
    <RouterProvider router={router} />
  </I18nProvider>
);

import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { useRoomStore } from "../store/roomStore";
import { createRoomClient } from "../api/createRoomClient";
import { Room } from "../../types/room";

export function useRoomService(propertyId: string) {
  const client = createRoomClient(propertyId);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const { setRoomTypes } = useRoomStore();

  const getRoomTypes = useCallback(async () => {
    try {
      setLoading(true);

      const res = await client.getRoomTypes();
      setRoomTypes(res.roomTypes);

      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
    }
  }, []);

  const updateRoom = useCallback(async (id: number, roomId: number, data: Partial<Room>) => {
    try {
      setLoading(true);

      const res = await client.updateRoom(id, roomId, data);

      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
    }
  }, [])

  return {
    // RoomType
    getRoomTypes,
    // Room
    updateRoom,
  };
}

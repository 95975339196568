import { create } from "zustand";
import { PropertyDTO } from "../../types/property.dto";

export type PropertyState = {
  readonly properties: PropertyDTO[];
  readonly propertyInFocus: PropertyDTO | null;
  readonly initialCallMade: boolean;
  readonly setProperties: (properties: PropertyDTO[]) => void;
  readonly setPropertyInFocus: (property: PropertyDTO | null) => void;
  readonly setInitialCall: (value: boolean) => void;
}

export const usePropertyStore = create<PropertyState>((set) => ({
  properties: [],
  propertyInFocus: null,
  initialCallMade: false,
  setProperties: (properties: PropertyDTO[]) => set({ properties }),
  setPropertyInFocus: (property: PropertyDTO | null) => set({ propertyInFocus: property }),
  setInitialCall: (initialCallMade: boolean) => set({ initialCallMade }),
}));

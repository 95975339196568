import { KeyboardEvent } from "react";
import "react-phone-number-input/style.css";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { classNames, getNestedValue } from "../../utils";
import { useFormContext, RegisterOptions } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import enCountryLabels from "react-phone-number-input/locale/en.json";
import jaCountryLabels from "react-phone-number-input/locale/ja.json";
import { useUserStore } from "../../store/userStore";

const baseClassNames =
  "block rounded-md border-0 py-1.5 focus:ring-2 focus:ring-inset text-sm leading-6";
const defaultColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600";
const disabledColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200";
const errorColorClassNames =
  "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500";

export type InputProps = {
  defaultValue?: string | number | any;
  name: string;
  type?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterPress?: (value: string) => void;
  placeholder?: string;
  size?: string;
  value?: string | number | any;
  error?: boolean;
};

export function Input({
  defaultValue,
  name,
  type = "text",
  disabled = false,
  onChange,
  onEnterPress,
  placeholder,
  size = "w-60",
  value,
  error = false,
}: InputProps) {
  let activeClassNames = classNames(baseClassNames, size);
  if (disabled) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (onEnterPress) onEnterPress(e.currentTarget.value);
    }
  }

  return (
    <input
      type={type}
      name={name}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      className={activeClassNames}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
    />
  );
}

type InputWithInsetLabelProps = InputProps & {
  label: string;
};

export function InputWithInsetLabel({
  defaultValue,
  name,
  type = "text",
  disabled = false,
  onChange,
  placeholder,
  label,
  size,
}: InputWithInsetLabelProps) {
  const enabledClassNames = classNames(
    "block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6",
    size
  );

  const disabledClassNames = classNames(
    "block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-sm leading-6",
    size
  );

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        className={disabled ? disabledClassNames : enabledClassNames}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  );
}

type InputGroupProps = {
  label: string;
  inputProps: InputProps;
};

export function InputGroup({ label, inputProps }: InputGroupProps) {
  return (
    <div className="grid grid-cols-3 gap-4 space-y-0 px-6 py-5">
      <div>
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-6 text-gray-900 mt-1.5"
        >
          {label}
        </label>
      </div>
      <div className="col-span-2">
        <Input {...inputProps} />
      </div>
    </div>
  );
}

type StackedInputGroupProps = {
  name: string;
  label: string;
  type?: string;
  options?: RegisterOptions;
  helpText?: string;
  className?: string;
};
export function StackedInputGroup({
  name,
  label,
  type = "text",
  helpText,
  options,
  className,
}: StackedInputGroupProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const error = getNestedValue(errors, name)?.message;
  const showHelpText = helpText && !error;
  let activeClassNames = classNames(baseClassNames, "w-full");
  if (options?.disabled) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          {...register(name, options)}
          className={activeClassNames}
          type={type}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}

export function StackedPhoneInputGroup({
  name,
  label,
  helpText,
  className,
}: StackedInputGroupProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext<{ [key: string]: string }>();
  const user = useUserStore((state) => state.user);
  let labels = enCountryLabels;
  if (user?.locale === "ja") {
    labels = jaCountryLabels;
  }

  const error = errors[name]?.message;
  const showHelpText = helpText && !error;
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      <div className="relative mt-2">
        <PhoneInputWithCountry
          name={name}
          international
          numberInputProps={{
            className:
              "block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6 rounded-md shadow-sm",
          }}
          control={control}
          countrySelectProps={{ unicodeFlags: true }}
          labels={labels}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "../../routes/NotFound";
import { useUserStore, UserState } from "../../store/userStore";
import { getUniqueProperties } from "../../utils";
import { MainLayout } from "./MainLayout";

export const ValidatePropertyLayout: React.FC = () => {
  let params = useParams();
  let propertyId = params.propertyId;
  const user = useUserStore((state: UserState) => state.user);

  const visibleProperties = useMemo(() => {
    if (!user) {
      return [];
    }
    return getUniqueProperties(user);
  }, [user]);

  const hasPropertyPermission = useMemo(() => {
    if (!user) {
      return false;
    }
    return visibleProperties.find((property) => property.id === propertyId);
  }, [user, propertyId]);

  if (!user) {
    return null;
  }

  if (!hasPropertyPermission) {
    return <NotFound />;
  } else {
    return <MainLayout />;
  }
};

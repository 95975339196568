import { create } from "zustand";
import { PropertyPermissionGroup } from "../../types/propertyPermissionGroup";
import { UserOrgDTO } from "../../types/user.dto";
import { Organization } from "../../types/organization";

export type OrgState = {
  readonly orgInFocus: Organization | null;
  readonly propertyPermissionGroups: PropertyPermissionGroup[];
  readonly users: UserOrgDTO[];
  readonly setOrgInFocus: (org: Organization | null) => void;
  readonly setPropertyPermissionGroups: (propertyPermissionGroups: PropertyPermissionGroup[]) => void;
  readonly setUsers: (users: UserOrgDTO[]) => void;
}

export const useOrgStore = create<OrgState>((set) => ({
  orgInFocus: null,
  propertyPermissionGroups: [],
  users: [],
  setOrgInFocus: (org: Organization | null) => set({ orgInFocus: org }),
  setPropertyPermissionGroups: (propertyPermissionGroups: PropertyPermissionGroup[]) => set({ propertyPermissionGroups }),
  setUsers: (users: UserOrgDTO[]) => set({ users }),
}));

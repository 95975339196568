import React, { useEffect } from 'react';
import { Layout, Space, MenuProps, Menu, notification } from 'antd';
import { Link, Outlet, useLocation, useNavigate  } from 'react-router-dom';
import { PropertyState, usePropertyStore } from '../../store/propertyStore';
import { GlobalState, useGlobalStore } from '../../store/globalStore';
import { useMockTemairazuReservationService } from '../../services/useMockTemairazuReservationService';
import { MockTemairazuReservationState, useMockTemairazuReservationStore } from '../../store/mockTemairazuReservationStore';
import { UserState, useUserStore } from '../../store/userStore';
import { useAuthService } from '../../services/useAuthService';
import { Button } from '../../components/elements';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const navItems: MenuItem[] = [
  getItem(
    <Link to="/mockingbird/mock/properties">Properties</Link>, "properties"
  ),
  getItem(
    <Link to="/dashboard">Hamlet</Link>, "hamlet"
  ),
]

const { Sider, Content } = Layout;

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  height: "100%",
  backgroundColor: '#3ba0e9',
  display: "inline-block",
  width: "12%",
};

export const MockingbirdRoot: React.FC = () => {
  const navigate = useNavigate();

  const user = useUserStore((state: UserState) => state.user);
  const authError = useUserStore((state: UserState) => state.authError);
  const { getCurrentUser, logoutUser } = useAuthService();

  const setPropertyInFocus = usePropertyStore((state: PropertyState) => state.setPropertyInFocus);
  const setReservationInFocus = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setReservationInFocus);
  const setReservationInitialCall = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setInitialCallMade);
  const setReservations = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.setReservations);
  const loading = useGlobalStore((state: GlobalState) => state.loading);

  const location = useLocation();
  const { processTemairazuReservations } = useMockTemairazuReservationService()

  useEffect(() => {
    if (!user) {
      getCurrentUser();
    }

    if (authError) {
      navigate("/login");
    }
  }, [user, authError]);

  useEffect(() => {
    if (location.state && location.state.fromListView) {
      setReservationInFocus(null);
      setPropertyInFocus(null);
      setReservationInitialCall(false);
    }
    if (location.pathname === "/mock/properties") {
      setReservations([]);
    }
  }, [location, setPropertyInFocus, setReservationInFocus, setReservationInitialCall, setReservations]);

  const [api, contextHolder] = notification.useNotification();

  const triggerNotification = () => {
    api.success({
      message: "Mockingbird synced successfully",
      description: "Mockingbird has pulled newly created reservations from Temairazu.",
      placement: "top",
      duration: 2,
    })
  }

  const handleSyncRequest = async () => {
    await processTemairazuReservations();
    triggerNotification();
    if (location.pathname === "/mock/properties") {
      setReservations([]);
    }
  }

  const handleLogout = async () => {
    await logoutUser();
    navigate("/login");
  }

  return (
    <Space direction="vertical" style={{ width: '100%', height: "100%" }} size={[0, 48]}>
      <Layout style={{ height: "100%" }}>
        <Sider style={siderStyle}>
          <h1 id="logo">Mockingbird</h1>
          <Menu
            mode="vertical"
            style={{
              background: "none",
              color: "white",
            }}
            items={[
              ...navItems,
              getItem(
                <Button disabled={loading} loading={loading} onClick={handleSyncRequest}>Sync</Button>, "sync"
              ),
              getItem(
                <Button onClick={handleLogout}>Logout</Button>, "logout"
              ),
            ]}
          />
        </Sider>
        <Layout style={{ height: "100%", overflow: "auto" }}>
          {contextHolder}
          <Content style={{ padding: 50 }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Space>
  )
}


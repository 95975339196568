import React from "react";
import { NavItem, NavItemProps } from "./NavItem";

type SidebarProps = {
  navItems: NavItemProps[];
  topContent?: React.ReactNode;
};

export const Sidebar: React.FC<SidebarProps> = ({ navItems, topContent }) => (
  <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pt-6">
    {topContent ? <div className="pb-6">{topContent}</div> : null}
    <nav className="flex flex-1 flex-col">
      <ul className="-mx-2 space-y-1">
        {navItems.map((item) => (
          <NavItem {...item} key={item.label} />
        ))}
      </ul>
    </nav>
  </div>
);

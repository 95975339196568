import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Notifications } from "../notifications/Notifications";
import { UserState, useUserStore } from "../../store/userStore";
import { useAuthService } from "../../services/useAuthService";

export const PublicLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = useUserStore.getState().user;
  const authError = useUserStore((state: UserState) => state.authError);
  const { getCurrentUser } = useAuthService();
  useEffect(() => {
    if (user) navigate("/dashboard");
    else getCurrentUser();
  }, [authError]);

  return (
    <>
      <Outlet />
      <Notifications />
    </>
  );
};

import { t, Trans } from "@lingui/macro";
import { Controller, UseFormReturn } from "react-hook-form";
import {
  Checkbox,
  CheckboxWithInput,
} from "../../../components/forms/Checkbox";
import { StackedInputGroup } from "../../../components/forms/Input";
import { StackedSelectInputGroup } from "../../../components/forms/Select";
import { ReservationGroupType } from "../../../constants/common/surveyResponse";
import { toValueLabelList } from "../../../utils";

type GuestInformationSurveyFormProps = {
  methods: UseFormReturn<any>;
};

export const GuestInformationSurveyForm = ({
  methods,
}: GuestInformationSurveyFormProps) => (
  <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
    <div className="mx-4 grid gap-x-6 gap-y-1 grid-cols-2">
      <StackedSelectInputGroup
        className="mb-7"
        label={t`Reservation Group Type`}
        selectProps={{ name: "reservationGroupType", options: toValueLabelList(ReservationGroupType) }}
      />
      <h2 className="text-base col-span-full font-semibold leading-7 text-gray-900">
        <Trans>How did you find out about Nazuna?</Trans>
      </h2>
      <Controller
        control={methods.control}
        name={`engagementChannels.onlineTravelAgency`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Online Travel Agency`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.officialWebsite`}
        render={({ field }) => (
          <Checkbox
            className="self-center"
            label={t`Official Website`}
            field={field}
          />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.instagram`}
        render={({ field }) => (
          <Checkbox
            className="self-center"
            label={t`Instagram`}
            field={field}
          />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.twitter`}
        render={({ field }) => (
          <Checkbox className="self-center" label={t`Twitter`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.facebook`}
        render={({ field }) => (
          <Checkbox className="self-center" label={t`Facebook`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.otherSocialMedia`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Other Social Media`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.television`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Television`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.travelAgency`}
        render={({ field }) => (
          <Checkbox
            className="self-center"
            label={t`Travel Agency`}
            field={field}
          />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.newspaper`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Newspaper`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.magazine`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Magazine`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.bookOrGuide`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Book Or Guide`} field={field} />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.recommendation`}
        render={({ field }) => (
          <Checkbox
            className="self-center"
            label={t`Recommendation`}
            field={field}
          />
        )}
      />
      <Controller
        control={methods.control}
        name={`engagementChannels.other`}
        render={({ field }) => (
          <CheckboxWithInput label={t`Other`} field={field} />
        )}
      />
    </div>
  </div>
);

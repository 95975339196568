import { create } from "zustand";
import { Reservation } from "../../types/reservation";
import { ReservationListDTO } from "../../types/reservation.dto";
import { PaginationParam } from "../app.d";

export type DashboardState = {
  readonly arrivals: ReservationListDTO;
  readonly departures: ReservationListDTO;
  readonly pagination: PaginationParam;
  readonly setArrivals: (reservations: ReservationListDTO) => void;
  readonly setDepartures: (reservations: ReservationListDTO) => void;
  readonly setPagination: (pagination: PaginationParam) => void;
}

export const useDashboardStore = create<DashboardState>((set) => ({
  arrivals: [],
  departures: [],
  pagination: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 100,
    total: 0,
    nextPageAvailable: false,
  },
  setArrivals: (reservations: ReservationListDTO) => set({ arrivals: reservations }),
  setDepartures: (reservations: ReservationListDTO) => set({ departures: reservations }),
  setPagination: (pagination: PaginationParam) => set({ pagination }),
}));

import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import { Trans, t } from '@lingui/macro';

import { SlideOver } from "../../../components/slideover/SlideOver";
import { useNotificationStore } from '../../../store/notificationStore';
import { InputGroup } from '../../../components/forms/Input';
import { useGlobalStore } from '../../../store/globalStore';
import { useOtaService } from '../../../services/useOtaService';
import { usePropertyStore } from '../../../store/propertyStore';
import { OtaDTO } from '../../../../types/ota.dto';

type Inputs = {
  name: string;
  nameJa?: string;
  website?: string;
  email?: string;
  temairazuCode?: string;
  description?: string;
}

type Param = {
  ota?: OtaDTO;
}

export function OTAForm({ ota }: Param) {
  const [open, setOpen] = useState(true);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const loading = useGlobalStore(state => state.loading);
  const property = usePropertyStore((state) => state.propertyInFocus);

  const { createOta, updateOta } = useOtaService(property?.id ?? "");

  const initialValues: Inputs = {
    name: "",
  }

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = useForm<Inputs>({
    defaultValues: initialValues,
  });


  useEffect(() => {
    if (ota) {
      setValue("name", ota.name);
      setValue("nameJa", ota.nameTranslations.find(t => t.language === "ja")?.translation ?? "");
      setValue("website", ota.website);
      setValue("email", ota.email);
      setValue("temairazuCode", ota.temairazuCode);
      setValue("description", ota.description);
    }
  }, [ota])

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    try {
      if (ota) {
        await updateOta({ ...data, id: ota.id });
      } else {
        await createOta(data);
      }

      setOpen(false);

      setTimeout(() => {
        addNotification({
          title: t`OTA successfully created`,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);
    } catch (e: unknown) {
      setTimeout(() => {
        addNotification({
          title: t`Unable to create OTA`,
          type: "error",
          message: String(e),
          timeoutMs: 1500,
        });
      }, 500);

    }
  };

  const renderForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <InputGroup
                label={t`Name`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="nameJa"
            render={({ field }) => (
              <InputGroup
                label={t`Name (ja)`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="website"
            render={({ field }) => (
              <InputGroup
                label={t`Website`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <InputGroup
                label={t`Email`}
                inputProps={{
                  name: field.name, 
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <InputGroup
                label={t`Description`}
                inputProps={{
                  name: field.name,
                  value: field.value,
                  onChange: (value) => {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="temairazuCode"
          render={({ field }) => (
            <InputGroup
              label={t`Temairazu Code (if applicable)`}
              inputProps={{
                name: field.name,
                value: field.value,
                onChange: (value) => {
                  field.onChange(value);
                }
              }}
            />
          )}
        />

      </div>
    )
  }
  

  const disableSubmitButton = () =>
    isSubmitSuccessful ||
    isSubmitting ||
    loading ||
    !isValid;

  const title = ota ? t`Edit OTA` : t`Create new OTA`;

  return <SlideOver
    open={open}
    onClose={() => setOpen(false)}
    onSubmit={handleSubmit(submitHandler)}
    afterLeave={() => reset()}
    title={title}
    disableSubmitButton={disableSubmitButton()}
    content={renderForm()}
  />
}

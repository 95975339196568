import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Space } from "antd";
import {
  UserOutlined,
  MailOutlined,
  LockOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useAuthService } from "../../../services/useAuthService";
import { UserState, useUserStore } from "../../../store/userStore";
import { LocaleEnum, useI18nContext } from "../../../providers/I18nProvider";
import { Trans, t } from "@lingui/macro";
import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import { Button } from "../../../components/elements";
import { useNavigate } from "react-router";

const { Option } = Select;

const UserSettingsContent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const { editUser } = useAuthService();
  const userData = useUserStore((state: UserState) => state.user);
  const error = useGlobalStore((state: GlobalState) => state.err);
  const { handleChangeLocale, locale } = useI18nContext();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!error && userData && userData.locale !== locale) {
      handleChangeLocale(userData.locale as LocaleEnum);
    }
  }, [userData, error, locale]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // TODO(KH-384): Perform password reset logic here
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = async (values: any) => {
    await editUser(values);
    navigate(0);
  };

  if (!userData) {
    return <div></div>;
  }

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleSave} onFieldsChange={() => setIsTouched(true)}>
        <Form.Item label={t`Name`}>
          <Input prefix={<UserOutlined />} value={userData.fullName} disabled />
        </Form.Item>

        <Form.Item label={t`Nickname`}>
          <Input prefix={<UserOutlined />} value={userData.nickName} disabled />
        </Form.Item>

        <Form.Item label={t`Email`}>
          <Input prefix={<MailOutlined />} value={userData.email} disabled />
        </Form.Item>

        <Form.Item label={t`Verified`}>
          <Input
            prefix={<LockOutlined />}
            value={userData.verified ? t`Yes` : t`No`}
            disabled
          />
        </Form.Item>

        <Form.Item
          label={t`Language`}
          name="locale"
          initialValue={userData.locale}
        >
          <Select style={{ width: 120 }}>
            <Option value={LocaleEnum.English}>English</Option>
            <Option value={LocaleEnum.Japanese}>日本語</Option>
          </Select>
        </Form.Item>

        <Space>
          <Button type="primary" onClick={showModal}>
            <Trans>Reset Password</Trans>
          </Button>

          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!isTouched}
          >
            <Trans>
              Save
            </Trans>
          </Button>
        </Space>
      </Form>

      <Modal
        title={t`Reset Password`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item
            label={t`Current Password`}
            name="currentPassword"
            rules={[
              {
                required: true,
                message: t`Please enter your current password.`,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: t`Please enter your new password.`,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={t`Confirm New Password`}
            name="confirmNewPassword"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: t`Please confirm your new password.`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t`The two passwords do not match!`)
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserSettingsContent;
